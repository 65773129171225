import { Injectable } from '@angular/core';
import { SampleDialogComponent } from '@app/modules/service-data/components/sample/sample-dialog/sample-dialog.component';
import { SampleKitChangeDialogComponent } from '@app/modules/service-data/components/sample/sample-kit-change-dialog/sample-kit-change-dialog.component';
import {
  ExportDialogDataType,
  ExportType,
  ExtendedSample,
  Sample,
  SampleExtensions,
} from '@app/modules/service-data/service-data.types';
import { ServiceDataUtilsService } from '@app/modules/service-data/services/service-data-utils.service';
import {
  GetServiceDataAction,
  GetServiceDataListAction,
} from '@app/modules/service-data/store/service-data.actions';
import { ServiceDataState } from '@app/modules/service-data/store/service-data.state';
import { RuntimeStatus } from '@core/core.types';
import { ExportersUtilsService } from '@core/services/exporters-utils.service';
import { LoadKitById, LoadKitsByIdAction } from '@core/store/kits/kits.actions';
import { KitsState } from '@core/store/kits/kits.state';
import {
  LoadPartnerById,
  LoadPartnersByIds,
} from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import { ProductsListState } from '@core/store/products/products.state';
import { CoreUtilsService } from '@core/utils/core-utils.service';
import { HttpUtils } from '@core/utils/http-utils';
import { NbDialogService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { SampleDetailsComponent } from '../components/sample/sample-details/sample-details.component';
import { SampleResultsComponent } from '../components/sample/sample-results/sample-results.component';
import { SampleFilters } from './sample.service';

@Injectable({
  providedIn: 'root',
})
export class SampleUtilsService {
  constructor(
    private store: Store,
    private serviceDataUtils: ServiceDataUtilsService,
    private nbDialogService: NbDialogService,
    private exportersUtilsService: ExportersUtilsService,
    private coreUtils: CoreUtilsService,
    private translate: TranslateService
  ) {}

  getExtendedSample(
    sample: Sample,
    appends: SampleExtensions = {
      partner: true,
      product: true,
      doctor: true,
      serviceData: null,
      kit: false,
    }
  ): Observable<ExtendedSample> {
    if (!sample) {
      return of(null);
    }
    return forkJoin([
      appends.partner
        ? this.store.dispatch(new LoadPartnerById(sample?.partnerId))
        : of(null),
      appends.doctor
        ? this.store.dispatch(
            new LoadPartnerById(sample?.serviceInformation.nipt.doctorId)
          )
        : of(null),
      appends.serviceData
        ? this.store.dispatch(new GetServiceDataAction(sample?.serviceDataId))
        : of(null),
      appends.kit
        ? this.store.dispatch(new LoadKitById(sample?.kitId))
        : of(null),
    ]).pipe(
      switchMap(() =>
        combineLatest([
          of(sample),
          this.store
            .select(ProductsListState.getProduct)
            .pipe(map((getProduct) => getProduct(sample?.productId))),
          this.store
            .select(PartnersState.getPartnerById)
            .pipe(map((getPartnerById) => getPartnerById(sample?.partnerId))),
          this.store
            .select(PartnersState.getPartnerById)
            .pipe(
              map((getPartnerById) =>
                getPartnerById(sample?.serviceInformation?.nipt?.doctorId)
              )
            ),
          appends.serviceData
            ? this.store
                .select(ServiceDataState.getServiceDataById)
                .pipe(
                  first(),
                  map((getServiceDataById) =>
                    getServiceDataById(sample?.serviceDataId)
                  )
                )
                .pipe(
                  switchMap((serviceData) =>
                    this.serviceDataUtils.getExtendedServiceData(
                      serviceData,
                      appends.serviceData
                    )
                  )
                )
            : of(null),
          this.store
            .select(KitsState.getKitById)
            .pipe(map((getKitById) => getKitById(sample?.kitId))),
        ])
      ),
      map(([sample, product, partner, doctor, serviceData, kit]) => ({
        ...sample,
        product,
        partner,
        doctor,
        serviceData,
        kit,
      }))
    );
  }

  getExtendedSampleList(
    sampleList: Sample[],
    appends: SampleExtensions
  ): Observable<ExtendedSample[]> {
    if (!sampleList.length) {
      return of([]);
    }

    return this.loadSampleConnectedData(sampleList, appends).pipe(
      switchMap(() =>
        combineLatest(
          sampleList.map((sample) => this.getExtendedSample(sample, appends))
        )
      )
    );
  }

  loadSampleConnectedData(sampleList: Sample[], appends: SampleExtensions) {
    if (!sampleList.length) {
      return of([]);
    }

    const productIds: string[] = [];
    const kitIds: string[] = [];
    const partnerIds: string[] = [];
    const serviceDataIds: string[] = [];
    sampleList.forEach((sample) => {
      productIds.push(sample?.productId);
      if (appends.partner) {
        partnerIds.push(sample?.partnerId);
      }
      if (appends.doctor) {
        partnerIds.push(sample?.serviceInformation?.nipt?.doctorId);
      }
      kitIds.push(sample?.kitId);
      serviceDataIds.push(sample?.serviceDataId);
    });

    return forkJoin([
      appends.partner || appends.doctor
        ? this.store.dispatch(new LoadPartnersByIds(partnerIds))
        : of(null),
      appends.serviceData
        ? this.store
            .dispatch(
              new GetServiceDataListAction(
                { ids: serviceDataIds },
                {},
                null,
                false
              )
            )
            .pipe(
              switchMap(() =>
                this.store
                  .select(ServiceDataState.getServiceDatasByIds)
                  .pipe(map((getByIds) => getByIds(serviceDataIds)))
              ),
              first(),
              switchMap((serviceDatas) =>
                this.serviceDataUtils.loadServiceDataConnectedData(
                  serviceDatas,
                  appends.serviceData
                )
              )
            )
        : of(null),
      appends.kit
        ? this.store.dispatch(new LoadKitsByIdAction(kitIds))
        : of(null),
    ]);
  }

  public openDetails(sampleId: string) {
    this.nbDialogService.open(SampleDetailsComponent, {
      context: {
        sampleId,
      },
    });
  }

  public openEdit(sampleId: string, serviceDataId: string) {
    this.nbDialogService.open(SampleDialogComponent, {
      context: {
        sampleId,
        serviceDataId,
      },
    });
  }

  public openResample(sampleId: string, serviceDataId: string, resampleKitId) {
    this.nbDialogService.open(SampleDialogComponent, {
      context: {
        sampleId,
        serviceDataId,
        resampleKitId,
      },
    });
  }

  public replaceKit(sampleId: string) {
    this.nbDialogService.open(SampleKitChangeDialogComponent, {
      context: {
        sampleId,
      },
    });
  }

  buildHttpParams(params: SampleFilters) {
    let httpParams = HttpUtils.buildHttpParams(params);

    if (params.kitIds?.length) {
      httpParams = httpParams.set('kitIds', params.kitIds.join(','));
    }

    if (params.serviceDataPartnerIds?.length) {
      httpParams = httpParams.set(
        'serviceDataPartnerIds',
        params.serviceDataPartnerIds.join(',')
      );
    }

    if (params.laboratories?.length) {
      httpParams = httpParams.set(
        'laboratories',
        params.laboratories.join(',')
      );
    }

    if (params.productIds?.length) {
      httpParams = httpParams.set('productIds', params.productIds.join(','));
    }

    return httpParams;
  }

  public openResults(sampleIds: string[]) {
    this.nbDialogService.open(SampleResultsComponent, {
      context: { sampleIds },
    });
  }

  public exportSelected(
    exportType: ExportType,
    ids?: string[]
  ): Observable<string> {
    const progressSubject = this.exportersUtilsService.openProgressDialog(
      this.translate.instant(
        'sample.export.types.' +
          Object.keys(ExportType)[Object.values(ExportType).indexOf(exportType)]
      )
    );

    return this.exportersUtilsService
      .exportSelected(ExportDialogDataType.Sample, ids, exportType)
      .pipe(
        tap((status: string) => {
          if (status !== RuntimeStatus.Running) {
            progressSubject.next({
              value: 100,
              status: 'Done',
              success: status === RuntimeStatus.Completed ? true : false,
            });
          }
        })
      );
  }

  public export(
    exportType: ExportType,
    filters?: { [key: string]: string }
  ): Observable<string> {
    const progressSubject = this.exportersUtilsService.openProgressDialog(
      this.translate.instant(
        'sample.export.types.' +
          Object.keys(ExportType)[Object.values(ExportType).indexOf(exportType)]
      )
    );

    return this.exportersUtilsService.export(filters, exportType).pipe(
      tap((status: string) => {
        if (status !== RuntimeStatus.Running) {
          progressSubject.next({
            value: 100,
            status: 'Done',
            success: status === RuntimeStatus.Completed ? true : false,
          });
        }
      })
    );
  }
}
