import { AsyncPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PROPERTY_KEYS } from '@app/app.constants';
import { awbModels } from '@app/modules/configuration/configuration.types';
import { AwbAddress, Partner } from '@core/core.types';
import { AwbService } from '@core/services/awb.service';
import { CountriesState } from '@core/store/countries/countries.state';
import { KitsState } from '@core/store/kits/kits.state';
import { PartnersState } from '@core/store/partners/partners.state';
import { Util } from '@core/utils/core.util';
import { NbInputModule, NbToastrService } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, combineLatest, merge } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { filter, map, take } from 'rxjs/operators';
import { LoadKitById } from '@core/store/kits/kits.actions';

@Component({
  selector: 'app-waybill-generation',
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    NbInputModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './waybill-generation.component.html',
})
export class WaybillGenerationComponent implements OnInit {
  @Output() waybillGenerated = new EventEmitter<{
    waybill: string;
    documentId: string;
    address: AwbAddress;
  }>();

  @Input() set partnerId(partnerId) {
    this.partnerId$.next(partnerId);
  }
  @Input() set doctorId(doctorId) {
    this.doctorId$.next(doctorId);
  }
  @Input() set kitId(kitId) {
    this.kitId$.next(kitId);
  }
  @Input() awbModel: string;
  @Input() set address(address) {
    if (address) {
      this.awbAddressForm.patchValue({
        name: address?.fullName,
        company: address?.companyName,
        address: address?.street,
        city: address?.city,
        zip: address?.postCode,
        country: address?.countryCode,
        phone: address?.contactNumber,
      });
    }
  }

  protected isLoading$ = new Subject<boolean>();

  protected awbAddressForm = this.fb.group({
    name: [''],
    company: [''],
    address: [''],
    city: [''],
    zip: [''],
    country: [{ value: '', disabled: true }],
    phone: [''],
    laboratory: [''],
  });

  private doctorId$ = new BehaviorSubject<string>(null);
  private kitId$: BehaviorSubject<string> = new BehaviorSubject(null);
  private partnerId$: BehaviorSubject<string> = new BehaviorSubject(null);
  private partner$: Observable<Partner>;

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private awbService: AwbService,
    private toastr: NbToastrService
  ) {}

  ngOnInit() {
    this.awbAddressForm.patchValue(this.address);
    this.kitId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.awbAddressForm.disable();
    });

    this.partner$ = this.partnerId$.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter((partnerId) => !!partnerId),
      switchMap((partnerId) =>
        this.store
          .select(PartnersState.getPartnerById)
          .pipe(map((getById) => getById(partnerId)))
      )
    );

    this.doctorId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((doctorId) => !!doctorId),
        switchMap((doctorId) =>
          this.store
            .select(PartnersState.getPartnerById)
            .pipe(map((findById) => findById(doctorId)))
        )
      )
      .subscribe((doctor) => {
        this.awbAddressForm.patchValue({
          ...Util.PropertiesToObject(doctor?.properties),
          phone: doctor?.phoneNumber,
          name: doctor?.name,
        });
      });

    this.partner$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((partner) =>
          combineLatest([
            this.store
              .select(CountriesState.getCountryByName)
              .pipe(map((findById) => findById(partner?.address?.country))),
            merge(this.kitId$.pipe(filter((kitId) => !!kitId))).pipe(
              switchMap((kitId) =>
                this.store
                  .dispatch(new LoadKitById(kitId))
                  .pipe(
                    switchMap(() =>
                      this.store
                        .select(KitsState.getKitById)
                        .pipe(map((getById) => getById(kitId)))
                    )
                  )
              )
            ),
          ])
        )
      )
      .subscribe(([country, kit]) => {
        if (!country || !kit || country.awbModel === awbModels.pregenerated) {
          this.awbAddressForm.disable();
        } else {
          this.awbAddressForm.enable();
          this.awbAddressForm.get('country').disable();
          this.awbAddressForm.patchValue({ laboratory: kit.laboratory });
        }

        this.awbAddressForm.patchValue({
          country: country?.name,
        });
      });
  }

  protected generateAwb() {
    this.awbAddressForm.markAllAsTouched();
    if (this.awbAddressForm.valid) {
      const formValue = this.awbAddressForm.getRawValue();
      const awbAddress: AwbAddress = {
        fullName: formValue.name,
        companyName: formValue.company,
        street: formValue.address,
        city: formValue.city,
        postCode: formValue.zip,
        contactNumber: formValue.phone,
        countryCode: null,
      };

      this.isLoading$.next(true);
      combineLatest([
        this.store.select(CountriesState.getCountryByName).pipe(
          take(1),
          map((getByName) =>
            getByName(this.awbAddressForm.controls.country.value)
          )
        ),
        this.partner$.pipe(take(1)),
      ])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          switchMap(([country, partner]) => {
            awbAddress.countryCode = country.isoCode3;
            const plasmaLabLocation = partner.properties.find(
              (p) =>
                p.key.toLowerCase() ===
                PROPERTY_KEYS.PlasmaLabLocation.toLowerCase()
            );
            return this.awbService.generateDhlWaybill({
              ...awbAddress,
              recipientLaboratory:
                plasmaLabLocation?.value ?? formValue.laboratory,
            });
          })
        )
        .subscribe({
          next: (awbData) => {
            this.waybillGenerated.emit({
              documentId: awbData.dhlWaybillDocumentId,
              waybill: awbData.dhlWaybill,
              address: awbAddress,
            });
            this.isLoading$.next(false);
          },
          error: (resp) => {
            if (resp?.error?.errors?.length) {
              for (const error of resp.error.errors) {
                this.toastr.danger(error.error, error.propertyName, {
                  duration: 10000,
                });
              }
            }
            if (resp?.error?.Message) {
              this.toastr.danger(resp.error.Message, '', {
                duration: 10000,
              });
            }

            this.isLoading$.next(false);
          },
        });
    }
  }

  protected readonly awbModels = awbModels;
}
