import { FormGroup } from '@angular/forms';
import { sampleValidationGroups } from '@app/modules/service-data/components/sample/sample-dialog/sample-validation-configuration';
import { serviceDataValidationGroups } from '@app/modules/service-data/components/service-data/service-data-form/service-data-validation-configuration';
import {
  CustomReportGenerationData,
  DataInputTypeValidationGroups,
  ExtendedProductInventoryItem,
  ValidationPair,
} from '@app/modules/service-data/service-data.types';
import { Product } from '@core/core.types';

export enum ValidatorsHandlerType {
  SERVICEDATA = 'serviceData',
  SAMPLE = 'sample',
}

export function handleValidators(
  products: Product[],
  group: FormGroup,
  root: string,
  type: ValidatorsHandlerType,
  ignore: string[] = []
) {
  clearValidators(group, root, ignore);
  setValidators(
    group,
    root,
    type,
    products
      .map((product) => product?.dataInputType)
      .filter((inputType) => !!inputType)
  );
}

// Clear all validators from group (recursively)
function clearValidators(
  group: FormGroup,
  controlName: string,
  ignore: string[] = []
) {
  if (ignore.includes(controlName)) {
    return;
  }
  const control = controlName ? group.get(controlName) : group;

  if (control instanceof FormGroup) {
    Object.keys(control.controls).forEach((key) => {
      clearValidators(group, controlName ? `${controlName}.${key}` : key);
    });
  } else {
    control.clearValidators();
    control.updateValueAndValidity();
  }
}

// Set validators for selected products
function setValidators(
  group: FormGroup,
  controlName: string,
  type: ValidatorsHandlerType,
  inputTypes: string[]
) {
  let selectedValidationGroups = [];
  for (const inputType of inputTypes) {
    selectedValidationGroups = selectedValidationGroups.concat(
      DataInputTypeValidationGroups[inputType]
    );
  }
  selectedValidationGroups = [...new Set(selectedValidationGroups)];
  const validationGroups =
    type === ValidatorsHandlerType.SERVICEDATA
      ? serviceDataValidationGroups
      : sampleValidationGroups;
  for (const groupName of selectedValidationGroups) {
    if (!validationGroups[groupName]) {
      continue;
    }
    setValidatorsRecursively(group, controlName, validationGroups[groupName]);
  }
}

function setValidatorsRecursively(
  group: FormGroup,
  controlName: string,
  validationGroup: ValidationPair
) {
  const control = controlName ? group.get(controlName) : group;
  if (validationGroup.validators) {
    control.addValidators(validationGroup.validators);
    control.updateValueAndValidity();
  }
  if (validationGroup.children) {
    for (const key of Object.keys(validationGroup.children)) {
      setValidatorsRecursively(
        group,
        controlName ? `${controlName}.${key}` : key,
        validationGroup.children[key]
      );
    }
  }
}

export const redirectToDhl = (dhlWaybill: string) => {
  window.open(
    `https://www.dhl.com/si-en/home/tracking/tracking-express.html?submit=1&tracking-id=${dhlWaybill.replace(
      / /g,
      ''
    )}`,
    '_blank'
  );
};

export const trimBarcode = (barcode: string) => {
  let bar = barcode;
  if (barcode && barcode.includes('-')) {
    bar = bar.split('-')[0];
  }

  if (barcode && barcode.includes('D')) {
    bar = bar.replace('D', '');
  }

  if (barcode && barcode.includes('R')) {
    bar = bar.replace('R', '');
  }

  if (barcode && barcode.includes('V')) {
    bar = bar.replace('V', '');
  }

  return bar;
};

export const getReportReportGenerationData = (
  data: ExtendedProductInventoryItem,
  approvedBy: string,
  partnerLanguageCode: string
): CustomReportGenerationData => ({
  Barcode: data.sample.kitId,
  PatientName: `${data.sample.serviceData.patientInformation.firstName} ${data.sample.serviceData.patientInformation.lastName}`,
  PatientDateOfBirth: new Date(
    data.sample.serviceData.patientInformation.dateOfBirth
  ).toISOString(),
  GestationWeeks:
    data.sample.serviceData.serviceInformation.nipt.currentPregnancyInformation
      .gestationAge.week,
  GestationDays:
    data.sample.serviceData.serviceInformation.nipt.currentPregnancyInformation
      .gestationAge.day,
  DocumentId: data.sample.serviceData.patientInformation.documentNumber,
  DateOfVenipuncture: new Date(data.sample.dateOfVenipuncture).toISOString(),
  DateOfConfinement: new Date(
    data.sample.serviceData.serviceInformation.nipt.currentPregnancyInformation.expectedConfinementDate
  ).toISOString(),
  ServiceName: data.product.name,
  CountryCode: data.partner.address.countryIsoCode,
  LanguageCode: partnerLanguageCode,
  ApprovedBy: approvedBy,
  Gender:
    data.sample.serviceData.serviceInformation.nipt.provideGenderInformation,
  IncidentalFindings:
    data.sample.serviceData.serviceInformation.nipt.incidentalFindings,
  productInventoryItemId: data.id,
  testId: data.id,
  sampleExtractorId: data.sample.doctor.id,
  doctorId: data.sample.serviceData.doctor.id,
  SampleReceived: data.sample.kit.returned
    ? new Date(data.sample.kit.returned.timestamp).toISOString()
    : null,
  DoctorName: data.sample.serviceData.doctor.name,
});
