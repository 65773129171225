import { PaymentType, TemplateType } from '@core/core.types';
import {
  Document,
  DocumentNotQualifiedKeywords,
  DocumentNotQualifiedType,
  DocumentType,
  ServiceData,
} from '../../service-data/service-data.types';

export const getTemplateType = (
  document: Document,
  serviceData: ServiceData
): TemplateType => {
  const documentType = document.properties.find(
    (p) => p.key === 'DocumentType'
  ).value;

  if (documentType === DocumentType.Delay) {
    return TemplateType.Delay;
  } else if (documentType === DocumentType.Resample) {
    return TemplateType.Resample;
  } else if (
    documentType === DocumentType.Delay ||
    documentType === DocumentType.Refund ||
    documentType === DocumentType.NoCall
  ) {
    if (serviceData.paymentInformation.paymentType === PaymentType.Partner) {
      return TemplateType.RefundPartnerPayment;
    } else {
      return TemplateType.RefundCustomerPayment;
    }
  } else if (documentType === DocumentType.HighRisk) {
    return TemplateType.HighRisk;
  } else if (documentType === DocumentType.NotQualified) {
    const newName = document.fileName
      .toLowerCase()
      .replace(
        DocumentNotQualifiedKeywords.NotQualifiedByLaboratory,
        DocumentNotQualifiedKeywords.NotQualified
      )
      .replace(
        DocumentNotQualifiedKeywords.NotQualifiedByPartner,
        DocumentNotQualifiedKeywords.NotQualified
      );

    const notQualifiedReason = getReasonFromFileName(
      newName,
      DocumentNotQualifiedKeywords.NotQualified
    );
    if (
      notQualifiedReason === DocumentNotQualifiedType.DHL.toLowerCase() ||
      notQualifiedReason ===
        DocumentNotQualifiedType.NinetySixHPlus.toLowerCase()
    ) {
      return TemplateType.ResampleDHL;
    } else if (
      notQualifiedReason ===
      DocumentNotQualifiedType.InsufficientVolume.toLowerCase()
    ) {
      return TemplateType.InsufficientBlood;
    } else if (
      notQualifiedReason === DocumentNotQualifiedType.Hemolysis.toLowerCase()
    ) {
      return TemplateType.Hemolysis;
    } else if (
      notQualifiedReason === DocumentNotQualifiedType.DamagedTube.toLowerCase()
    ) {
      return TemplateType.DamagedTube;
    } else {
      return TemplateType.NotQualified;
    }
  } else if (documentType === DocumentType.Canceled) {
    return TemplateType.Canceled;
  } else {
    return TemplateType.OfficialReport;
  }
};

export const getReasonFromFileName = (
  fileName: string,
  templateType: string
) => {
  let splitted = fileName.split(templateType);
  if (splitted.length > 1) {
    splitted = splitted[splitted.length - 1].split('-');
    if (splitted.length > 1) {
      return splitted[1].replace('.pdf', '').split('_')[0];
    }
  }
  return null;
};
