import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SampleDialogComponent } from '@app/modules/service-data/components/sample/sample-dialog/sample-dialog.component';
import {
  Sample,
  SampleStatus,
  ServiceData,
} from '@app/modules/service-data/service-data.types';
import { SampleUtilsService } from '@app/modules/service-data/services/sample-utils.service';
import { ProductInventoryItemState } from '@app/modules/service-data/store/product-inventory-item.state';
import {
  GetSamplesListAction,
  RemoveSampleAction,
} from '@app/modules/service-data/store/sample.actions';
import { SampleState } from '@app/modules/service-data/store/sample.state';
import { ServiceDataState } from '@app/modules/service-data/store/service-data.state';
import { ProductsListState } from '@core/store/products/products.state';
import { NbCardModule, NbDialogService, NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ConfirmService } from '@shared/components/confirm/confirm-dialog.component';
import { DataTableComponent } from '@shared/components/data-table/data-table.component';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableDataType,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { ConfirmCloseReason } from '@shared/shared.types';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { map, shareReplay, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-samples-list',
  standalone: true,
  imports: [DataTableComponent, AsyncPipe, NbCardModule],
  templateUrl: './samples-list.component.html',
  styleUrl: './samples-list.component.scss',
})
export class SamplesListComponent implements OnInit {
  @Input() readonly = false;

  @Input() serviceDataId: string;
  samples$: Observable<Sample[]>;
  serviceData$: Observable<ServiceData>;
  requiredSamples$: Observable<number>;

  tableConfiguration: DataTableConfiguration;

  constructor(
    private store: Store,
    private dialogService: NbDialogService,
    private confirmService: ConfirmService,
    private sampleUtils: SampleUtilsService,
    private translateService: TranslateService,
    private toast: NbToastrService
  ) {}

  ngOnInit() {
    this.serviceData$ = this.store
      .select(ServiceDataState.getServiceDataById)
      .pipe(map((getById) => getById(this.serviceDataId)));

    this.samples$ = this.serviceData$.pipe(
      map((serviceData) => serviceData.sampleIds),
      switchMap((ids) =>
        this.store
          .dispatch(new GetSamplesListAction({ ids }, {}, null, false))
          .pipe(map(() => ids))
      ),
      switchMap((ids) =>
        this.store
          .select(SampleState.getSamplesByIds)
          .pipe(map((getByIds) => getByIds(ids)))
      ),
      switchMap((samples) =>
        this.sampleUtils.getExtendedSampleList(samples, {
          product: true,
          kit: true,
          serviceData: {},
        })
      ),
      shareReplay(1)
    );

    this.requiredSamples$ = this.serviceData$.pipe(
      switchMap((serviceData) =>
        this.store
          .select(ProductsListState.getBundles)
          .pipe(map((getByIds) => getByIds(serviceData.bundleIds)))
      ),
      map(
        (bundles) => bundles.map((bundle) => bundle.productIds).flat(1).length
      )
    );

    this.tableConfiguration = {
      rows$: this.samples$,
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      actionsLocation: !this.readonly
        ? DataTableActionsLocation.RIGHT
        : DataTableActionsLocation.TOP,
      columns: [
        {
          label: 'Kit Id',
          property: 'kitId',
        },
        {
          label: 'Date of venipuncture',
          property: 'dateOfVenipuncture',
          type: DataTableDataType.DATETIME,
        },
        {
          label: 'Product',
          property: 'product.name',
        },
        {
          label: 'Waybill',
          property: 'shippingInformation.waybill',
          permission: 'serviceData.details.sample.details.waybillList',
        },
        {
          label: 'Laboratory',
          property: 'kit.laboratory',
          permission: 'serviceData.details.sample.list.laboratory',
        },
        {
          label: 'Status',
          property: 'status',
        },
      ],
      addAction: !this.readonly
        ? {
            name: 'Add sample',
            icon: 'plus-outline',
            type: DataTableActionType.BUTTON,
            action: this.newSample.bind(this),
            permission: 'serviceData.details.sample.addSample',
          }
        : null,
      singleActions: !this.readonly
        ? [
            {
              name: 'Sample details',
              icon: 'file-outline',
              type: DataTableActionType.BUTTON,
              action: this.sampleDetails.bind(this),
              permission: 'serviceData.details.sample.detailsButton',
            },
            {
              name: 'Resample',
              icon: 'droplet-outline',
              type: DataTableActionType.BUTTON,
              disabled: (sample: Sample) =>
                sample.status !== SampleStatus.forResample,
              action: this.resample.bind(this),
              permission: 'serviceData.details.sample.addSample',
            },
            {
              name: 'Edit sample',
              icon: 'edit-outline',
              type: DataTableActionType.BUTTON,
              action: this.sampleEdit.bind(this),
              permission: 'serviceData.details.sample.editSample',
            },
            {
              name: 'Remove sample',
              icon: 'close-outline',
              type: DataTableActionType.BUTTON,
              action: this.removeSample.bind(this),
              permission: 'serviceData.details.sample.delete',
            },
          ]
        : null,
    };
  }

  newSample() {
    this.dialogService.open(SampleDialogComponent, {
      context: {
        serviceDataId: this.serviceDataId,
      },
    });
  }

  sampleDetails(e: Event, sample: Sample) {
    this.sampleUtils.openDetails(sample.id);
  }

  sampleEdit(e: Event, sample: Sample) {
    this.sampleUtils.openEdit(sample.id, sample.serviceDataId);
  }

  resample(e: Event, sample: Sample) {
    this.sampleUtils.openResample(
      sample.id,
      sample.serviceDataId,
      sample.kitId
    );
  }

  removeSample(e: Event, sample: Sample) {
    this.store
      .select(ProductInventoryItemState.getProductInventoryItemsByIds)
      .pipe(
        map((getByIds) => getByIds(sample.productInventoryItems)),
        first(),
        switchMap((piis) => {
          const documents = piis.map((pii) => pii?.documents ?? []).flat(1);
          if (!documents.length) {
            return this.confirmService.confirm({
              title: this.translateService.instant(
                'sample.details.remove.title'
              ),
              message: this.translateService.instant(
                'sample.details.remove.message'
              ),
            });
          } else {
            this.toast.warning(
              'Sample product inventory items already have documents attached. Remove documents first.',
              "Can't delete this sample."
            );
            return of(null);
          }
        })
      )
      .subscribe((result) => {
        if (result?.closeReason === ConfirmCloseReason.Yes) {
          this.store.dispatch(new RemoveSampleAction(sample.id));
        }
      });
  }
}
