import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NIFTY_APPLICATIONID_KEY, PAGING_HEADERS } from '@app/app.constants';
import { ProductInventoryItemUtilsService } from '@app/modules/service-data/services/product-inventory-item-utils.service';
import {
  ApiFilters,
  ApiHeaders,
  ExportedSagaState,
  N2dStatistics,
  ProductInventory,
} from '@core/core.types';
import { HttpUtils } from '@core/utils/http-utils';
import { environment } from '@root/environments/environment';
import { Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class ProductInventoryItemService {
  readonly API_URL = `${environment.apiUrl}/productInventoryItems`;
  constructor(
    private http: HttpClient,
    private productInventoryItemUtilsService: ProductInventoryItemUtilsService
  ) {}

  getProductInventoryItemListIds(filters: ProductInventoryItemFilters) {
    return this.http.get<string[]>(`${this.API_URL}/ids`, {
      params: this.productInventoryItemUtilsService.buildHttpParams({
        ...filters,
        applicationId: NIFTY_APPLICATIONID_KEY,
      }),
    });
  }

  getProductInventoryItemList(
    filters: ProductInventoryItemFilters,
    headers: ApiHeaders
  ): Observable<{
    items: ProductInventory[];
    continuationToken: string;
    totalCount: number;
  }> {
    return this.http
      .get<ProductInventory[]>(this.API_URL, {
        params: this.productInventoryItemUtilsService.buildHttpParams({
          ...filters,
          applicationId: NIFTY_APPLICATIONID_KEY,
        }),
        headers: HttpUtils.buildHttpHeaders(headers),
        observe: 'response',
      })
      .pipe(
        map((response) => ({
          items: response.body,
          continuationToken: response.headers.get(
            PAGING_HEADERS.continuationToken
          ),
          totalCount: parseInt(
            response.headers.get(PAGING_HEADERS.totalCount),
            10
          ),
        }))
      );
  }

  getProductInventoryItem(id: string) {
    return this.http.get<ProductInventory>(`${this.API_URL}/${id}`);
  }

  assignDocumentToProductInventoryItem(id: string, documentId: string) {
    return this.http.patch(`${this.API_URL}/${id}/documents/${documentId}`, {});
  }

  unassignDocumentFromProductInventoryItem(id: string, documentId: string) {
    return this.http.delete(`${this.API_URL}/${id}/documents/${documentId}`);
  }

  productInventoryItemsInProcess(
    filters: {
      dateOfVenipunctureFrom: string;
      dateOfVenipunctureTo: string;
      country?: string;
      laboratories?: string[];
      productInventoryItemStatus: string;
    },
    headers: ApiHeaders
  ): Observable<{ items: string[]; totalCount: number }> {
    return this.productInventoryItemsInProcessRecursion([], filters, null);
  }

  productInventoryItemsInProcessRecursion(
    accumulator: string[] = [],
    filters,
    continuationToken
  ) {
    return this.http
      .get<string[]>(
        `${environment.apiUrl}/service-data/samples/product-inventory-items`,
        {
          params: filters,
          observe: 'response',
          headers: HttpUtils.buildHttpHeaders({ continuationToken }),
        }
      )
      .pipe(
        switchMap((response) => {
          const ct = response.headers.get(PAGING_HEADERS.continuationToken);
          accumulator = [...accumulator, ...response.body];
          if (ct) {
            return this.productInventoryItemsInProcessRecursion(
              accumulator,
              filters,
              ct
            );
          } else {
            return of({
              items: accumulator,
              totalCount: parseInt(
                response.headers.get(PAGING_HEADERS.totalCount),
                10
              ),
            });
          }
        })
      );
  }

  public sendResultReferenceLink(piiId) {
    return this.http.post(
      `${environment.apiUrl}/service-data/product-inventory-items/${piiId}/reference-link`,
      {}
    );
  }

  public markPiisAsExported(
    productInventoryItemIds: string[]
  ): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      `${environment.apiUrl}/service-data/product-inventory-items/batch`,
      { productInventoryItemIds }
    );
  }

  public checkExportedState(id: string): Observable<ExportedSagaState> {
    return this.http.get<ExportedSagaState>(
      `${environment.apiUrl}/service-data/product-inventory-items/batch/${id}`
    );
  }

  n2dStatistics(kitId): Observable<N2dStatistics> {
    return this.http.get<N2dStatistics>(
      `${environment.apiUrl}/nipt-online/n2d-information/${kitId}`
    );
  }
}

export interface ProductInventoryItemFilters extends ApiFilters {
  ids?: string[];
  kitToken?: string;
  productId?: string;
  productIds?: string[];
  kitId?: string;
  kitIds?: string[];
  customerId?: string;
  orderId?: string;
  partnerId?: string;
  applicationId?: string;
  source?: string;
  sources?: string;
  documentStatus?: string;
  documentIds?: string[];
  documentReasonToken?: string;
  countries?: string[];
  laboratory?: string;
  lastStatus?: string;
  lastStatuses?: string;
  lastStatusChangedFrom?: string;
  lastStatusChangedTo?: string;
  lastStatusChangedReason?: string;
  status?: string;
  statusChangedFrom?: string;
  statusChangedTo?: string;
  propertyKey?: string;
  propertyKeyExists?: boolean;
  propertyValue?: string;
  propertyValueFrom?: string;
  propertyValueTo?: string;
  accountManagerId?: string;
}
