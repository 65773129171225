import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SampleListDialogComponent } from '@app/modules/service-data/components/sample/sample-list-dialog/sample-list-dialog.component';
import { SamplesListComponent } from '@app/modules/service-data/components/sample/samples-list/samples-list.component';
import {
  ExtendedServiceData,
  PregnancyType,
} from '@app/modules/service-data/service-data.types';
import { ServiceDataUtilsService } from '@app/modules/service-data/services/service-data-utils.service';
import { ServiceDataState } from '@app/modules/service-data/store/service-data.state';
import { Bundle } from '@core/core.types';
import { ProductsListState } from '@core/store/products/products.state';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbDialogService,
  NbIconModule,
  NbTooltipModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { RequirePermissionDirective } from '@shared/directives/require-permission.directive';
import { GetListPipe } from '@shared/pipes/get-list.pipe';
import { Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceDataFinanceDetailsComponent } from '../service-data-finance-details/service-data-finance-details.component';

@Component({
  selector: 'app-service-data-details',
  standalone: true,
  templateUrl: './service-data-details.component.html',
  styleUrl: './service-data-details.component.scss',
  imports: [
    NbCardModule,
    TranslateModule,
    SamplesListComponent,
    DatePipe,
    GetListPipe,
    AsyncPipe,
    NbIconModule,
    NbButtonModule,
    NbTooltipModule,
    RequirePermissionDirective,
  ],
})
export class ServiceDataDetailsComponent implements OnInit {
  @Input()
  public serviceDataId: string;

  @Select(ProductsListState.getBundlesList)
  protected bundles$: Observable<Bundle[]>;

  protected extendedServiceData$: Observable<ExtendedServiceData>;

  constructor(
    private store: Store,
    protected serviceDataUtilsService: ServiceDataUtilsService,
    public dialogRef: NbDialogRef<ServiceDataDetailsComponent>,
    private dialogService: NbDialogService
  ) {}

  ngOnInit() {
    this.extendedServiceData$ = this.store
      .select(ServiceDataState.getServiceDataById)
      .pipe(
        map((getById) => getById(this.serviceDataId)),
        switchMap((serviceData) =>
          this.serviceDataUtilsService.getExtendedServiceData(serviceData, {
            partner: true,
            doctor: true,
            bundles: true,
            invoice: true,
          })
        )
      );
  }

  protected manageSamples(serviceDataId, sampleIds) {
    this.dialogService.open(SampleListDialogComponent, {
      context: {
        serviceDataId,
        sampleIds,
      },
    });
  }

  protected editServiceData(serviceDataId: string) {
    this.serviceDataUtilsService.openEdit(serviceDataId);
  }

  protected openPaymentDetails(serviceDataId: string) {
    this.dialogService.open(ServiceDataFinanceDetailsComponent, {
      context: { id: serviceDataId },
    });
  }

  protected readonly PregnancyType = PregnancyType;
}
