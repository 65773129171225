import { Injectable } from '@angular/core';
import { PatientForm } from '@app/modules/patient-forms/patient-forms.types';
import {
  CompletePatientFormAction,
  LoadPatientFormsAction,
  PATIENT_FORMS_STATE_TYPE_NAME,
  ProvisionPatientFormAction,
  ProvisionPatientFormUpdateAction,
} from '@app/modules/patient-forms/state/patient-forms.actions';
import { PatientFormsService } from '@app/modules/patient-forms/state/patient-forms.service';
import {
  Pagination,
  PaginationAndOrdering,
  SortDirection,
  StatePageSettings,
} from '@core/core.types';
import { Util } from '@core/utils/core.util';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { map, tap } from 'rxjs/operators';

export interface PatientFormsStateModel {
  patientForms: { [id: string]: PatientForm };
  pages?: {
    [key: string]: {
      items: string[];
      continuationToken: string;
    };
  };
  filter?: PatientFormsFilter;
  totalCount?: number;
  currentPageSettings: StatePageSettings<PatientFormsFilter>;
}

export interface PatientFormsFilter {
  ids?: string[];
  emailToken?: string;
  fullnameToken?: string;
  partnerId?: string;
  lastStatuses?: string[];
  lastStatusChangedFrom?: Date;
  lastStatusChangedTo?: Date;
  status?: string;
  statusChangedFrom?: Date;
  statusChangedTo?: Date;
}

const DEFAULT_ORDERS_STATE: PatientFormsStateModel = {
  patientForms: {},
  pages: {},
  totalCount: 0,
  currentPageSettings: {
    pageIndex: 0,
    sortSettings: {
      orderBy: 'generated',
      sortDirection: SortDirection.desc,
      pageSize: 20,
    },
  },
};

@State<PatientFormsStateModel>({
  name: PATIENT_FORMS_STATE_TYPE_NAME,
  defaults: {
    ...DEFAULT_ORDERS_STATE,
  },
})
@Injectable({
  providedIn: 'root',
})
export class PatientFormsState {
  constructor(
    private store: Store,
    private patientFormsService: PatientFormsService
  ) {}

  @Selector()
  static getPatientFormById({ patientForms }: PatientFormsStateModel) {
    return (patientFormId: string) => patientForms[patientFormId];
  }

  @Selector()
  static getPaginationInfo({
    currentPageSettings,
    totalCount,
    pages,
  }: PatientFormsStateModel): Pagination<PatientFormsFilter> {
    return {
      currentPageSettings,
      totalCount,
      pagesSize: Object.keys(pages).length,
      collectionSize: currentPageSettings.sortSettings.pageSize,
    };
  }

  @Selector()
  static getCurrentPage(state: PatientFormsStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;
    if (
      Object.keys(pages).length === 0 ||
      pageIndex > Object.keys(pages).length ||
      pageIndex < 0
    ) {
      return [];
    }
    return state.pages[pageIndex].items.map((id) => state.patientForms[id]);
  }

  @Action(LoadPatientFormsAction)
  loadPatientForms(
    { getState, patchState, dispatch }: StateContext<PatientFormsStateModel>,
    { options }: LoadPatientFormsAction
  ) {
    const state = getState();
    let reload = false;

    const { sortDirection, orderBy, pageSize } =
      state.currentPageSettings.sortSettings;

    let pageToLoad = options.pageToLoad ? options.pageToLoad - 1 : 0;

    const filter = options.filter;
    filter.partnerId = filter.partnerId ?? '';
    const filterMap = new Map<string, string>();

    // TODO: after APIs merge -> clear empty query params -> use utils
    Object.keys(filter).forEach((value, key) => {
      filterMap.set(value, filter[value]);
    });

    // TODO: after APIs merge -> use utils params compare instead of JSON
    if (JSON.stringify(filter) !== JSON.stringify(state.filter)) {
      pageToLoad = 0;
      reload = true;
    }

    const paginationAndOrdering: PaginationAndOrdering = {
      orderDirection: options.orderDirection ?? sortDirection,
      top: options.pageSize ?? pageSize,
      orderBy: options.orderBy ?? orderBy,
      continuationToken: state.pages[pageToLoad - 1]?.continuationToken,
      filters: filterMap,
    };

    if (state.pages[pageToLoad] && !reload) {
      patchState({
        currentPageSettings: {
          ...state.currentPageSettings,
          pageIndex: pageToLoad,
        },
      });
      return;
    }

    return this.patientFormsService
      .getFilteredEntities(paginationAndOrdering)
      .pipe(
        tap((response) => {
          const pages = reload ? {} : state.pages;
          patchState({
            pages: {
              ...pages,
              [String(pageToLoad)]: {
                items: response.entitiesList.map((item) => item.id),
                continuationToken: response.newContinuationToken,
              },
            },
            currentPageSettings: {
              ...state.currentPageSettings,
              pageIndex: pageToLoad,
              sortSettings: {
                orderBy: paginationAndOrdering.orderBy,
                sortDirection: paginationAndOrdering.orderDirection,
                pageSize: paginationAndOrdering.top,
              },
            },
            patientForms: {
              ...state.patientForms,
              ...response.entitiesList.reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
              }, {}),
            },
            filter,
            totalCount: response.totalCount,
          });
        })
      );
  }

  @Action(ProvisionPatientFormAction)
  provisionPatientFormAction(
    { getState, patchState, dispatch }: StateContext<PatientFormsStateModel>,
    { data }: ProvisionPatientFormAction
  ) {
    const id = Util.CreateGuid();

    const formData = { ...data, id };
    const extendedFormData: PatientForm = {
      ...formData,
      partnerId: formData.partner,
      productId: formData.productType,
      patientInformation: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        telephoneNumber: formData.phone,
      },
      lastStatus: 'Generated',
      generated: { by: '', timestamp: new Date() },
      lastStatusChanged: new Date().toISOString(),
    };
    this.patientFormsService
      .provisionPatientForm(formData)
      .pipe(
        map(() => {
          const state = getState();

          return patchState({
            patientForms: {
              ...state.patientForms,
              [id]: extendedFormData,
            },
            pages: {
              ...state.pages,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              0: {
                items: [extendedFormData.id, ...state.pages[0].items],
                continuationToken: state.pages[0].continuationToken,
              },
            },
          });
        })
      )
      .subscribe();
  }

  @Action(ProvisionPatientFormUpdateAction)
  provisionPatientFormUpdateAction(
    { getState, patchState, dispatch }: StateContext<PatientFormsStateModel>,
    { data }: ProvisionPatientFormUpdateAction
  ) {
    const extendedFormData: PatientForm = {
      ...data,
      id: data.id,
      partnerId: data.partner,
      productId: data.productType,
      patientInformation: {
        firstName: data.firstName,
        lastName: data.lastName,
        telephoneNumber: data.phone,
      },
      lastStatus: 'Generated',
      generated: { by: '', timestamp: new Date() },
      lastStatusChanged: new Date().toISOString(),
    };
    this.patientFormsService
      .provisionPatientFormUpdate(data)
      .pipe(
        map(() => {
          const state = getState();

          return patchState({
            patientForms: {
              ...state.patientForms,
              [extendedFormData.id]: extendedFormData,
            },
          });
        })
      )
      .subscribe();
  }

  @Action(CompletePatientFormAction)
  completePatientFormAction(
    { patchState, getState }: StateContext<PatientFormsStateModel>,
    { patientFormId }: CompletePatientFormAction
  ) {
    return this.patientFormsService.completePatientForm(patientFormId).pipe(
      tap(() => {
        const state = getState();

        const form = { ...state.patientForms[patientFormId] };
        form.completed = {
          by: '',
          timestamp: new Date(),
        };
        form.lastStatus = 'Completed';
        form.lastStatusChanged = form.completed.timestamp.toISOString();

        patchState({
          patientForms: { ...state.patientForms, [patientFormId]: form },
        });
      })
    );
  }
}
