import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageRemovalResponse } from '@app/modules/dashboard/dashboard.types';
import {
  BgiResult,
  BgiXlsResult,
  CustomReportGenerationData,
  ExtendedProductInventoryItem,
  GeneratedReport,
  ReportResult,
} from '@app/modules/service-data/service-data.types';
import { LoadPartnersByIds } from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import { Store } from '@ngxs/store';
import { environment } from '@root/environments/environment';
import moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { first } from 'rxjs/internal/operators/first';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private REPORT_MANAGER_CODE: string;
  private REPORT_GENERATOR_ENDPOINT: string;
  private REPORT_PROVISIONER_ENDPOINT: string;
  private CUSTOM_REPORT_GENERATOR_ENDPOINT: string;
  private REPORT_PAGE_REMOVAL_ENDPOINT: string;
  private readonly routes = {
    generateReport: () =>
      `${this.REPORT_GENERATOR_ENDPOINT}?code=${this.REPORT_MANAGER_CODE}`,
    provisionReport: () =>
      `${this.REPORT_PROVISIONER_ENDPOINT}?code=${this.REPORT_MANAGER_CODE}`,
    generateCustomReport: () =>
      `${this.CUSTOM_REPORT_GENERATOR_ENDPOINT}?code=${this.REPORT_MANAGER_CODE}`,
    reportPageRemoval: () =>
      `${this.REPORT_PAGE_REMOVAL_ENDPOINT}?code=${this.REPORT_MANAGER_CODE}`,
  };

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) {
    this.REPORT_MANAGER_CODE = environment.reportManagerCode;
    this.REPORT_GENERATOR_ENDPOINT = `${environment.reportManagerUrl}/ReportGenerator`;
    this.REPORT_PROVISIONER_ENDPOINT = `${environment.reportManagerUrl}/ReportProvisioner`;
    this.CUSTOM_REPORT_GENERATOR_ENDPOINT = `${environment.reportManagerUrl}/CustomReportGenerator`;
    this.REPORT_PAGE_REMOVAL_ENDPOINT = `${environment.reportManagerUrl}/ReportPageRemoval`;
  }

  bgiXlsParser(fileName, data) {
    return this.httpClient
      .post<{
        Success: boolean;
        Results: BgiXlsResult[];
        Errors?: string[];
        FileName?: string;
      }>(
        `${environment.reportManagerUrl}/HkExcelParser?code=${this.REPORT_MANAGER_CODE}`,
        { FileName: fileName, Report: data }
      )
      .pipe(
        catchError((err) =>
          of({ Results: [], Errors: [err?.error], Success: false })
        ),
        map((response) => {
          let i = 0;
          for (const result of response.Results) {
            (result as BgiXlsResult).index = i;
            result.KitId = result.KitId.split('-')[0];
            result.Result.SampleReceived = moment(result.Result.SampleReceived);
            result.Result.Dated = moment(result.Result.Dated);
            i++;
          }
          return response;
        })
      );
  }

  generateReport(generateReportBody: {
    Results: ReportResult[];
    Treatment: CustomReportGenerationData;
  }): Observable<GeneratedReport> {
    return this.getPartnerReportLanguage(generateReportBody.Treatment).pipe(
      switchMap((language) => {
        generateReportBody.Treatment.LanguageCode = language;

        return this.httpClient.post<GeneratedReport>(
          this.routes.generateReport(),
          generateReportBody
        );
      })
    );
  }

  generateHkReport(generateReportBody): Observable<GeneratedReport> {
    return this.getPartnerReportLanguage(generateReportBody.Treatment).pipe(
      switchMap((language) => {
        generateReportBody.Treatment.LanguageCode = language;

        return this.httpClient.post<GeneratedReport>(
          `${environment.reportManagerUrl}/HkReportGenerator?code=${this.REPORT_MANAGER_CODE}`,
          generateReportBody
        );
      })
    );
  }

  generateHkReportPostData(
    result: BgiResult,
    data: ExtendedProductInventoryItem,
    languageCode: string,
    approvedBy,
    xlsResult?: BgiXlsResult
  ) {
    try {
      return {
        Treatment: {
          Barcode: data.sample.kitId,
          PatientName: `${data.sample.serviceData.patientInformation.firstName} ${data.sample.serviceData.patientInformation.lastName}`,
          PatientDateOfBirth: new Date(
            data.sample.serviceData.patientInformation.dateOfBirth
          ).toISOString(),
          GestationWeeks:
            data.sample.serviceData.serviceInformation.nipt
              .currentPregnancyInformation.gestationAge.week,
          GestationDays:
            data.sample.serviceData.serviceInformation.nipt
              .currentPregnancyInformation.gestationAge.day,
          DocumentId: data.sample.serviceData.patientInformation.documentNumber,
          DateOfVenipuncture: new Date(
            data.sample.dateOfVenipuncture
          ).toISOString(),
          DateOfConfinement: new Date(
            data.sample.serviceData.serviceInformation.nipt.currentPregnancyInformation.expectedConfinementDate
          ).toISOString(),
          ServiceName: data.product.name,
          CountryCode: data.partner.address.countryIsoCode,
          LanguageCode: languageCode,
          ApprovedBy: approvedBy,
          Gender:
            data.sample.serviceData.serviceInformation.nipt
              .provideGenderInformation,
          IncidentalFindings:
            data.sample.serviceData.serviceInformation.nipt.incidentalFindings,
          productInventoryItemId: data.id,
          testId: data.id,
          sampleExtractorId: data.sample.doctor.id,
          doctorId:
            data.sample.serviceData.serviceInformation.nipt.doctorInformation
              .doctorId,
          SampleReceived: data.sample.kit.returned?.timestamp
            ? new Date(data.sample.kit.returned.timestamp).toISOString()
            : null,
          DoctorName: data.sample.serviceData.doctor.name,
          PregnancyType:
            data.sample.serviceData.serviceInformation.nipt
              .currentPregnancyInformation.pregnancyType,
        },
        Result: {
          ...result,
          Dated: result.Dated.set({ hours: 0 })
            .add(result.Dated.utcOffset(), 'minutes')
            .toISOString(),
          SampleReceived: result.SampleReceived.set({ hours: 0 })
            .add(result.SampleReceived.utcOffset(), 'minutes')
            .toISOString(),
        },
        ReportType: this.mapReportType(xlsResult?.ReportType || 'OFFICIAL'),
        ResultRisk: xlsResult?.ResultRisk || '',
        sourceFileName: xlsResult?.sourceFileName,
      };
    } catch (err) {
      console.error(err);
      return { error: `Treatment not found.` };
    }
  }

  mapReportType(reportType) {
    switch (reportType) {
      case 'OFFICIAL':
        return 'Official';
      case 'DELAY':
        return 'Delay GC';
      case 'REFUND':
        return 'Refund';
      case 'RESAMPLE':
        return 'Resample';
      default:
        return reportType;
    }
  }

  provisionReport(provisionReportBody: {
    FileName: string;
    Data: string;
    ReportType: string;
  }): Observable<any> {
    return this.httpClient.post(
      this.routes.provisionReport(),
      provisionReportBody
    );
  }

  generateCustomReport(generateReportBody: {
    Results: ReportResult[];
    Treatment: CustomReportGenerationData;
    ReportType: string;
    reportTypeReason?: string;
  }): Observable<GeneratedReport> {
    return this.getPartnerReportLanguage(generateReportBody.Treatment).pipe(
      switchMap((language) => {
        generateReportBody.Treatment.LanguageCode = language;

        return this.httpClient.post<GeneratedReport>(
          this.routes.generateCustomReport(),
          generateReportBody
        );
      })
    );
  }

  getPartnerReportLanguage(data: {
    LanguageCode: string;
    doctorId: string;
  }): Observable<string> {
    if (!Object.keys(environment.overrideReportLanguages).length) {
      return of(data.LanguageCode);
    }
    return this.store
      .dispatch(
        new LoadPartnersByIds(Object.keys(environment.overrideReportLanguages))
      )
      .pipe(
        switchMap(() => {
          const partnerSubpartners: { [key: string]: Observable<any> } = {};
          for (const key of Object.keys(environment.overrideReportLanguages)) {
            partnerSubpartners[key] = this.store
              .select(PartnersState.getSubPartners)
              .pipe(
                map((getSubpartners) => getSubpartners(key)),
                first()
              );
          }

          return forkJoin(partnerSubpartners);
        }),
        map((partnerSubpartners: { [key: string]: string[] }) => {
          let language = data.LanguageCode;
          for (const partnerId of Object.keys(partnerSubpartners)) {
            if (partnerSubpartners[partnerId].includes(data.doctorId)) {
              language = environment.overrideReportLanguages[partnerId];
            }
          }

          return language;
        })
      );
  }

  removeLastPage(removeLastPageBody: { FileName: string; Data: string }) {
    return this.httpClient.post<PageRemovalResponse>(
      this.routes.reportPageRemoval(),
      removeLastPageBody
    );
  }

  parseReportError(err: any): { message: string; title: string } | null {
    const error =
      (err?.error?.detail || err?.error || err?.message || err?.detail || err) +
      '';
    const last = error.split(' ').pop();

    if (error.includes('Cannot read properties of null')) {
      return {
        title: `Treatment doesn't exist`,
        message: `Couldn't generate document!`,
      };
    } else if (
      error.includes(`Result TireType has to match Treatment PregnancyType`)
    ) {
      return {
        title: `Result TireType has to match Treatment PregnancyType`,
        message: `Couldn't generate document!`,
      };
    } else if (
      error.includes(`Exception of type 'System.ArgumentNullException'`) ||
      error.includes('Value cannot be null')
    ) {
      return {
        title: `${last} is null!`,
        message: `Couldn't generate document!`,
      };
    } else if (error.includes('cannot consist of whitespace chars only')) {
      return {
        title: `${last} is required!`,
        message: `Couldn't generate document!`,
      };
    } else if (error.includes('invalid argument value')) {
      return {
        title: `${last} has invalid value!`,
        message: `Couldn't generate document!`,
      };
    } else if (error.includes('can not accept commands')) {
      return {
        title: `Treatment can not accept commands!`,
        message: `Couldn't assign document!`,
      };
    }
    return null;
  }
}
