import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DocumentSagaState, TemplateType } from '@core/core.types';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
} from '@nebular/theme';
import { DataTableComponent } from '@shared/components/data-table/data-table.component';
import {
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { of } from 'rxjs';

@Component({
  selector: 'app-document-approval-saga-confirm',
  standalone: true,
  imports: [
    DataTableComponent,
    AsyncPipe,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
  ],
  templateUrl: './document-approval-saga-confirm.component.html',
})
export class DocumentApprovalSagaConfirmComponent implements OnInit {
  @Input() state: DocumentSagaState;

  configuration: DataTableConfiguration;

  constructor(
    private dialogRef: NbDialogRef<DocumentApprovalSagaConfirmComponent>
  ) {}

  ngOnInit() {
    this.configuration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      columns: [
        {
          label: '',
          property: 'data',
        },
        {
          label: 'Type',
          property: 'type',
          customStyle: 'padding-right: 30px;',
        },
      ],
      rows$: of([
        ...this.state.errors.map((error) => ({ data: error, type: 'Error' })),
        ...this.state.warnings.map((warning) => ({
          data: warning,
          type: 'Warning',
        })),
      ]),
    };
  }

  dismiss(response) {
    this.dialogRef.close(response);
  }

  protected readonly TemplateType = TemplateType;
}
