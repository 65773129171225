import { Component, Input, OnInit } from '@angular/core';
import { ExtendedProductInventoryItem } from '@app/modules/service-data/service-data.types';
import { ProductInventoryItemUtilsService } from '@app/modules/service-data/services/product-inventory-item-utils.service';
import { GetProductInventoryItemListAction } from '@app/modules/service-data/store/product-intentory-item.actions';
import { ProductInventoryItemState } from '@app/modules/service-data/store/product-inventory-item.state';
import { ProductInventory } from '@core/core.types';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DataTableComponent } from '@shared/components/data-table/data-table.component';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { Observable, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-product-inventory-item-results',
  standalone: true,
  imports: [
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    TranslateModule,
    DataTableComponent,
  ],
  templateUrl: './product-inventory-item-results.component.html',
})
export class ProductInventoryItemResultsComponent implements OnInit {
  @Input() public piiIds: string[];

  protected dataTableConfiguration: DataTableConfiguration;
  protected isLoading = true;

  private rows$: Observable<ExtendedProductInventoryItem[]>;

  constructor(
    private dialogRef: NbDialogRef<ProductInventoryItemResultsComponent>,
    private piiUtilsService: ProductInventoryItemUtilsService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.rows$ = this.store
      .dispatch(
        new GetProductInventoryItemListAction({ ids: this.piiIds }, null, false)
      )
      .pipe(
        switchMap(() =>
          this.store
            .select(ProductInventoryItemState.getProductInventoryItemsByIds)
            .pipe(
              switchMap((getProductInventoryItemsByIds) =>
                this.piiUtilsService.getExtendedProductInventoryItemList(
                  getProductInventoryItemsByIds(this.piiIds),
                  { partner: true, product: true }
                )
              ),
              tap(() => (this.isLoading = false))
            )
        )
      );

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      actionsLocation: DataTableActionsLocation.RIGHT,
      tableHeadClasses: '',
      actionsClasses: 'text-end',
      columns: [
        {
          label: 'Barcode',
          property: 'kitId',
        },
        {
          label: 'Partner',
          property: 'partner.name',
        },
        {
          label: 'Product',
          property: 'product.name',
        },
      ],
      singleActions: [
        {
          name: 'Open product inventory details',
          icon: 'file-outline',
          type: DataTableActionType.BUTTON,
          action: this.openPiiDetails.bind(this),
        },
      ],
      rows$: this.rows$,
    };
  }

  private openPiiDetails(event: MouseEvent, pii: ProductInventory) {
    this.piiUtilsService.openDetails(pii.id);
    this.dismiss();
  }

  dismiss() {
    this.dialogRef.close();
  }
}
