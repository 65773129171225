import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AccessTokenInterceptor } from '@core/services/access-token-interceptor.service';
import { SubscriptionKeyInterceptor } from '@core/services/subscription-key-interceptor.service';
import { AccountManagerState } from '@core/store/account-managers/account-managers.state';
import { BundleConfigurationsState } from '@core/store/bundle-configuration/bundle-configuration.state';
import { CountriesState } from '@core/store/countries/countries.state';
import { CouponsState } from '@core/store/coupons/coupons.state';
import { DocumentsState } from '@core/store/documents/documents.state';
import { KitsState } from '@core/store/kits/kits.state';
import { LanguageState } from '@core/store/languages/languages.state';
import { NotificationProfileState } from '@core/store/notification-profiles/notification-profiles.state';
import { PantheonState } from '@core/store/pantheon/pantheon.state';
import { PartnerTypeState } from '@core/store/partner-types/partner-types.state';
import { PartnersState } from '@core/store/partners/partners.state';
import { InvoicesState } from '@core/store/payments/invoice-payments.state';
import { ProductConfigurationsState } from '@core/store/product-configuration/product-configuration.state';
import { ProductsListState } from '@core/store/products/products.state';
import { RunState } from '@core/store/runs/runs.state';
import { DocumentApprovalSagaState } from '@core/store/saga/document-approval-saga.state';
import { SigneeState } from '@core/store/signees/signees.state';
import { UserAccountsState } from '@core/store/user-accounts/user-accounts.state';
import { UserProfileState } from '@core/store/user-profile/user-profile.state';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbMomentDateModule } from '@nebular/moment';
import {
  DEFAULT_THEME,
  NbDatepickerModule,
  NbDialogModule,
  NbGlobalPhysicalPosition,
  NbListModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbTimepickerModule,
  NbToastrModule,
} from '@nebular/theme';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@root/environments/environment';
import * as Sentry from '@sentry/angular';
import { ErrorInterceptor } from '@shared/interceptors/error.interceptor';
import { routes } from './app.routes';
import { OrdersState } from './modules/orders/state/orders.state';
import { PatientFormsState } from './modules/patient-forms/state/patient-forms.state';
import { PdfRequestFormModule } from './modules/pdf-request-form/pdf-request-form.module';
import { ProductInventoryItemState } from './modules/service-data/store/product-inventory-item.state';
import { SampleState } from './modules/service-data/store/sample.state';
import { ServiceDataState } from './modules/service-data/store/service-data.state';

const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

const states = [
  UserProfileState,
  LanguageState,
  BundleConfigurationsState,
  CountriesState,
  PartnersState,
  ProductsListState,
  PartnerTypeState,
  ProductConfigurationsState,
  DocumentsState,
  RunState,
  AccountManagerState,
  PantheonState,
  KitsState,
  NotificationProfileState,
  UserAccountsState,
  SigneeState,
  InvoicesState,
  CouponsState,
  ServiceDataState,
  DocumentApprovalSagaState,
  SampleState,
  OrdersState,
  ProductInventoryItemState,
  PatientFormsState,
];

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      NbEvaIconsModule,
      NbMenuModule.forRoot(),
      NbThemeModule.forRoot(
        {
          name: 'default',
        },
        [DEFAULT_THEME]
      ),
      NbSidebarModule.forRoot(),
      NbToastrModule.forRoot({
        position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      }),
      NbDatepickerModule.forRoot(),
      NbTimepickerModule.forRoot(),
      NbMomentDateModule,
      NbDialogModule.forRoot(),
      NbListModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      PdfRequestFormModule,
      CommonModule,
      NgxsModule.forRoot(states, {
        developmentMode: !environment.production,
      })
    ),
    provideRouter(routes),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubscriptionKeyInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
};
