import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { NbInputModule, NbRadioModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationErrorComponent } from '@shared/components/validation-error/validation-error.component';
import { controlContainerProvider } from '@shared/helpers';

@Component({
  selector: 'app-service-data-form-other-information',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NbRadioModule,
    NbInputModule,
    AsyncPipe,
    TranslateModule,
    ValidationErrorComponent,
  ],
  templateUrl: './service-data-form-other-information.component.html',
  viewProviders: [controlContainerProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDataFormOtherInformationComponent {
  protected get form() {
    return this.controlContainer.control as FormGroup;
  }

  constructor(private controlContainer: ControlContainer) {}
}
