import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PAGING_HEADERS } from '@app/app.constants';
import { RunsUtilsService } from '@app/modules/runs/runs-utils.service';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { KitPositionModel, Run, SortDirection } from '../core.types';

@Injectable({
  providedIn: 'root',
})
export class RunsService {
  private readonly apiUrl = `${environment.apiUrl}/nipts/runs`;

  constructor(
    protected http: HttpClient,
    private runsUtils: RunsUtilsService
  ) {}

  getRun(runId: string) {
    return this.http.get(`${this.apiUrl}/${runId}`);
  }

  generateRun(run: Run) {
    return this.http.post<{ id: string }>(this.apiUrl, run);
  }

  updateRun(run: Run) {
    return this.http.put(`${this.apiUrl}/${run.id}`, run);
  }

  deleteRun(runId: string) {
    return this.http.delete(`${this.apiUrl}/${runId}`);
  }

  getRuns(
    queryParams: RunFilters,
    headers: RunHeaders
  ): Observable<{
    items: Run[];
    continuationToken: string;
    totalCount: number;
  }> {
    return this.http
      .get<Run[]>(this.apiUrl, {
        params: this.runsUtils.buildHttpParams(queryParams),
        headers: this.runsUtils.buildHttpHeaders(headers),
        observe: 'response',
      })
      .pipe(
        map((response) => ({
          items: response.body,
          continuationToken: response.headers.get(
            PAGING_HEADERS.continuationToken
          ),
          totalCount: parseInt(
            response.headers.get(PAGING_HEADERS.totalCount),
            10
          ),
        }))
      );
  }

  unassignKitFromRun(runId: string, kitId: string) {
    return this.http.patch(`${this.apiUrl}/${runId}/kit/${kitId}/unassign`, {
      kitId,
    });
  }

  assignKitToRun(runId: string, kitPositionItem: KitPositionModel) {
    return this.http.patch(
      `${this.apiUrl}/${runId}/kit/${kitPositionItem.kitId}`,
      kitPositionItem
    );
  }

  addKitToRun(runId: string, kitId: string) {
    return this.http.patch(`${this.apiUrl}/${runId}/kit/${kitId}`, {
      runId,
      kitId,
    });
  }

  removeKitFromRun(runId: string, kitId: string) {
    return this.http.patch(`${this.apiUrl}/${runId}/kit/${kitId}/remove`, {
      kitId,
    });
  }

  markRunAsStarted(runId: string, started: Date) {
    return this.http.patch(`${this.apiUrl}/${runId}/status/start`, { started });
  }

  markRunAsFinished(runId: string, finished: Date) {
    return this.http.patch(`${this.apiUrl}/${runId}/status/finish`, {
      finished,
    });
  }
}
export interface RunFilters {
  ids?: string[];
  excludeIds?: string[];
  nameToken?: string;
  sequencer?: string;
  site?: string;
  signeeIds?: string[];
}

export interface RunHeaders {
  continuationToken?: string;
  select?: string[];
  orderBy?: string;
  orderDirection?: SortDirection;
  pagingTop?: number;
}
