import { Injectable } from '@angular/core';
import { ServiceData } from '@app/modules/service-data/service-data.types';
import {
  ServiceDataFilters,
  ServiceDataService,
} from '@app/modules/service-data/services/service-data.service';
import {
  CreateServiceDataAction,
  DeleteServiceDataAction,
  GetServiceDataAction,
  GetServiceDataListAction,
  SERVICE_DATA_NAME,
  SetDoctorInvoiceAction,
  SetGeneplanetInvoiceAction,
  SetLaboratoryInvoiceAction,
  SetPaymentTypeAction,
  SetServiceDataAction,
  TerminateServiceDataAction,
  UpdateServiceDataAction,
} from '@app/modules/service-data/store/service-data.actions';
import {
  Bundle,
  Pagination,
  Product,
  SortDirection,
  StatePageSettings,
} from '@core/core.types';
import { ProductsListState } from '@core/store/products/products.state';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { isEqual } from 'lodash';
import { EMPTY, forkJoin, of } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { Util } from '@core/utils/core.util';
import { GetSamplesListAction } from '@app/modules/service-data/store/sample.actions';

export interface ServiceDataStateModel {
  serviceData: { [id: string]: ServiceData };
  serviceDataBySample: { [id: string]: string };
  pages?: {
    [key: string]: {
      items: string[];
      continuationToken: string;
    };
  };
  currentPageSettings: StatePageSettings<ServiceDataFilters>;
  totalCount?: number;
}

const SERVICE_DATA_DEFAULT_STATE: ServiceDataStateModel = {
  serviceData: {},
  serviceDataBySample: {},
  pages: {},
  currentPageSettings: {
    pageIndex: 0,
    sortSettings: {
      orderBy: 'Generated',
      sortDirection: SortDirection.desc,
      pageSize: 30,
    },
  },
};

@State<ServiceDataStateModel>({
  name: SERVICE_DATA_NAME,
  defaults: { ...SERVICE_DATA_DEFAULT_STATE },
})
@Injectable()
export class ServiceDataState {
  constructor(
    private store: Store,
    private serviceDataService: ServiceDataService
  ) {}

  @Selector()
  static getServiceDataById({ serviceData }: ServiceDataStateModel) {
    return (serviceDataId: string) => serviceData[serviceDataId];
  }

  @Selector()
  static getServiceDatasByIds({ serviceData }: ServiceDataStateModel) {
    return (serviceDataIds: string[]) =>
      serviceDataIds.map((id) => serviceData[id]);
  }

  @Selector([ServiceDataState.getCurrentPageIds])
  static getCurrentPage(state: ServiceDataStateModel, ids: string[]) {
    return ids
      .map((id) => state.serviceData[id])
      .filter((serviceData) => !!serviceData);
  }

  @Selector()
  static getCurrentPageIds(state: ServiceDataStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;

    if (
      Object.keys(pages).length === 0 ||
      pageIndex > Object.keys(pages).length ||
      pageIndex < 0
    ) {
      return [];
    }
    return state.pages[pageIndex].items.slice();
  }

  @Selector()
  static getPagination({
    currentPageSettings,
    totalCount,
    pages,
  }: ServiceDataStateModel): Pagination<ServiceDataFilters> {
    return {
      currentPageSettings: {
        ...currentPageSettings,
      },
      totalCount,
      pagesSize: Object.keys(pages).length,
      collectionSize: currentPageSettings.sortSettings.pageSize,
    };
  }

  @Selector([
    ProductsListState.getBundlesList,
    ProductsListState.getProductsList,
  ])
  static getAvailableProducts(
    { serviceData }: ServiceDataStateModel,
    bundlesList: Bundle[],
    productsList: Product[]
  ) {
    return (serviceDataId: string) => {
      const products: Product[] = [];
      const sd = serviceData[serviceDataId];
      for (const bId of sd.bundleIds) {
        const bundle = bundlesList.find((b) => b.id === bId);
        for (const pId of bundle.productIds) {
          products.push(productsList.find((p) => p.id === pId));
        }
      }
      return products;
    };
  }

  @Action(GetServiceDataListAction)
  getServiceDataListAction(
    { getState, patchState, dispatch }: StateContext<ServiceDataStateModel>,
    {
      filters,
      headers,
      pageToLoad = 1,
      reload = false,
    }: GetServiceDataListAction
  ) {
    // Appends objects are added, in case of separate state @Action calls, as they would otherwise override each other!
    const serviceDataAppends = {};

    const state = getState();
    const serviceDataList = { ...state.serviceData };
    let pages = { ...state.pages };
    const currentPageSettings = {
      ...state.currentPageSettings,
      sortSettings: { ...state.currentPageSettings.sortSettings },
      filterSettings: { ...state.currentPageSettings.filterSettings },
    };
    let totalCount = state.totalCount;

    let actualPageToLoad = pageToLoad ? pageToLoad - 1 : undefined;

    // Update pagination in case of listing pages.
    if (actualPageToLoad !== undefined) {
      currentPageSettings.pageIndex = actualPageToLoad;

      // Check that filters are same as they were for previous page.
      if (
        !isEqual(currentPageSettings.filterSettings, filters) ||
        currentPageSettings.pageSize !== headers.pagingTop ||
        currentPageSettings.sortSettings.sortDirection !==
          headers.orderDirection ||
        currentPageSettings.sortSettings.orderBy !== headers.orderBy
      ) {
        // If filters are different as they were -> update filters, reset pages object and go back to page 1.
        currentPageSettings.pageSize = headers.pagingTop;
        currentPageSettings.sortSettings.sortDirection = headers.orderDirection;
        currentPageSettings.sortSettings.orderBy = headers.orderBy;
        currentPageSettings.sortSettings.pageSize = headers.pagingTop;
        currentPageSettings.filterSettings = filters;

        actualPageToLoad = 0;
        reload = true;
        pages = {};
        currentPageSettings.pageIndex = actualPageToLoad;
      }
    }

    // If reload is not required, check if we already hold service data with selected properties.
    if (filters.ids && !reload) {
      filters.ids = filters.ids.filter((item) => !serviceDataList[item]);

      // If we filter out all IDs, return early!
      if (!filters.ids.length) {
        return EMPTY;
      }
    }

    if (filters.ids?.length > 100) {
      return forkJoin(
        Util.chunkArray(filters.ids, 100).map((ids) =>
          dispatch(new GetServiceDataListAction({ ids }, headers, null, reload))
        )
      );
    }

    // Get selected page continuation token, if not first page
    if (actualPageToLoad > 0) {
      headers.continuationToken =
        pages[actualPageToLoad - 1]?.continuationToken;
    }

    // If not listing page, page doesnt exist or reload is demanded -> call API
    if (actualPageToLoad === undefined || !pages[actualPageToLoad] || reload) {
      return this.serviceDataService.getServiceDataList(filters, headers).pipe(
        tap((response) => {
          response.items.forEach((serviceData) => {
            serviceDataAppends[serviceData.id] = serviceData;
          });

          // Add/update pages
          if (actualPageToLoad !== undefined) {
            totalCount = response.totalCount;
            pages[actualPageToLoad] = {
              items: response.items.map((serviceData) => serviceData.id),
              continuationToken: response.continuationToken,
            };
          }
        }),
        tap(() => {
          // get latest state again as state might be patched in some other @Action
          const oldState = getState();
          patchState({
            serviceData: { ...oldState.serviceData, ...serviceDataAppends },
            pages,
            totalCount,
            currentPageSettings,
          });
        }),
        catchError((err) => {
          patchState({});
          return of(null);
        })
      );
    } else {
      // If page exists -> update state so pagination is updated.
      patchState({ currentPageSettings });
      return EMPTY;
    }
  }

  @Action(SetServiceDataAction)
  SetServiceDataAction(
    { getState, patchState }: StateContext<ServiceDataStateModel>,
    { list, prependOnPage }: SetServiceDataAction
  ) {
    const pages = { ...getState().pages };

    return patchState({
      ...getState(),
      serviceData: {
        ...getState().serviceData,
        ...list.reduce<{ [id: string]: ServiceData }>((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {}),
      },
      pages: {
        ...pages,
        ...(pages[0]?.items && prependOnPage
          ? {
              [0]: {
                continuationToken: pages[0].continuationToken,
                items: [
                  ...list.map((serviceData) => serviceData.id),
                  ...pages[0].items,
                ],
              },
            }
          : {}),
      },
    });
  }

  @Action(GetServiceDataAction)
  GetServiceDataAction(
    { dispatch, getState }: StateContext<ServiceDataStateModel>,
    { id, prependToPage, reload }: GetServiceDataAction
  ) {
    if (!reload && getState().serviceData[id]) {
      return EMPTY;
    }
    return this.serviceDataService
      .getServiceData(id)
      .pipe(
        switchMap((serviceData) =>
          dispatch(new SetServiceDataAction([serviceData], prependToPage))
        )
      );
  }

  @Action(CreateServiceDataAction)
  CreateServiceDataAction(
    { dispatch }: StateContext<ServiceDataStateModel>,
    { serviceData }: CreateServiceDataAction
  ) {
    return this.serviceDataService.createServiceData(serviceData).pipe(
      delay(300),
      switchMap(({ id }) => dispatch(new GetServiceDataAction(id, true)))
    );
  }

  @Action(UpdateServiceDataAction)
  UpdateServiceDataAction(
    { dispatch }: StateContext<ServiceDataStateModel>,
    { serviceData }: UpdateServiceDataAction
  ) {
    return this.serviceDataService.updateServiceData(serviceData).pipe(
      delay(300),
      switchMap(() =>
        dispatch(new GetServiceDataAction(serviceData.id, false, true))
      )
    );
  }

  @Action(DeleteServiceDataAction)
  DeleteServiceDataAction(
    { patchState, getState }: StateContext<ServiceDataStateModel>,
    { id }: DeleteServiceDataAction
  ) {
    return this.serviceDataService.deleteServiceData(id).subscribe(() => {
      const serviceDataList = { ...getState().serviceData };
      delete serviceDataList[id];
      patchState({ serviceData: serviceDataList });
    });
  }

  @Action(TerminateServiceDataAction)
  TerminateServiceDataAction(
    { dispatch }: StateContext<ServiceDataStateModel>,
    { id, reason }: TerminateServiceDataAction
  ) {
    return this.serviceDataService.terminateServiceData(id, reason).pipe(
      delay(500),
      switchMap(() => dispatch(new GetServiceDataAction(id, false, true)))
    );
  }

  @Action(SetDoctorInvoiceAction)
  SetDoctorInvoiceAction(
    { dispatch }: StateContext<ServiceDataStateModel>,
    { id, invoiceNumber }: SetDoctorInvoiceAction
  ) {
    return this.serviceDataService
      .setDoctorInvoiceNumber(id, invoiceNumber)
      .pipe(
        delay(500),
        switchMap(() => dispatch(new GetServiceDataAction(id, false, true)))
      );
  }

  @Action(SetGeneplanetInvoiceAction)
  SetGeneplanetInvoiceAction(
    { dispatch }: StateContext<ServiceDataStateModel>,
    { id, invoiceNumber }: SetGeneplanetInvoiceAction
  ) {
    return this.serviceDataService
      .setGeneplanetInvoiceNumber(id, invoiceNumber)
      .pipe(
        delay(500),
        switchMap(() => dispatch(new GetServiceDataAction(id, false, true)))
      );
  }

  @Action(SetLaboratoryInvoiceAction)
  SetLaboratoryInvoiceAction(
    { dispatch }: StateContext<ServiceDataStateModel>,
    { id, invoiceNumber }: SetLaboratoryInvoiceAction
  ) {
    return this.serviceDataService
      .setLaboratoryInvoiceNumber(id, invoiceNumber)
      .pipe(
        delay(500),
        switchMap(() => dispatch(new GetServiceDataAction(id, false, true)))
      );
  }

  @Action(SetPaymentTypeAction)
  SetPaymentTypeAction(
    { dispatch }: StateContext<ServiceDataStateModel>,
    { id, paymentType }: SetPaymentTypeAction
  ) {
    return this.serviceDataService.setPaymentType(id, paymentType).pipe(
      delay(500),
      switchMap(() => dispatch(new GetServiceDataAction(id, false, true)))
    );
  }
}
