import { Validators } from '@angular/forms';
import {
  CancerHistoryStatus,
  InVitroTypes,
  PregnancyType,
  ValidationPair,
} from '@app/modules/service-data/service-data.types';
import { gestationAgeValidValidator } from '@app/modules/service-data/validators/gestation-age-valid.validator';
import { Util } from '@core/utils/core.util';
import { crossFieldLowerHigherValidator } from '@shared/validators/cross-field-lower-higher-validator';
import { crossFieldValidator } from '@shared/validators/cross-field-validator';
import { diseasesValidator } from '@shared/validators/diseases-validator';
import { errorValueValidator } from '@shared/validators/error-value-validator';

export const serviceDataValidationGroups: {
  [groupKey: string]: ValidationPair;
} = {
  niptGeneric: {
    children: {
      doctorInformation: {
        children: {
          doctorId: {
            validators: [Validators.required],
          },
        },
      },
      patientHistory: {
        children: {
          numberOfPregnancies: {
            validators: [Validators.required, Validators.min(1)],
          },
          numberOfBirths: {
            validators: [Validators.required, Validators.min(0)],
          },
          cancerHistory: {
            children: {
              hasHistory: {
                validators: [Validators.required],
              },
              status: {
                validators: [
                  errorValueValidator(CancerHistoryStatus.CurrentlyAffected),
                ],
              },
            },
          },
          albuminTherapy: {
            children: {
              hasReceived: {
                validators: [Validators.required],
              },
              isRelevant: {
                validators: [errorValueValidator(true)],
              },
            },
          },
          cellularImmunotherapy: {
            children: {
              hasReceived: {
                validators: [Validators.required],
              },
              isRelevant: {
                validators: [errorValueValidator(true)],
              },
            },
          },
          stemCellTherapy: {
            children: {
              hasReceived: {
                validators: [Validators.required, errorValueValidator(true)],
              },
            },
          },
          transplantSurgery: {
            children: {
              hasReceived: {
                validators: [Validators.required, errorValueValidator(true)],
              },
            },
          },
          bloodTransfusion: {
            children: {
              hasReceived: {
                validators: [Validators.required],
              },
              isRelevant: {
                validators: [errorValueValidator(true)],
              },
            },
          },
          familyDiseaseHistory: {
            children: {
              hasHistory: {
                validators: [Validators.required],
              },
            },
            validators: [diseasesValidator(true)],
          },
          pregnancyDiseaseHistory: {
            children: {
              hasHistory: {
                validators: [Validators.required],
              },
            },
            validators: [diseasesValidator()],
          },
          carrierOfGeneticDiseases: {
            children: {
              hasHistory: {
                validators: [Validators.required],
              },
            },
            validators: [diseasesValidator()],
          },
        },
        validators: [
          crossFieldLowerHigherValidator(
            'numberOfBirths',
            'numberOfPregnancies'
          ),
        ],
      },
      currentPregnancyInformation: {
        children: {
          expectedConfinementDate: {
            validators: [Validators.required],
          },
          gestationAge: {
            children: {
              week: {
                validators: [Validators.required, Validators.min(10)],
              },
              day: {
                validators: [
                  Validators.required,
                  Validators.min(0),
                  Validators.max(6),
                ],
              },
            },
          },
          isGestationAgeAutomaticallyCalculated: {
            validators: [Validators.required],
          },
          pregnancyType: {
            validators: [Validators.required],
          },
          inVitro: {
            children: {
              isInVitroFertilized: {
                validators: [Validators.required],
              },
            },
          },
          priorScreeningTest: {
            children: {
              priorScreeningTestType: {
                validators: [Validators.required],
              },
              firstTRisks: {
                children: {
                  t21Risk: {
                    validators: [
                      Validators.pattern(Util.GetServiceDataRiskPattern()),
                    ],
                  },
                  t18Risk: {
                    validators: [
                      Validators.pattern(Util.GetServiceDataRiskPattern()),
                    ],
                  },
                  t13Risk: {
                    validators: [
                      Validators.pattern(Util.GetServiceDataRiskPattern()),
                    ],
                  },
                },
              },
              secondTRisks: {
                children: {
                  t21Risk: {
                    validators: [
                      Validators.pattern(Util.GetServiceDataRiskPattern()),
                    ],
                  },
                  t18Risk: {
                    validators: [
                      Validators.pattern(Util.GetServiceDataRiskPattern()),
                    ],
                  },
                  t13Risk: {
                    validators: [
                      Validators.pattern(Util.GetServiceDataRiskPattern()),
                    ],
                  },
                },
              },
            },
          },
          vanishingTwinSyndrome: {
            children: {
              isDetected: {
                validators: [Validators.required],
              },
              detectedGestationAge: {
                children: {
                  week: {
                    validators: [Validators.min(1), Validators.max(7)],
                  },
                  day: {
                    validators: [Validators.min(0), Validators.max(6)],
                  },
                },
              },
            },
          },
          heparinTherapy: {
            children: {
              hasReceived: {
                validators: [Validators.required],
              },
              isRelevant: {
                validators: [errorValueValidator(true)],
              },
            },
          },
        },
      },
    },
    validators: [
      gestationAgeValidValidator(),
      crossFieldValidator(
        'patientHistory.cancerHistory.status',
        'patientHistory.cancerHistory.hasHistory',
        true
      ),
      crossFieldValidator(
        'patientHistory.albuminTherapy.isRelevant',
        'patientHistory.albuminTherapy.hasReceived',
        true
      ),
      crossFieldValidator(
        'patientHistory.cellularImmunotherapy.isRelevant',
        'patientHistory.cellularImmunotherapy.hasReceived',
        true
      ),
      crossFieldValidator(
        'patientHistory.bloodTransfusion.isRelevant',
        'patientHistory.bloodTransfusion.hasReceived',
        true
      ),
      crossFieldValidator(
        'currentPregnancyInformation.chorionicityType',
        'currentPregnancyInformation.pregnancyType',
        PregnancyType.twins
      ),
      crossFieldValidator(
        'currentPregnancyInformation.inVitro.isSurrogateMother',
        'currentPregnancyInformation.inVitro.isInVitroFertilized',
        true
      ),
      crossFieldValidator(
        'currentPregnancyInformation.inVitro.vitroType',
        'currentPregnancyInformation.inVitro.isInVitroFertilized',
        true
      ),
      crossFieldValidator(
        'currentPregnancyInformation.vanishingTwinSyndrome.detectedGestationAge.week',
        'currentPregnancyInformation.vanishingTwinSyndrome.isDetected',
        true
      ),
      crossFieldValidator(
        'currentPregnancyInformation.vanishingTwinSyndrome.detectedGestationAge.day',
        'currentPregnancyInformation.vanishingTwinSyndrome.isDetected',
        true
      ),
      crossFieldValidator(
        'currentPregnancyInformation.heparinTherapy.isRelevant',
        'currentPregnancyInformation.heparinTherapy.hasReceived',
        true
      ),
    ],
  },

  nipt: {
    children: {
      currentPregnancyInformation: {
        children: {
          gestationAge: {
            children: {
              week: {
                validators: [Validators.max(40)],
              },
            },
          },
        },
      },
      provideGenderInformation: {
        validators: [Validators.required],
      },
    },
  },
  mono: {
    children: {
      currentPregnancyInformation: {
        children: {
          gestationAge: {
            children: {
              week: {
                validators: [Validators.max(24)],
              },
            },
          },
          pregnancyType: {
            validators: [errorValueValidator(PregnancyType.twins)],
          },
          inVitro: {
            children: {
              vitroType: {
                validators: [errorValueValidator(InVitroTypes.DonorEgg)],
              },
              isSurrogateMother: {
                validators: [errorValueValidator(true)],
              },
            },
          },
          vanishingTwinSyndrome: {
            children: {
              isDetected: {
                validators: [errorValueValidator(true)],
              },
            },
          },
        },
      },
    },
  },
  carrier: {
    children: {
      currentPregnancyInformation: {
        children: {
          inVitro: {
            children: {
              vitroType: {
                validators: [errorValueValidator(InVitroTypes.DonorEgg)],
              },
            },
          },
        },
      },
      patientHistory: {
        children: {
          cysticFibrosisHistory: {
            children: {
              hasHistory: {
                validators: [Validators.required],
              },
            },
            validators: [diseasesValidator(true)],
          },
        },
      },
    },
  },
  rh: {
    children: {
      patientHistory: {
        children: {
          rhdStatus: {
            children: {
              status: {
                validators: [Validators.required, errorValueValidator(false)],
              },
            },
          },
        },
      },
    },
  },
};
