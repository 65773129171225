@if (step$ | async; as step) {
  <nb-card [nbSpinner]="isLoading$ | async">
    <nb-card-header class="position-relative">
      <p class="mb-0">
        Document <b>{{ document.fileName }}</b> approval
      </p>
      <nb-icon (click)="dismiss()" icon="close"></nb-icon>
    </nb-card-header>

    <nb-card-body>
      @if (step === DialogStep.prepareEmail) {
        @if (notificationProfile || TemplateType.Canceled) {
          <div class="mb-3">
            Send
            <b>{{ notificationProfile?.name ?? TemplateType.Canceled }}</b>
            e-mail notifications
          </div>

          @if (
            templateType === TemplateType.NotQualified ||
            templateType === TemplateType.Canceled
          ) {
            <label class="label-primary ps-1">{{
              'pii.details.approve.createCustomEmailNotQualifiedMessage'
                | translate
            }}</label>
          } @else {
            <form
              class="row form-group"
              [formGroup]="sendEmailForm.form"
              novalidate
            >
              @if (templateType === TemplateType.HighRisk) {
                <div class="label form-control-label col-12 mb-2">
                  <label class="label-primary ps-1" for="reason"
                    >High Risk Reason</label
                  >
                  <input
                    fullWidth
                    nbInput
                    formControlName="reason"
                    name="reason"
                    id="reason"
                    type="text"
                    (keydown)="error$.next(null)"
                    placeholder="Enter High Risk Reason"
                  />
                </div>
              }

              <div class="label form-control-label col-12 mb-2">
                <label class="label-primary ps-1" for="subject">{{
                  'pii.details.approve.mailSubject' | translate
                }}</label>
                <input
                  fullWidth
                  nbInput
                  formControlName="subject"
                  name="subject"
                  id="subject"
                  type="text"
                  placeholder="Enter E-mail Subject"
                />
              </div>
              <div class="label form-control-label col-12">
                <label class="label-primary ps-1" for="body">Mail Body</label>
                <textarea
                  rows="10"
                  fullWidth
                  nbInput
                  formControlName="body"
                  name="body"
                  id="body"
                  type="text"
                  placeholder="Enter E-mail Body"
                ></textarea>
              </div>
            </form>
            <div class="row">
              <div class="col-12">
                <app-data-table
                  [configuration]="toDataTableConfiguration"
                ></app-data-table>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <app-data-table
                  [configuration]="ccDataTableConfiguration"
                ></app-data-table>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <app-data-table
                  [configuration]="bccDataTableConfiguration"
                ></app-data-table>
              </div>
            </div>
          }
        } @else {
          <div class="mb-0">
            Please add notification profiles to send e-mail notifications.
          </div>
        }
      } @else if (step === DialogStep.previewEmail) {
        <div class="mb-3">
          Confirm sending <b>{{ notificationProfile.name }}</b> e-mail
          notifications
        </div>
        <div class="row form-group">
          @if (emailConfig.to.length) {
            <div class="col-12 mb-2">
              <label class="label form-control-label label-primary">To:</label>
              <div>{{ emailConfig.to.join(', ') }}</div>
            </div>
          }

          @if (emailConfig.cc.length) {
            <div class="col-12 mb-2">
              <label class="label form-control-label label-primary">Cc:</label>
              <div>{{ emailConfig.cc.join(', ') }}</div>
            </div>
          }

          @if (emailConfig.bcc.length) {
            <div class="col-12 mb-2">
              <label class="label form-control-label label-primary">Bcc:</label>
              <div>{{ emailConfig.bcc.join(', ') }}</div>
            </div>
          }

          @if (emailConfig.subject) {
            <div class="col-12 mb-2">
              <label class="label form-control-label label-primary"
                >Subject:</label
              >
              <div>{{ emailConfig.subject }}</div>
            </div>
          }
          @if (emailConfig.body) {
            <div class="col-12 mb-2">
              <label class="label form-control-label label-primary"
                >Body:</label
              >
              <div style="white-space: pre-line">{{ emailConfig.body }}</div>
            </div>
          }
        </div>
      } @else if (step === DialogStep.approveDocument) {
        <div>
          Would you like to approve document <b>{{ document.fileName }}</b> for
          product inventory item with barcode <b>{{ kitId }}</b
          >?
        </div>
      }

      @if (error$ | async; as error) {
        <div class="caption status-danger">
          {{ error }}
        </div>
      }
    </nb-card-body>

    <nb-card-footer class="d-flex flex-row-reverse">
      @if (step === DialogStep.previewEmail) {
        <button
          nbButton
          status="info"
          (click)="sendEmails(true)"
          [disabled]="
            !notificationProfile ||
            (isLoading$ | async) ||
            templateType === TemplateType.NotQualified ||
            templateType === TemplateType.Canceled
          "
        >
          {{ 'pii.details.approve.sendEmail' | translate }}
        </button>
      }

      @if (step === DialogStep.prepareEmail) {
        <button
          nbButton
          status="info"
          (click)="previewEmail()"
          [disabled]="
            !notificationProfile ||
            (isLoading$ | async) ||
            templateType === TemplateType.NotQualified ||
            templateType === TemplateType.Canceled
          "
        >
          {{ 'pii.details.approve.previewEmail' | translate }}
        </button>
      }

      @if (step === DialogStep.approveDocument) {
        <button
          nbButton
          status="info"
          (click)="approveDocument()"
          [disabled]="isLoading$ | async"
        >
          Approve
        </button>
      }

      <button
        nbButton
        (click)="cancel(step)"
        class="me-2"
        [disabled]="isLoading$ | async"
      >
        @if (step === DialogStep.previewEmail) {
          <span>Edit</span>
        } @else {
          <span>Cancel</span>
        }
      </button>
    </nb-card-footer>
  </nb-card>
}

<ng-template #loading>
  <div class="pt-1 loading"></div>
</ng-template>
