import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { INVOICE_KEYS, NIFTY_APPLICATIONID_KEY } from '@app/app.constants';
import { PartnersUtilsService } from '@app/modules/partners/partners-utils.service';
import { PatientForm } from '@app/modules/patient-forms/patient-forms.types';
import { CompletePatientFormAction } from '@app/modules/patient-forms/state/patient-forms.actions';
import { PatientFormsState } from '@app/modules/patient-forms/state/patient-forms.state';
import { ServiceDataFormCurrentPregnancyInformationComponent } from '@app/modules/service-data/components/service-data/service-data-form/service-data-form-current-pregnancy-information/service-data-form-current-pregnancy-information.component';
import { ServiceDataFormPatientInformationComponent } from '@app/modules/service-data/components/service-data/service-data-form/service-data-form-patient-information/service-data-form-patient-information.component';
import { ServiceDataFormPaymentInformationComponent } from '@app/modules/service-data/components/service-data/service-data-form/service-data-form-payment-information/service-data-form-payment-information.component';
import { ServiceDataPatientHistoryInformationComponent } from '@app/modules/service-data/components/service-data/service-data-form/service-data-patient-history-information/service-data-patient-history-information.component';
import {
  DiseaseInformation,
  FamilyDiseaseInformation,
  InVitroTypes,
  ServiceData,
} from '@app/modules/service-data/service-data.types';
import {
  handleValidators,
  ValidatorsHandlerType,
} from '@app/modules/service-data/service-data.utils';
import {
  CreateServiceDataAction,
  GetServiceDataAction,
  UpdateServiceDataAction,
} from '@app/modules/service-data/store/service-data.actions';
import { ServiceDataState } from '@app/modules/service-data/store/service-data.state';
import {
  DataInputType,
  ModalMode,
  Partner,
  PaymentType,
  Product,
} from '@core/core.types';
import { AuthenticationService } from '@core/services/authentication.service';
import { ProductUtilsService } from '@core/services/product-utils.service';
import { PartnersState } from '@core/store/partners/partners.state';
import { AddInvoiceAction } from '@core/store/payments/invoice-payments.actions';
import { ProductsListState } from '@core/store/products/products.state';
import { Util } from '@core/utils/core.util';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
  NbSelectModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { environment } from '@root/environments/environment';
import { RequirePermissionDirective } from '@shared/directives/require-permission.directive';
import { crossFieldValidator } from '@shared/validators/cross-field-validator';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import {
  delay,
  filter,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ServiceDataFormBundleInformationComponent } from './service-data-form-bundle-information/service-data-form-bundle-information.component';
import { ServiceDataFormOtherInformationComponent } from './service-data-form-other-information/service-data-form-other-information.component';
import { ServiceDataFormServiceInformationComponent } from './service-data-form-service-information/service-data-form-service-information.component';
import { prefillData } from './service-data-prefill';

enum RequestFormsVitroType {
  DonorEgg = 'Donor Egg',
  OwnEgg = 'Own Egg',
}

@Component({
  templateUrl: './service-data-form.component.html',
  styleUrl: './service-data-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NbCardModule,
    ServiceDataFormPaymentInformationComponent,
    ServiceDataPatientHistoryInformationComponent,
    ServiceDataFormCurrentPregnancyInformationComponent,
    ServiceDataFormOtherInformationComponent,
    ServiceDataFormBundleInformationComponent,
    ServiceDataFormServiceInformationComponent,
    ServiceDataFormPatientInformationComponent,
    NbSelectModule,
    NbButtonModule,
    AsyncPipe,
    NbIconModule,
    NbButtonModule,
    RequirePermissionDirective,
  ],
})
export class ServiceDataFormComponent implements OnInit {
  @Input() public id: string;
  @Input() public patientFormId: string;

  selectedDataInputTypes: DataInputType[] = [];
  loading = false;

  protected paymentTypes$: Observable<string[]>;

  protected isStaging = environment.stagingFavIcon;

  protected prefillDisabled = signal(true);

  protected form: FormGroup = this.fb.group(
    {
      id: [null],
      partnerId: this.fb.nonNullable.control('', [Validators.required]),
      bundleIds: [[], [Validators.required]],
      onlineReports: [null],
      notes: [''],
      paymentInformation: this.fb.group({
        paymentType: [{ value: null, disabled: true }, Validators.required],
        invoiceId: [],
        paid: [],
        geneplanetInvoiceNumber: [''],
        doctorInvoiceNumber: [''],
        laboratoryInvoiceNumber: [''],
        notes: [''],
      }),
      patientInformation: this.fb.group({
        firstName: [
          '',
          [Validators.required, Validators.pattern(/^(?!.*[\\/:*?"<>|]).*$/)],
        ],
        lastName: [
          '',
          [Validators.required, Validators.pattern(/^(?!.*[\\/:*?"<>|]).*$/)],
        ],
        documentNumber: [''],
        dateOfBirth: [null, Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', Validators.required],
        height: [null, [Validators.required, Validators.min(0)]],
        weight: [null, [Validators.required, Validators.min(0)]],
      }),
      serviceInformation: this.fb.group({
        nipt: this.fb.group({
          doctorInformation: this.fb.group({
            doctorId: [{ value: null, disabled: true }, Validators.required],
            doctorConsent: [null],
          }),
          patientHistory: this.fb.group({
            numberOfPregnancies: [null],
            numberOfBirths: [null],
            cancerHistory: this.fb.group({
              hasHistory: [null],
              status: [null],
            }),
            albuminTherapy: this.fb.group({
              hasReceived: [null],
              isRelevant: [null],
            }),
            cellularImmunotherapy: this.fb.group({
              hasReceived: [null],
              isRelevant: [null],
            }),
            stemCellTherapy: this.fb.group({
              hasReceived: [null],
            }),
            transplantSurgery: this.fb.group({
              hasReceived: [null],
            }),
            rhdStatus: this.fb.group({
              status: [null],
            }),
            bloodTransfusion: this.fb.group({
              hasReceived: [null],
              isRelevant: [null],
            }),
            familyDiseaseHistory: this.fb.group({
              hasHistory: [null],
              diseases: this.fb.control<FamilyDiseaseInformation[]>([]),
            }),
            cysticFibrosisHistory: this.fb.group({
              hasHistory: [null],
              diseases: this.fb.control<FamilyDiseaseInformation[]>([]),
            }),
            pregnancyDiseaseHistory: this.fb.group({
              hasHistory: [null],
              diseases: this.fb.control<DiseaseInformation[]>([]),
            }),
            carrierOfGeneticDiseases: this.fb.group({
              hasHistory: [null],
              diseases: this.fb.control<DiseaseInformation[]>([]),
            }),
          }),
          currentPregnancyInformation: this.fb.group({
            expectedConfinementDate: [null],
            gestationAge: this.fb.group({
              week: [null],
              day: [null],
            }),
            isGestationAgeAutomaticallyCalculated: [true],
            pregnancyType: [null],
            chorionicityType: [null],
            inVitro: this.fb.group({
              isInVitroFertilized: [null],
              vitroType: [null],
              isSurrogateMother: [null],
            }),
            priorScreeningTest: this.fb.group({
              priorScreeningTestType: [null],
              firstTRisks: this.fb.group({
                t21Risk: [null],
                t18Risk: [null],
                t13Risk: [null],
              }),
              secondTRisks: this.fb.group({
                t21Risk: [null],
                t18Risk: [null],
                t13Risk: [null],
              }),
            }),
            vanishingTwinSyndrome: this.fb.group({
              isDetected: [null],
              detectedGestationAge: this.fb.group({
                week: [null],
                day: [null],
              }),
            }),
            heparinTherapy: this.fb.group({
              hasReceived: [null],
              isRelevant: [null],
            }),
            ultrasoundDate: [null],
          }),
          provideGenderInformation: [null],
          incidentalFindings: [null],
          comments: [''],
        }),
      }),
    },
    {
      validators: [
        crossFieldValidator(
          'paymentInformation.notes',
          'paymentInformation.paymentType',
          [PaymentType.Customer1, PaymentType.Customer2]
        ),
      ],
    }
  );

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private cdRef: ChangeDetectorRef,
    public dialogRef: NbDialogRef<ServiceDataFormComponent>,
    private productUtils: ProductUtilsService,
    private destroyRef: DestroyRef,
    private authService: AuthenticationService,
    private partnersUtilsService: PartnersUtilsService
  ) {}

  ngOnInit() {
    if (this.id) {
      this.store
        .dispatch(new GetServiceDataAction(this.id))
        .pipe(
          switchMap(() =>
            this.store.select(ServiceDataState.getServiceDataById)
          ),
          map((getById) => getById(this.id)),
          first()
        )
        .subscribe((serviceData: ServiceData) => {
          this.form.patchValue({
            ...serviceData,
            patientInformation: {
              ...serviceData.patientInformation,
              dateOfBirth: moment(serviceData.patientInformation.dateOfBirth),
            },
            serviceInformation: {
              ...serviceData.serviceInformation,
              nipt: {
                ...serviceData.serviceInformation.nipt,
                currentPregnancyInformation: {
                  ...serviceData.serviceInformation.nipt
                    .currentPregnancyInformation,
                  isGestationAgeAutomaticallyCalculated: false,
                  expectedConfinementDate: moment(
                    serviceData.serviceInformation.nipt
                      .currentPregnancyInformation.expectedConfinementDate
                  ),
                  ultrasoundDate: moment(
                    serviceData.serviceInformation.nipt
                      .currentPregnancyInformation.ultrasoundDate
                  ),
                },
              },
            },
          } as any);

          if (serviceData?.sampleIds && serviceData.sampleIds.length) {
            this.form.get('bundleIds').disable();
          }

          this.cdRef.markForCheck();
        });
    } else if (this.patientFormId) {
      this.store
        .select(PatientFormsState.getPatientFormById)
        .pipe(
          first(),
          map((getPatientFormById) => getPatientFormById(this.patientFormId))
        )
        .subscribe((patientForm: PatientForm) => {
          this.patchFormWithPatientFormData(patientForm);
          this.form.markAllAsTouched();
          this.cdRef.markForCheck();
        });
    }

    this.form
      .get('partnerId')
      .valueChanges.pipe(
        tap((partnerId: string) => {
          this.prefillDisabled.set(!partnerId);
        }),
        switchMap((partnerId) =>
          this.store
            .select(PartnersState.getPartnerById)
            .pipe(map((getById) => getById(partnerId)))
        )
      )
      .subscribe((partner) => {
        this.form.patchValue({ onlineReports: partner?.onlineReport ?? false });
      });

    this.form
      .get('bundleIds')
      .valueChanges.pipe(
        startWith(this.form.get('bundleIds').value),
        takeUntilDestroyed(this.destroyRef),
        filter((bundles) => !!bundles),
        switchMap((bundleIds: string[]) =>
          this.store
            .select(ProductsListState.getBundles)
            .pipe(map((getByIds) => getByIds(bundleIds)))
        ),
        switchMap((bundles) =>
          this.store
            .select(ProductsListState.getAllChildProducts)
            .pipe(
              map((getByIds) =>
                getByIds(bundles.map((bundle) => bundle.productIds).flat(1))
              )
            )
        )
      )
      .subscribe((products) => {
        this.handlePregnancyType(products);
        handleValidators(
          products,
          this.form,
          'serviceInformation.nipt',
          ValidatorsHandlerType.SERVICEDATA
        );

        this.selectedDataInputTypes = products.map(
          (product) => product.dataInputType
        );

        const hasIncidentalFindings = products.some(
          (product) => product.incidentalFindings
        );
        const incidentalFormControl = this.form.get(
          'serviceInformation.nipt.incidentalFindings'
        );
        if (hasIncidentalFindings) {
          incidentalFormControl.enable();
        } else {
          incidentalFormControl.disable();
        }
      });

    this.paymentTypes$ = this.getPaymentTypes();
  }

  protected prefillTestData() {
    this.form.patchValue(prefillData);

    this.cdRef.markForCheck();
  }

  protected submit() {
    this.form.markAllAsTouched();

    const formData = this.form.getRawValue();

    if (this.form.valid) {
      this.loading = true;
      if (!this.id) {
        const invoiceId = Util.CreateGuid();
        formData.paymentInformation.invoiceId = invoiceId;
        this.store
          .dispatch(
            new AddInvoiceAction({
              applicationId: NIFTY_APPLICATIONID_KEY,
              id: invoiceId,
              properties: [
                {
                  itemKey: `${INVOICE_KEYS.SepaContract}`,
                  itemValue: 'false',
                },
              ],
              invoicePayments: [],
            })
          )
          .pipe(
            delay(200),
            switchMap(() =>
              this.store.dispatch(new CreateServiceDataAction(formData))
            ),
            switchMap(() =>
              this.patientFormId
                ? this.store.dispatch(
                    new CompletePatientFormAction(this.patientFormId)
                  )
                : of(null)
            )
          )
          .subscribe(() => this.dialogRef.close());
      } else {
        this.store
          .dispatch(new UpdateServiceDataAction(formData))
          .subscribe(() => this.dialogRef.close());
      }
    }
  }

  private handlePregnancyType(products: Product[]) {
    const types = this.productUtils.getBundlesNumberOfFetuses(products);
    if (types.length === 1) {
      this.form
        .get(
          'serviceInformation.nipt.currentPregnancyInformation.pregnancyType'
        )
        .setValue(types[0]);
      this.form
        .get(
          'serviceInformation.nipt.currentPregnancyInformation.pregnancyType'
        )
        .disable();
    } else if (types.length > 1) {
      this.form
        .get(
          'serviceInformation.nipt.currentPregnancyInformation.pregnancyType'
        )
        .enable();
    } else {
      this.form
        .get(
          'serviceInformation.nipt.currentPregnancyInformation.pregnancyType'
        )
        .setValue(null);
      if (this.id) {
        this.form
          .get(
            'serviceInformation.nipt.currentPregnancyInformation.pregnancyType'
          )
          .disable();
      } else {
        this.form
          .get(
            'serviceInformation.nipt.currentPregnancyInformation.pregnancyType'
          )
          .enable();
      }
    }
  }

  protected readonly ModalMode = ModalMode;

  private getPaymentTypes(): Observable<string[]> {
    return this.form.get('partnerId').valueChanges.pipe(
      startWith(this.form.get('partnerId').value),
      takeUntilDestroyed(this.destroyRef),
      filter((partnerId: string) => !!partnerId),
      switchMap((partnerId: string) =>
        this.partnersUtilsService.getPartnerById$(partnerId)
      ),
      filter((partner: Partner) => !!partner),
      switchMap((partner: Partner) =>
        this.authService
          .hasRequiredPermission$([
            'serviceData.edit.canSelectPartnerPaymentTypes',
          ])
          .pipe(first())
          .pipe(
            switchMap((canSelectPartnerPaymentTypes: boolean) =>
              this.authService
                .hasRequiredPermission$([
                  'serviceData.edit.canSelectAnyPaymentType',
                ])
                .pipe(first())
                .pipe(
                  map((canSelectAnyPaymentType) => {
                    this.form.get('paymentInformation.paymentType').enable();

                    if (canSelectAnyPaymentType) {
                      return Object.keys(PaymentType);
                    } else if (canSelectPartnerPaymentTypes) {
                      return partner.paymentTypes;
                    } else {
                      this.form.get('paymentInformation.paymentType').disable();
                      return [];
                    }
                  })
                )
            )
          )
      )
    );
  }

  private patchFormWithPatientFormData(data: PatientForm) {
    this.form.patchValue({
      partnerId: data.partnerId,
      bundleIds: [data.productId],
      patientInformation: {
        ...data.patientInformation,
        email: data.email,
        dateOfBirth: data.patientInformation?.dateOfBirth
          ? moment(data.patientInformation.dateOfBirth)
          : null,
        phoneNumber: data.patientInformation?.telephoneNumber,
      },
      serviceInformation: {
        nipt: {
          ...data.additionalInformation,
          doctorInformation: {
            doctorId: data?.doctorId,
          },
          provideGenderInformation:
            data.additionalInformation?.genderInformation,
          currentPregnancyInformation: {
            ...data.currentPregnancy,
            expectedConfinementDate: data.currentPregnancy?.expDateOfConfinement
              ? moment(data.currentPregnancy.expDateOfConfinement)
              : null,
            ultrasoundDate: data.currentPregnancy?.dateOfUltrasound
              ? moment(data.currentPregnancy.dateOfUltrasound)
              : null,
            inVitro: {
              isInVitroFertilized: data.currentPregnancy?.inVitro,
              vitroType: this.getCorrectVitroType(
                data.currentPregnancy?.vitroType
              ),
              isSurrogateMother: data.currentPregnancy?.isSurrogateMother,
            },
            vanishingTwinSyndrome: {
              isDetected: data.currentPregnancy?.vanishingTwinSyndrome,
            },
            heparinTherapy: {
              hasReceived: data.currentPregnancy?.heparinTherapy,
            },
            priorScreeningTest: {
              priorScreeningTestType:
                data.currentPregnancy?.priorScreeningTestType,
              firstTRisks: {
                t21Risk: data.currentPregnancy?.firstT21Risk,
                t18Risk: data.currentPregnancy?.firstT18Risk,
                t13Risk: data.currentPregnancy?.firstT13Risk,
              },
              secondTRisks: {
                t21Risk: data.currentPregnancy?.secondT21Risk,
                t18Risk: data.currentPregnancy?.secondT18Risk,
                t13Risk: data.currentPregnancy?.secondT13Risk,
              },
            },
          },
          patientHistory: {
            numberOfPregnancies: data.historyInformation?.gravida,
            numberOfBirths: data.historyInformation?.para,
            cancerHistory: {
              hasHistory: data.historyInformation?.historyOfCancer,
            },
            albuminTherapy: {
              hasReceived: data.historyInformation?.albuminTherapy,
            },
            cellularImmunotherapy: {
              hasReceived: data.historyInformation?.cellularImmunotherapy,
            },
            stemCellTherapy: {
              hasReceived: data.historyInformation?.stemCellTherapy,
            },
            transplantSurgery: {
              hasReceived: data.historyInformation?.transplantSurgery,
            },
            bloodTransfusion: {
              hasReceived: data.historyInformation?.bloodTransfusion,
            },
            familyDiseaseHistory: {
              hasHistory: data.historyInformation?.familyDiseaseHistory,
              diseases:
                data.historyInformation?.familyDiseaseHistoryCondition &&
                data.historyInformation?.familyDiseaseHistoryRelation
                  ? [
                      {
                        conditionDescription:
                          data.historyInformation.familyDiseaseHistoryCondition,
                        relationToPatient:
                          data.historyInformation.familyDiseaseHistoryRelation,
                      },
                    ]
                  : [],
            },
            pregnancyDiseaseHistory: {
              hasHistory: data.historyInformation?.pregnancyDiseaseHistory,
              diseases: data.historyInformation
                ?.pregnancyDiseaseHistoryCondition
                ? [
                    {
                      conditionDescription:
                        data.historyInformation
                          .pregnancyDiseaseHistoryCondition,
                    },
                  ]
                : [],
            },
            carrierOfGeneticDiseases: {
              hasHistory:
                data.historyInformation?.carrierOfGeneticDiseaseInformations,
              diseases: data.historyInformation
                ?.carrierOfGeneticDiseaseInformationsCondition
                ? [
                    {
                      conditionDescription:
                        data.historyInformation
                          .carrierOfGeneticDiseaseInformationsCondition,
                    },
                  ]
                : [],
            },
          },
        },
      },
    });
  }

  private getCorrectVitroType(vitroType: string): string {
    if (!vitroType) {
      return null;
    }

    return vitroType === RequestFormsVitroType.OwnEgg
      ? InVitroTypes.OwnEgg
      : InVitroTypes.DonorEgg;
  }
}
