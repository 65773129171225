import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NIFTY_APPLICATIONID_KEY, PAGING_HEADERS } from '@app/app.constants';
import {
  ProvisionSample,
  Sample,
} from '@app/modules/service-data/service-data.types';
import { SampleUtilsService } from '@app/modules/service-data/services/sample-utils.service';
import { ApiFilters, ApiHeaders } from '@core/core.types';
import { HttpUtils } from '@core/utils/http-utils';
import { environment } from '@root/environments/environment';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SampleService {
  readonly API_URL = `${environment.apiUrl}/service-data`;
  constructor(
    private http: HttpClient,
    private sampleUtilsService: SampleUtilsService
  ) {}

  getSampleList(
    filters: SampleFilters,
    headers: ApiHeaders
  ): Observable<{
    items: Sample[];
    continuationToken: string;
    totalCount: number;
  }> {
    return this.http
      .get<Sample[]>(`${this.API_URL}/samples`, {
        observe: 'response',
        params: this.sampleUtilsService.buildHttpParams(filters),
        headers: HttpUtils.buildHttpHeaders(headers),
      })
      .pipe(
        map((response) => ({
          items: response.body,
          continuationToken: response.headers.get(
            PAGING_HEADERS.continuationToken
          ),
          totalCount: parseInt(
            response.headers.get(PAGING_HEADERS.totalCount),
            10
          ),
        }))
      );
  }

  getSample(id: string) {
    return this.http.get<Sample>(`${this.API_URL}/samples/${id}`);
  }

  createSample(sample: ProvisionSample) {
    return this.http.post<{ id: string }>(`${this.API_URL}/samples`, {
      ...sample,
      ApplicationId: NIFTY_APPLICATIONID_KEY,
    });
  }

  updateSample(sample: Sample) {
    return this.http.put(`${this.API_URL}/samples/${sample.id}`, {
      ...sample,
      ApplicationId: NIFTY_APPLICATIONID_KEY,
    });
  }

  removeSample(id: string) {
    return this.http.delete(`${this.API_URL}/samples/${id}`);
  }

  changeKit(id: string, kitId: string) {
    return this.http.post<{ id: string }>(
      `${this.API_URL}/samples/${id}/kit-change`,
      {
        kitId,
      }
    );
  }
}

export interface SampleFilters extends ApiFilters {
  kitIds?: string[];
  kitToken?: string;
  ids?: string[];
  serviceDataIds?: string[];
  productInventoryIds?: string[];
  productIds?: string[];
  serviceDataPartnerIds?: string[];
  serviceDataDoctorIds?: string[];
  customerIds?: string[];
  notesToken?: string;
  laboratory?: string;
  laboratories?: string[];
  partnerId?: string;
  waybillToken?: string;
  countries?: string;
  status?: string;
  dateOfVenipunctureFrom?: string;
  dateOfVenipunctureTo?: string;
  generatedFrom?: string;
  generatedTo?: string;
  forResampleFrom?: string;
  forResampleTo?: string;
  redrawnFrom?: string;
  redrawnTo?: string;
  accountManagerId?: string;
}
