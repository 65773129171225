import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { combineLatest, forkJoin, of, throwError } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { INVOICE_KEYS } from '../../../../app.constants';
import { Invoice, InvoicePayment } from '../../core.types';
import { InvoicePaymentsService } from '../../services/invoice-payments.service';
import { Logger } from '../../services/logger.service';
import {
  AddInvoiceAction,
  AddInvoicePaymentsAction,
  AssignInvoicePaymentsToInvoiceAction,
  LoadInvoiceById,
  LoadInvoicesByIdsAction,
  LoadInvoicesPaymentsListAction,
  MarkAsPaidAction,
  MarkInvoicePaymentAsPaidAction,
  PAYMENT_INVOICES_STATE_NAME,
  RemoveInvoicePaymentAction,
  RevokeInvoicePaid,
  SetDiscountAction,
  SetLaboratoryPriceAction,
  UpdateInvoiceAction,
  UpdateInvoicePaymentAction,
} from './invoice-payments.actions';
import { Util } from '@core/utils/core.util';
import { GetServiceDataListAction } from '@app/modules/service-data/store/service-data.actions';

export interface InvoicesStateModel {
  invoices: { [id: string]: Invoice };
  invoiceIdsList: string[];
  invoicesPaymentsList: { [invoiceId: string]: InvoicePayment[] };
}

@State<InvoicesStateModel>({
  name: PAYMENT_INVOICES_STATE_NAME,
  defaults: {
    invoices: {},
    invoiceIdsList: [],
    invoicesPaymentsList: {},
  },
})
@Injectable()
export class InvoicesState {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    private invoicePaymentsService: InvoicePaymentsService,
    private store: Store
  ) {}

  @Selector()
  static getInvoicesList(state: InvoicesStateModel) {
    return state.invoices;
  }

  @Selector()
  static getInvoiceById({ invoices }: InvoicesStateModel) {
    return (invoiceId: string) => invoices[invoiceId];
  }

  @Selector()
  static getInvoicePymentsByInvoiceId(state: InvoicesStateModel) {
    return (invoiceId: string) => state.invoicesPaymentsList?.[invoiceId] ?? [];
  }

  @Action(LoadInvoicesByIdsAction)
  loadInvoicesByIdsAction(
    { getState, patchState, dispatch }: StateContext<InvoicesStateModel>,
    { ids }: LoadInvoicesByIdsAction
  ) {
    const filters = new Map<string, string>();

    if (ids?.length > 100) {
      return forkJoin(
        Util.chunkArray(ids, 100).map((ids) =>
          dispatch(new LoadInvoicesByIdsAction(ids))
        )
      );
    }

    filters.set('ids', ids.filter((id) => !!id).join(','));
    return this.invoicePaymentsService.getFilteredEntities({ filters }).pipe(
      tap((resp) => {
        const entities = resp.entitiesList;
        if (entities.length) {
          const updatedInvoices = {
            ...getState().invoices,
          };
          const invoiceIdsList: string[] = [];
          for (const invoice of entities) {
            updatedInvoices[invoice.id] = invoice;
            invoiceIdsList.push(invoice.id);
          }
          patchState({
            invoices: updatedInvoices,
            invoiceIdsList,
          });
        }
      })
    );
  }

  @Action(LoadInvoiceById)
  loadInvoiceById(
    { getState, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId, refresh }: LoadInvoiceById
  ) {
    if (!invoiceId || invoiceId === 'temp_fix_not_empty') {
      return;
    }
    this.log.debug('load invoice by id');

    const { invoices } = getState();
    if (!refresh && invoices[invoiceId]) {
      return of(invoices[invoiceId]);
    }

    return this.invoicePaymentsService.getInvoiceById(invoiceId).pipe(
      map((invoice: Invoice) => {
        return patchState({
          invoices: {
            ...getState().invoices,
            [invoiceId]: invoice ? invoice : null,
          },
        });
      }),
      catchError(() => of(null))
    );
  }

  @Action(UpdateInvoiceAction)
  updateInvoiceAction(
    { getState, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId, sepaContract }: UpdateInvoiceAction
  ) {
    this.log.debug('update invoice');

    const { invoices } = getState();
    if (!Object.prototype.hasOwnProperty.call(invoices, invoiceId)) {
      return of(null);
    }

    return this.invoicePaymentsService
      .updateInvoice(invoiceId, sepaContract)
      .pipe(
        tap((result) => {
          if (!result) {
            const invoice = getState().invoices[invoiceId];

            const invoiceCopy = { ...invoice };
            invoiceCopy.properties = [
              {
                itemKey: INVOICE_KEYS.SepaContract,
                itemValue: String(sepaContract).toLowerCase(),
              },
            ];

            patchState({
              invoices: {
                ...getState().invoices,
                [invoiceId]: invoiceCopy,
              },
            });
          }
        })
      );
  }

  @Action(LoadInvoicesPaymentsListAction)
  loadInvoicesPaymentsListAction(
    { getState, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId, refresh }: LoadInvoicesPaymentsListAction
  ) {
    this.log.debug('load invoice payments');

    const { invoices, invoicesPaymentsList } = getState();
    const invoice = invoices[invoiceId];

    if (!invoice) {
      return of(null);
    }

    if (
      !refresh &&
      Object.prototype.hasOwnProperty.call(invoicesPaymentsList, invoiceId)
    ) {
      return of(invoicesPaymentsList[invoiceId]);
    }

    if (
      !Object.prototype.hasOwnProperty.call(invoicesPaymentsList, invoiceId)
    ) {
      patchState({
        invoicesPaymentsList: {
          ...invoicesPaymentsList,
          [invoiceId]: undefined,
        },
      });
    }

    const invoicePaymentsIds = invoice.invoicePayments;
    if (!invoicePaymentsIds?.length) {
      return of(null);
    }

    return this.invoicePaymentsService
      .getInvoicePaymentsList(invoice.id, invoicePaymentsIds)
      .pipe(
        tap((entities) => {
          if (entities.length) {
            patchState({
              invoicesPaymentsList: {
                ...getState().invoicesPaymentsList,
                [invoice.id]: entities,
              },
            });
          }
        })
      );
  }

  @Action(AddInvoiceAction)
  addInvoiceAction(
    { getState, patchState }: StateContext<InvoicesStateModel>,
    { invoice }: AddInvoiceAction
  ) {
    this.log.debug('add invoice state');

    const sepaContractProp = invoice.properties.find(
      (p) =>
        p.itemKey.toLocaleLowerCase() ===
        INVOICE_KEYS.SepaContract.toLocaleLowerCase()
    );
    const sepaContract = JSON.parse(sepaContractProp.itemValue);

    return this.invoicePaymentsService
      .generateInvoice(invoice.id, sepaContract)
      .pipe(
        catchError((err) => throwError(err)),
        delay(1000),
        switchMap((result) => {
          if (result !== null) {
            return of(result);
          }

          return this.invoicePaymentsService.getInvoiceById(invoice.id).pipe(
            tap((newInvoice) => {
              const invoices = getState().invoices;
              const getInvoiceIdsList = getState().invoiceIdsList;
              const getinvoicesPaymentsListState =
                getState().invoicesPaymentsList;

              const invoicePaymentsListCopy = {
                ...getinvoicesPaymentsListState,
                [invoice.id]: [],
              };

              patchState({
                invoices: {
                  ...invoices,
                  [invoice.id]: newInvoice,
                },
                invoiceIdsList: [...getInvoiceIdsList, newInvoice.id],
                invoicesPaymentsList: invoicePaymentsListCopy,
              });
            })
          );
        })
      );
  }

  @Action(AssignInvoicePaymentsToInvoiceAction)
  assignInvoicePaymentsToInvoiceAction(
    { getState, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId, invoicePayment }: AssignInvoicePaymentsToInvoiceAction
  ) {
    this.log.debug('assign invoice payment to invocie state');

    return this.invoicePaymentsService
      .assignInvoicePaymentToInvoice(invoiceId, invoicePayment)
      .pipe(
        tap((result) => {
          if (result === null) {
            const state = getState();
            const invoices = state.invoices;
            const invoiceCopy = { ...invoices[invoiceId] };
            const invoicePaymentsCopy = [
              ...(state.invoicesPaymentsList?.[invoiceCopy.id] ?? []),
            ];

            invoiceCopy.invoicePayments = [
              ...invoiceCopy.invoicePayments,
              invoicePayment.id,
            ];

            invoicePaymentsCopy.push(invoicePayment);

            patchState({
              invoices: {
                ...invoices,
                [invoiceId]: invoiceCopy,
              },
              invoicesPaymentsList: {
                ...state.invoicesPaymentsList,
                [invoiceCopy.id]: invoicePaymentsCopy,
              },
            });
          }
        })
      );
  }

  @Action(AddInvoicePaymentsAction)
  addInvoicePaymentsAction(
    { getState }: StateContext<InvoicesStateModel>,
    { invoiceId, invoicePaymentsList }: AddInvoicePaymentsAction
  ) {
    this.log.debug('add invoice payments to invoice');

    const invoice = getState().invoices[invoiceId];

    const observablesArray = [];

    for (const invoicePayment of invoicePaymentsList) {
      const observable = this.invoicePaymentsService
        .generateInvoicePayment(invoicePayment)
        .pipe(
          tap((result) => {
            if (result === null) {
              this.store.dispatch(
                new AssignInvoicePaymentsToInvoiceAction(
                  invoice.id,
                  invoicePayment
                )
              );
            }
          })
        );
      observablesArray.push(observable);
    }

    return combineLatest(observablesArray);
  }

  @Action(RemoveInvoicePaymentAction)
  removeInvoicePaymentAction(
    { getState, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId, paymentId }: RemoveInvoicePaymentAction
  ) {
    this.log.debug('remove invoice payment from invoice');

    return this.invoicePaymentsService
      .unassignInvoicePaymentFromInvoice(invoiceId, paymentId)
      .pipe(
        switchMap((result) =>
          this.invoicePaymentsService.deleteInvoicePayment(paymentId)
        ),
        tap(() => {
          const state = getState();
          const invoices = state.invoices;
          const invoiceCopy = { ...invoices[invoiceId] };
          const invoicePaymentsCopy = [
            ...state.invoicesPaymentsList[invoiceCopy.id].filter(
              (payment) => payment.id !== paymentId
            ),
          ];

          invoiceCopy.invoicePayments = [
            ...invoiceCopy.invoicePayments.filter(
              (payment) => payment !== paymentId
            ),
          ];

          patchState({
            invoices: {
              ...invoices,
              [invoiceId]: invoiceCopy,
            },
            invoicesPaymentsList: {
              ...state.invoicesPaymentsList,
              [invoiceCopy.id]: invoicePaymentsCopy,
            },
          });
        })
      );
  }

  @Action(UpdateInvoicePaymentAction)
  updateInvoicePaymentAction(
    { getState, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId, invoicePayment }: UpdateInvoicePaymentAction
  ) {
    this.log.debug('update invoice payment');

    if (!getState().invoices[invoiceId]) {
      return of(null);
    }

    if (!getState().invoicesPaymentsList[invoiceId]) {
      return of(null);
    }

    const invoicesPaymentsListCopy = [
      ...getState().invoicesPaymentsList[invoiceId],
    ];

    return this.invoicePaymentsService
      .updateInvoicePayment(invoicePayment)
      .pipe(
        tap((result) => {
          if (result === null) {
            const invoicePaymentIndex = invoicesPaymentsListCopy.findIndex(
              (i) => i.id === invoicePayment.id
            );

            invoicesPaymentsListCopy[invoicePaymentIndex] = invoicePayment;

            patchState({
              invoicesPaymentsList: {
                ...getState().invoicesPaymentsList,
                [invoiceId]: invoicesPaymentsListCopy,
              },
            });
          }
        })
      );
  }

  @Action(MarkInvoicePaymentAsPaidAction)
  markInvoicePaymentAsPaid(
    { getState, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId, invoicePaymentId }: MarkInvoicePaymentAsPaidAction
  ) {
    this.log.debug('Mark invoice payment as paid');

    const { invoices } = getState();

    if (!Object.prototype.hasOwnProperty.call(invoices, invoiceId)) {
      return of(null);
    }

    return this.invoicePaymentsService
      .markInvoicePaymentAsPaid(invoicePaymentId)
      .pipe(
        delay(500),
        switchMap((result) => {
          if (result !== null) {
            return of(result);
          }

          const invoicePaymentsState =
            getState().invoicesPaymentsList[invoiceId];

          const invoicePaymentIndex = invoicePaymentsState.findIndex(
            (i) => i.id === invoicePaymentId
          );

          return this.invoicePaymentsService
            .getInvoicePaymentsList(invoiceId, [invoicePaymentId])
            .pipe(
              tap((invoicePaymentList) => {
                const invoicesPaymentsListCopy = [
                  ...getState().invoicesPaymentsList[invoiceId],
                ];

                invoicesPaymentsListCopy[invoicePaymentIndex] =
                  invoicePaymentList[0];
                patchState({
                  invoicesPaymentsList: {
                    ...getState().invoicesPaymentsList,
                    [invoiceId]: invoicesPaymentsListCopy,
                  },
                });
              })
            );
        })
      );
  }

  @Action(SetLaboratoryPriceAction)
  setLaboratoryPriceAction(
    { getState, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId, laboratoryPrice }: SetLaboratoryPriceAction
  ) {
    const invoices = getState().invoices;
    return this.invoicePaymentsService
      .setInvoiceLaboratoryPrice(invoiceId, laboratoryPrice)
      .pipe(
        tap(() => {
          patchState({
            invoices: {
              ...invoices,
              [invoiceId]: { ...invoices[invoiceId], laboratoryPrice },
            },
          });
        })
      );
  }

  @Action(SetDiscountAction)
  SetDiscountAction(
    { getState, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId, discountValue }: SetDiscountAction
  ) {
    const invoices = getState().invoices;
    return this.invoicePaymentsService
      .setInvoiceDiscount(invoiceId, discountValue)
      .pipe(
        tap(() => {
          patchState({
            invoices: {
              ...invoices,
              [invoiceId]: { ...invoices[invoiceId], discountValue },
            },
          });
        })
      );
  }

  @Action(RevokeInvoicePaid)
  revokeInvoicePaidAction(
    { getState, dispatch, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId }: RevokeInvoicePaid
  ) {
    return this.invoicePaymentsService.revokePaidInFull(invoiceId).pipe(
      delay(300),
      switchMap(() => this.store.dispatch(new LoadInvoiceById(invoiceId, true)))
    );
  }

  @Action(MarkAsPaidAction)
  markAsPaidAction(
    { getState, dispatch, patchState }: StateContext<InvoicesStateModel>,
    { invoiceId }: MarkAsPaidAction
  ) {
    return this.invoicePaymentsService.paidInFull(invoiceId).pipe(
      delay(300),
      switchMap(() => this.store.dispatch(new LoadInvoiceById(invoiceId, true)))
    );
  }
}
