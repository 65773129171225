import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DATEPICKER_DATE_FORMAT } from '@app/app.constants';
import {
  ChorinicityType,
  InVitroTypes,
  PregnancyType,
  PriorScreeningTestType,
} from '@app/modules/service-data/service-data.types';
import { DataInputType } from '@core/core.types';
import { NbMomentDateService } from '@nebular/moment';
import {
  NbCheckboxModule,
  NbDatepickerModule,
  NbDateService,
  NbInputModule,
  NbOptionModule,
  NbRadioModule,
  NbSelectModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationErrorComponent } from '@shared/components/validation-error/validation-error.component';
import { InputValidationStatusDirective } from '@shared/directives/input-validation-status.directive';
import { controlContainerProvider } from '@shared/helpers';
import moment from 'moment';
import { combineLatest, startWith } from 'rxjs';

@Component({
  selector: 'app-service-data-form-current-pregnancy-information',
  imports: [
    NbInputModule,
    ReactiveFormsModule,
    TranslateModule,
    NbCheckboxModule,
    NbOptionModule,
    NbSelectModule,
    NbRadioModule,
    TranslateModule,
    ValidationErrorComponent,
    NbDatepickerModule,
    InputValidationStatusDirective,
  ],
  providers: [{ provide: NbDateService, useClass: NbMomentDateService }],
  templateUrl:
    './service-data-form-current-pregnancy-information.component.html',
  viewProviders: [controlContainerProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDataFormCurrentPregnancyInformationComponent
  implements OnInit
{
  @Input() selectedDataInputTypes: DataInputType[] = [];
  protected readonly PregnancyType = PregnancyType;
  protected readonly Object = Object;
  protected readonly ChorinicityType = ChorinicityType;
  protected get form() {
    return this.controlContainer.control as FormGroup;
  }

  protected minDate = moment().subtract(1, 'year');
  protected maxDate = moment().add(1, 'year');
  protected dueDateStartDate = moment().add(210, 'days');
  protected DATEPICKER_DATE_FORMAT = DATEPICKER_DATE_FORMAT;

  private destroyRef = inject(DestroyRef);

  constructor(
    private controlContainer: ControlContainer,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const priorScreeningTypeControl = this.form.get(
      'priorScreeningTest.priorScreeningTestType'
    );
    const pregnancyTypeControl = this.form.get('pregnancyType');

    combineLatest([
      priorScreeningTypeControl.valueChanges.pipe(
        startWith(priorScreeningTypeControl.value)
      ),
      pregnancyTypeControl.valueChanges.pipe(
        startWith(pregnancyTypeControl.value)
      ),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([priorScreeningTestType, pregnancyType]) => {
        if (priorScreeningTestType === PriorScreeningTestType.NoTestTaken) {
          this.form.get('priorScreeningTest.firstTRisks').reset(null);
          this.form.get('priorScreeningTest.firstTRisks').disable();
          this.form.get('priorScreeningTest.secondTRisks').reset(null);
          this.form.get('priorScreeningTest.secondTRisks').disable();
        } else {
          this.form.get('priorScreeningTest.firstTRisks').enable();
          if (pregnancyType === PregnancyType.twins) {
            this.form.get('priorScreeningTest.secondTRisks').enable();
          } else {
            this.form.get('priorScreeningTest.secondTRisks').disable();
            this.form.get('priorScreeningTest.secondTRisks').reset(null);
          }
        }
      });

    const gestationAgeAutoCalculateControl = this.form.get(
      'isGestationAgeAutomaticallyCalculated'
    );
    const expectedConfinementDateControl = this.form.get(
      'expectedConfinementDate'
    );

    combineLatest([
      gestationAgeAutoCalculateControl.valueChanges.pipe(
        startWith(gestationAgeAutoCalculateControl.value)
      ),
      expectedConfinementDateControl.valueChanges.pipe(
        startWith(expectedConfinementDateControl.value)
      ),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isGestationAgeAutoCalculated, expectedConfinementDate]) =>
        this.calculateGestationAge(
          isGestationAgeAutoCalculated,
          expectedConfinementDate
        )
      );

    this.form
      .get('pregnancyType')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pregnancyType: PregnancyType) => {
        if (pregnancyType !== PregnancyType.twins) {
          this.form.get('chorionicityType').reset(null);
        }
      });

    this.form
      .get('inVitro.isInVitroFertilized')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((inVitro: boolean) => {
        if (!inVitro) {
          this.form.get('inVitro.vitroType').reset(null);
          this.form.get('inVitro.isSurrogateMother').reset(null);
        }
      });

    this.form
      .get('vanishingTwinSyndrome.isDetected')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((vanishingTwinSyndrome: boolean) => {
        if (!vanishingTwinSyndrome) {
          this.form
            .get('vanishingTwinSyndrome.detectedGestationAge.day')
            .reset(null);
          this.form
            .get('vanishingTwinSyndrome.detectedGestationAge.week')
            .reset(null);
        }
      });

    this.form
      .get('heparinTherapy.hasReceived')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((heparinTherapy: boolean) => {
        if (!heparinTherapy) {
          this.form.get('heparinTherapy.isRelevant').reset(null);
        }
      });
  }

  protected readonly InVitroTypes = InVitroTypes;
  protected readonly PriorScreeningTestType = PriorScreeningTestType;

  private calculateGestationAge(
    isGestationAgeAutoCalculated: boolean,
    expectedConfinementDate
  ) {
    if (!isGestationAgeAutoCalculated || !expectedConfinementDate) {
      return;
    }

    const dateNow = moment();
    const startOfPregnancy = moment(expectedConfinementDate).subtract(280, 'd');

    const fullGestationDays = dateNow.diff(startOfPregnancy, 'd');
    const week = Math.floor(fullGestationDays / 7);
    const day = fullGestationDays % 7;

    this.form.get('gestationAge').patchValue({ day, week });
    this.form.get('gestationAge').markAllAsTouched();
    this.cdRef.markForCheck();
  }
}
