import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DocumentInteraction } from '@app/modules/service-data/service-data.types';
import {
  DataTableConfiguration,
  DataTableDataType,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import moment from 'moment';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { DataTableComponent } from '../data-table/data-table.component';

@Component({
  selector: 'app-document-interactions-list',
  templateUrl: './document-interactions-list.component.html',
  styleUrls: ['./document-interactions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DataTableComponent],
})
export class DocumentInteractionsListComponent implements OnInit {
  documentInteractions$: Observable<any[]> = of([]);

  dataTableConfiguration: DataTableConfiguration;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('rows')
  set _rows(rows: DocumentInteraction[]) {
    this.documentInteractions$ = of(
      rows
        .map((interaction) => ({
          ...interaction,
          interacted: moment(interaction.interacted),
        }))
        .sort((a, b) => a.interacted.valueOf() - b.interacted.valueOf())
    );
  }

  ngOnInit(): void {
    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      tableClasses: '',
      tableCellClasses: 'pt-1 pb-0 border-0',
      columns: [
        {
          label: 'Interaction',
          property: 'interaction',
        },
        {
          label: 'Interacted By',
          property: 'interactedBy',
        },
        {
          label: 'Interacted',
          property: 'interacted',
          customStyle: 'width: 30%',
          type: DataTableDataType.DATETIME,
        },
      ],
      rows$: this.documentInteractions$,
    };
  }
}
