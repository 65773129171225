import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS, ServiceType } from '@app/app.constants';
import { ProductInventoryItemUtilsService } from '@app/modules/service-data/services/product-inventory-item-utils.service';
import { GetProductInventoryItemListAction } from '@app/modules/service-data/store/product-intentory-item.actions';
import { ProductInventoryItemState } from '@app/modules/service-data/store/product-inventory-item.state';
import { AnalysisType, Properties } from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { ReportsService } from '@core/services/reports.service';
import { getFileNameWithoutExtension, Util } from '@core/utils/core.util';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { first, map, switchMap } from 'rxjs/operators';
import {
  Document,
  DocumentExtensions,
  DocumentInteraction,
  DocumentKeywords,
  DocumentStatus,
  ExtendedDocument,
  ExtendedProductInventoryItem,
  NonOfficialDocumentReportTypes,
} from '../../service-data/service-data.types';
import { patchDocument, PatchType, TextRun } from 'docx';
import { HttpClient } from '@angular/common/http';

import * as translations from '../../../../assets/blank-reports/language_translations.json';
import { LanguageState } from '@core/store/languages/languages.state';

@Injectable({
  providedIn: 'root',
})
export class DocumentsUtilsService {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    private reportsService: ReportsService,
    private productInventoryItemUtils: ProductInventoryItemUtilsService,
    private store: Store,
    private http: HttpClient
  ) {}

  public isBGIDocument(document: Document) {
    return (
      !document.fileName.includes('-HR-') && !document.fileName.includes('-HK-')
    );
  }

  public getCheckedDocuments(): string[] {
    return (
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.checkedDocuments)) ??
      []
    );
  }

  public isDocumentChecked(id) {
    return this.getCheckedDocuments().includes(id);
  }

  public getExtendedDocuments(
    documents: Document[],
    appends: DocumentExtensions = {
      pii: null,
    }
  ): Observable<ExtendedDocument[]> {
    const documentIds = documents.map((document) => document.id);
    return this.store
      .dispatch(
        new GetProductInventoryItemListAction(
          { documentIds: documentIds },
          null,
          true
        )
      )
      .pipe(
        switchMap(() =>
          this.store
            .select(
              ProductInventoryItemState.getProductInventoryItemsByDocumentIds
            )
            .pipe(map((getByDocumentIds) => getByDocumentIds(documentIds)))
            .pipe(
              switchMap((piis) =>
                this.productInventoryItemUtils.loadProductInventoryItemConnectedData(
                  piis,
                  appends.pii
                )
              )
            )
        ),
        switchMap(() =>
          combineLatest(
            documents.map((document) =>
              combineLatest([
                of(document),
                appends.pii
                  ? this.store
                      .select(
                        ProductInventoryItemState.getProductInventoryItemByDocumentId
                      )
                      .pipe(
                        map((getById) => getById(document.id)),
                        switchMap((pii) =>
                          this.productInventoryItemUtils.getExtendedProductInventoryItem(
                            pii,
                            appends.pii
                          )
                        )
                      )
                  : of(null),
              ])
            )
          )
        ),
        map((items) =>
          items.map(([document, productInventoryItem]) => ({
            ...document,
            productInventoryItem,
          }))
        )
      );
  }

  public getLastDocumentInteractionByType(
    interactions: DocumentInteraction[],
    interactionType: DocumentStatus.approved | DocumentStatus.disapproved
  ): DocumentInteraction | null {
    if (interactions && interactions.length) {
      const sortedInteractions = [...interactions]
        .filter(
          ({ interaction }) =>
            interaction !== DocumentStatus.downloaded &&
            interaction !== DocumentStatus.uploaded &&
            interaction !== DocumentStatus.archived
        )
        .sort(
          (a, b) => moment(b.interacted).unix() - moment(a.interacted).unix()
        );
      const [lastInteraction] = sortedInteractions;
      if (lastInteraction && lastInteraction.interaction === interactionType) {
        return lastInteraction;
      }
    }
    return null;
  }

  public uploadBase64Docx(
    fileName: string,
    data: string,
    documentProperties: Properties[] = [],
    analysisType: AnalysisType
  ) {
    this.log.debug('upload docx', fileName);

    fileName = Util.addAnalysisTypeToName(fileName, analysisType);

    let reportType: string;

    if (fileName.endsWith('.pdf')) {
      reportType = 'pdf';
    }
    if (fileName.endsWith('.docx') || fileName.endsWith('.doc')) {
      reportType = 'docx';
    }

    // Extract barcode from filename
    let kitId = fileName.split('-')[0];

    // Replace P with B -> so P documents are not unclassified
    // Remove D at the end for twins
    kitId = kitId.replace('P', 'B').replace('D', '').replace('V', '');

    // Check if barcode belongs to a redraw pii
    return this.productInventoryItemUtils
      .findRedrawBarcode(kitId, analysisType)
      .pipe(
        switchMap((kitId) => {
          // Replace redraw KitId barcode with redrawn KitId
          fileName = this.productInventoryItemUtils.replaceFilenameBarcode(
            fileName,
            kitId
          );
          return combineLatest([
            of(data),
            this.reportsService.provisionReport({
              Data: data,
              FileName: getFileNameWithoutExtension(fileName),
              ReportType: reportType,
            }),
          ]);
        }),
        map(([data, fileName]) => {
          const document = {
            guid: Util.CreateGuid(),
            kitId,
            fileName: fileName + '.pdf',
            data,
            documentProperties: [
              ...documentProperties,
              {
                key: 'DocumentType',
                value: Util.getReportTypeFromName(fileName),
              },
              {
                key: 'AnalysisType',
                value: Util.getAnalysisTypeFromName(fileName),
              },
            ],
          };

          if (Util.getDocumentDescriptionFromName(fileName)) {
            document.documentProperties.push({
              key: 'DocumentDescription',
              value: Util.getDocumentDescriptionFromName(fileName),
            });
          }

          if (Util.getDocumentReason(fileName)) {
            document.documentProperties.push({
              key: 'DocumentReason',
              value: Util.getDocumentReason(fileName),
            });
          }

          return document;
        }),
        switchMap((document) =>
          this.productInventoryItemUtils.addDocumentsToPiis([document])
        )
      );
  }

  private uploadDocument(
    file,
    documentProperties: Properties[],
    analysisType: AnalysisType
  ): Observable<{
    guid: string;
    fileName: string;
    kitId: string;
    data: string;
    documentProperties: Properties[];
  }> {
    let newFilename = this.transformBGIFilename(file);

    newFilename = Util.addAnalysisTypeToName(newFilename, analysisType);

    let reportType: string;

    if (file.name.endsWith('.pdf')) {
      reportType = 'pdf';
    }
    if (file.name.endsWith('.docx') || file.name.endsWith('.doc')) {
      reportType = 'docx';
    }

    // Extract barcode from filename
    let kitId = newFilename.split('-')[0];

    // Replace P with B -> so P documents are not unclassified
    // Remove D at the end for twins
    kitId = kitId.replace('P', 'B').replace('D', '').replace('V', '');

    // Check if barcode belongs to a redraw pii
    return this.productInventoryItemUtils
      .findRedrawBarcode(kitId, Util.getAnalysisTypeFromName(newFilename))
      .pipe(
        first(),
        switchMap((newKitId) => {
          kitId = newKitId;
          // Replace redraw KitId barcode with redrawn KitId
          newFilename = this.productInventoryItemUtils.replaceFilenameBarcode(
            newFilename,
            kitId
          );

          return combineLatest([
            of(newFilename),
            Util.getBase64ImageFromFile(file),
          ]);
        }),
        switchMap(([newFilename, data]) =>
          combineLatest([
            of(data),
            this.reportsService.provisionReport({
              Data: data,
              FileName: getFileNameWithoutExtension(newFilename),
              ReportType: reportType,
            }),
          ])
        ),
        map(([data, fileName]) => {
          const document = {
            guid: Util.CreateGuid(),
            kitId,
            fileName: fileName + '.pdf',
            data,
            documentProperties: [
              ...documentProperties,
              {
                key: 'DocumentType',
                value: Util.getReportTypeFromName(fileName),
              },
              {
                key: 'AnalysisType',
                value: Util.getAnalysisTypeFromName(fileName),
              },
            ],
          };

          if (Util.getReportResultFromName(fileName)) {
            document.documentProperties.push({
              key: 'DocumentReason',
              value: Util.getReportResultFromName(fileName),
            });
          }

          if (Util.getDocumentDescriptionFromName(fileName)) {
            document.documentProperties.push({
              key: 'DocumentDescription',
              value: Util.getDocumentDescriptionFromName(fileName),
            });
          }

          if (Util.getDocumentReason(fileName)) {
            document.documentProperties.push({
              key: 'DocumentReason',
              value: Util.getDocumentReason(fileName),
            });
          }

          return document;
        })
      );
  }

  uploadDocumentsMass(
    files: File[],
    documentProperties: Properties[],
    analysisType: AnalysisType
  ) {
    return combineLatest(
      files.map((file) =>
        this.uploadDocument(file, documentProperties, analysisType)
      )
    ).pipe(
      switchMap((documentsList) =>
        this.productInventoryItemUtils.addDocumentsToPiis(documentsList)
      )
    );
  }

  public transformBGIFilename(file: File) {
    if (file.name.toLowerCase().includes('_geneplanet')) {
      this.log.info('BGI report naming');

      // Replace "re-sample/resampling" with "resample", and "high-risk" with "high risk", so it doesn't mess with filename transformation
      const fileName = file.name
        .replace(
          new RegExp(DocumentKeywords.resample1, 'gi'),
          DocumentKeywords.resample2
        )
        .replace(
          new RegExp(DocumentKeywords.resample3, 'gi'),
          DocumentKeywords.resample2
        )
        .replace(
          new RegExp(DocumentKeywords.highRisk2, 'gi'),
          DocumentKeywords.highRisk1
        );

      const withoutExtension = fileName.substring(0, fileName.length - 4);

      const gpIndex = withoutExtension.toLowerCase().indexOf('_geneplanet');

      const beforeGp = withoutExtension.substring(0, gpIndex);
      const afterGp = withoutExtension.substring(gpIndex);

      const afterGpSplit = afterGp.split('-');

      // last index is always type, therefore pop()
      let type =
        afterGpSplit.length > 1
          ? afterGpSplit.pop().toLowerCase()
          : 'official report';

      // If Unknown type, we assume it's a type of "High risk"
      if (
        !NonOfficialDocumentReportTypes.some((reportType) =>
          type.includes(reportType)
        ) &&
        !type.includes(DocumentKeywords.official)
      ) {
        type = `${DocumentKeywords.highRisk1}-${type}`;
      }
      const fileExtension = fileName.split('.').pop();

      return `${beforeGp}${afterGpSplit.join('-')}-${type}.${fileExtension}`
        .split('_')
        .join('-');
    }
    return file.name;
  }

  prepareBlankReport(item: ExtendedProductInventoryItem) {
    let documentName = '';
    this.productInventoryItemUtils
      .getExtendedProductInventoryItem(item, {
        product: true,
        sample: { doctor: true, serviceData: {}, kit: true },
        partner: true,
      })
      .pipe(first())
      .subscribe((item) => {
        const analysisType = item.product.analysisType;
        const brand =
          analysisType !== AnalysisType.MONO
            ? item.product.brand
            : ServiceType.NIPT;
        const language = this.store.selectSnapshot(
          LanguageState.getLanguageById
        )(item.partner.languageIds[0]).name;
        const translation = translations[language];

        documentName = `${item.sample.kitId}-${item.sample.serviceData.patientInformation.firstName} ${item.sample.serviceData.patientInformation.lastName}-GENEPLANET-AnalysisType-${analysisType}-ReportResult-{{ResultHere}}-Official report.docx`;

        this.http
          .get(
            `/assets/blank-reports/${analysisType}/${analysisType}_${language}_${brand}.docx`,
            {
              responseType: 'blob',
            }
          )
          .subscribe((data: Blob) => {
            patchDocument({
              outputType: 'blob',
              data,
              patches: {
                patient: {
                  children: [new TextRun(translation['patient'])],
                  type: PatchType.PARAGRAPH,
                },
                patientNameText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['patientNameText'])],
                },
                patientName: {
                  type: PatchType.PARAGRAPH,
                  children: [
                    new TextRun(
                      `${item.sample.serviceData.patientInformation.firstName} ${item.sample.serviceData.patientInformation.lastName}`
                    ),
                  ],
                },
                patientIdCardText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['patientIdCardText'])],
                },
                documentNumber: {
                  type: PatchType.PARAGRAPH,
                  children: [
                    new TextRun(
                      item.sample.serviceData.patientInformation.documentNumber
                    ),
                  ],
                },
                patientAgeText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['patientAgeText'])],
                },
                age: {
                  type: PatchType.PARAGRAPH,
                  children: [
                    new TextRun(
                      moment()
                        .diff(
                          item.sample.serviceData.patientInformation
                            .dateOfBirth,
                          'years'
                        )
                        .toString()
                    ),
                  ],
                },
                gestationalWeeksText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['gestationalWeeksText'])],
                },
                gestationalWeeks: {
                  type: PatchType.PARAGRAPH,
                  children: [
                    new TextRun(
                      `${item.sample.serviceInformation.nipt.gestationAge.week}W` +
                        (item.sample.serviceInformation.nipt.gestationAge.day
                          ? `+${item.sample.serviceInformation.nipt.gestationAge.day}`
                          : '')
                    ),
                  ],
                },
                dueDateText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['dueDateText'])],
                },
                dueDate: {
                  type: PatchType.PARAGRAPH,
                  children: [
                    new TextRun(
                      moment(
                        item.sample.serviceData.serviceInformation.nipt
                          .currentPregnancyInformation.expectedConfinementDate
                      ).format('DD.MM.YYYY')
                    ),
                  ],
                },
                sample: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['sample'])],
                },
                sampleIdText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['sampleIdText'])],
                },
                kitId: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(item.sample.kitId)],
                },
                doctorNameText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['doctorNameText'])],
                },
                doctorName: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(item.sample.doctor.name)],
                },
                venipunctureDateText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['venipunctureDateText'])],
                },
                venipunctureDate: {
                  type: PatchType.PARAGRAPH,
                  children: [
                    new TextRun(
                      moment(item.sample.dateOfVenipuncture).format(
                        'DD.MM.YYYY'
                      )
                    ),
                  ],
                },
                receivedDateText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['receivedDateText'])],
                },
                receivedDate: {
                  type: PatchType.PARAGRAPH,
                  children: [
                    new TextRun(
                      moment(item.sample.kit.returned?.timestamp).format(
                        'DD.MM.YYYY'
                      )
                    ),
                  ],
                },
                sampleTypeText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['sampleTypeText'])],
                },
                sampleType: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['sampleType'])],
                },
                approvedBy: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['approvedBy'])],
                },
                datedText: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(translation['datedText'])],
                },
                dated: {
                  type: PatchType.PARAGRAPH,
                  children: [new TextRun(moment().format('DD.MM.YYYY'))],
                },
              },
            }).then(
              (finaldata) => {
                const blob = new Blob([finaldata], {
                  type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });
                // Now work with the data, e.g. download it.
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = documentName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
              },
              () => {
                console.error('Nope.');
              }
            );
          });
      });
  }
}
