import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NIPTSignee } from '../../core.types';
import { Logger } from '../../services/logger.service';
import { SigneeService } from '../../services/signee.service';
import { ClearSigneeList, LoadSigneesAction } from './signees.actions';
import { AccountUtilsService } from '@core/services/account-utils.service';
import { first, switchMap, tap } from 'rxjs/operators';

const SIGNEE_STATE_NAME = 'signee';

export interface SigneeStateModel {
  signee: NIPTSignee[];
}

@State<SigneeStateModel>({
  name: SIGNEE_STATE_NAME,
  defaults: {
    signee: [],
  },
})
@Injectable()
export class SigneeState {
  private log = new Logger(this.constructor.name);

  constructor(
    private signeeService: SigneeService,
    private accountUtils: AccountUtilsService
  ) {}

  @Selector()
  static getSigneeList(state: SigneeStateModel) {
    return state.signee;
  }

  @Action(ClearSigneeList)
  clearSigneeList({ setState }: StateContext<SigneeStateModel>) {
    return setState({
      signee: [],
    });
  }
  @Action(LoadSigneesAction)
  loadSigneeAction({ patchState }: StateContext<SigneeStateModel>) {
    this.log.debug('load Signee');

    return this.accountUtils.getLaboratory$().pipe(
      switchMap((laboratory) =>
        this.signeeService.getSigneeList(
          laboratory ? { LaboratoryNames: laboratory } : {}
        )
      ),
      first(),
      tap((signeList) => {
        patchState({ signee: signeList });
      })
    );
  }
}
