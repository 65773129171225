import { AsyncPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PdfIframeInlineComponent } from '@shared/components/pdf-iframe-inline/pdf-iframe-inline.component';
import { BehaviorSubject, Observable, catchError, first, of, tap } from 'rxjs';

@Component({
  selector: 'app-document-preview',
  imports: [TranslateModule, PdfIframeInlineComponent, AsyncPipe],
  templateUrl: './document-preview.component.html',
  styleUrl: './document-preview.component.scss',
})
export class DocumentPreviewComponent implements OnChanges {
  @Input() uri: string;

  protected isError$ = new BehaviorSubject<boolean>(false);
  protected pdfIframeUrl$ = new BehaviorSubject<string>('');
  protected initialLoad = false;
  protected isDocumentLoaded = true;

  constructor(private httpClient: HttpClient) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uri && this.uri) {
      this.showPDF(this.uri).subscribe();
    } else {
      this.pdfIframeUrl$.next(null);
    }
  }

  private showPDF(uri: string): Observable<Blob> {
    this.initialLoad = true;
    this.isDocumentLoaded = false;
    this.isError$.next(false);

    return this.httpClient.get(uri, { responseType: 'blob' }).pipe(
      first(),
      catchError(() => {
        this.isError$.next(true);
        return of(null);
      }),
      tap(() => {
        this.pdfIframeUrl$.next(uri);
        this.isDocumentLoaded = true;
      })
    );
  }
}
