import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ProductInventoryItemDetailsComponent } from '@app/modules/service-data/components/product-inventory-item/product-inventory-item-details/product-inventory-item-details.component';
import {
  ExtendedProductInventoryItem,
  ExtendedSample,
  Sample,
} from '@app/modules/service-data/service-data.types';
import { redirectToDhl } from '@app/modules/service-data/service-data.utils';
import { ProductInventoryItemUtilsService } from '@app/modules/service-data/services/product-inventory-item-utils.service';
import { SampleUtilsService } from '@app/modules/service-data/services/sample-utils.service';
import { ServiceDataUtilsService } from '@app/modules/service-data/services/service-data-utils.service';
import { GetProductInventoryItemListAction } from '@app/modules/service-data/store/product-intentory-item.actions';
import { ProductInventoryItemState } from '@app/modules/service-data/store/product-inventory-item.state';
import { GetSampleAction } from '@app/modules/service-data/store/sample.actions';
import { SampleState } from '@app/modules/service-data/store/sample.state';
import { ProductInventory } from '@core/core.types';
import { AuthenticationService } from '@core/services/authentication.service';
import { RunState } from '@core/store/runs/runs.state';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbDialogService,
  NbIconModule,
  NbSelectModule,
  NbTooltipModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DataTableComponent } from '@shared/components/data-table/data-table.component';
import {
  DataTableActionsLocation,
  DataTableActionType,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { RequirePermissionDirective } from '@shared/directives/require-permission.directive';
import { Observable, Subject, switchMap } from 'rxjs';
import { filter, first, map, shareReplay } from 'rxjs/operators';
import { DocumentPreviewComponent } from '../../document-preview/product-inventory-item-document-preview/document-preview.component';
import { SampleDetailsDhlWaybillComponent } from '../sample-details-dhl-waybill/sample-details-dhl-waybill.component';

@Component({
  selector: 'app-sample-details',
  imports: [
    AsyncPipe,
    DatePipe,
    TranslateModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbSelectModule,
    NbTooltipModule,
    DocumentPreviewComponent,
    SampleDetailsDhlWaybillComponent,
    NgClass,
    DataTableComponent,
    RequirePermissionDirective,
  ],
  templateUrl: './sample-details.component.html',
  styleUrl: './sample-details.component.scss',
})
export class SampleDetailsComponent implements OnInit {
  @Input()
  public sampleId: string;

  protected sample$: Observable<ExtendedSample>;
  protected previousSample$: Observable<Sample>;
  protected nextSample$: Observable<Sample>;
  protected onShowPdfSubject$ = new Subject<string>();
  protected piisDataTableConfiguration: DataTableConfiguration;
  protected runs$: Observable<string>;
  protected documentPreviewPermission$: Observable<boolean>;

  private piis$: Observable<ExtendedProductInventoryItem[]>;

  constructor(
    private store: Store,
    public dialogRef: NbDialogRef<SampleDetailsComponent>,
    private sampleUtils: SampleUtilsService,
    private nbDialogService: NbDialogService,
    private productInventoryItemUtils: ProductInventoryItemUtilsService,
    private serviceDataUtilsService: ServiceDataUtilsService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.documentPreviewPermission$ = this.authService.hasRequiredPermission$(
      'serviceData.details.sample.details.waybill'
    );

    this.sample$ = this.store.dispatch(new GetSampleAction(this.sampleId)).pipe(
      switchMap(() =>
        this.store
          .select(SampleState.getSampleById)
          .pipe(map((getById) => getById(this.sampleId)))
      ),
      switchMap((sample) =>
        this.sampleUtils.getExtendedSample(sample, {
          doctor: true,
          product: true,
          serviceData: { bundles: true },
          partner: true,
          kit: true,
        })
      ),
      first(),
      shareReplay(1)
    );

    this.previousSample$ = this.sample$.pipe(
      switchMap((sample) =>
        this.store.dispatch(new GetSampleAction(sample?.previousSampleId)).pipe(
          switchMap(() =>
            this.store.select(SampleState.getSampleById).pipe(
              map((getById) => getById(sample.previousSampleId)),
              filter((sample) => !!sample)
            )
          )
        )
      )
    );

    this.nextSample$ = this.sample$.pipe(
      switchMap((sample) =>
        this.store.dispatch(new GetSampleAction(sample.nextSampleId)).pipe(
          switchMap(() =>
            this.store.select(SampleState.getSampleById).pipe(
              map((getById) => getById(sample.nextSampleId)),
              filter((sample) => !!sample)
            )
          )
        )
      )
    );

    this.runs$ = this.sample$.pipe(
      switchMap((sample) =>
        this.store
          .select(RunState.getRunsByKitId)
          .pipe(map((getByKitId) => getByKitId(sample.kitId)))
      ),
      map((runs) => runs.map((run) => run.name).join(', '))
    );

    this.piis$ = this.sample$.pipe(
      switchMap((sample: Sample) =>
        this.store
          .dispatch(
            new GetProductInventoryItemListAction(
              { ids: sample.productInventoryItems },
              null,
              false
            )
          )
          .pipe(map(() => sample))
      ),
      switchMap((sample: Sample) =>
        this.store
          .select(ProductInventoryItemState.getProductInventoryItemsByIds)
          .pipe(map((getByIds) => getByIds(sample.productInventoryItems)))
      ),
      switchMap((piis: ProductInventory[]) =>
        this.productInventoryItemUtils.getExtendedProductInventoryItemList(piis)
      ),
      first()
    );

    this.piisDataTableConfiguration = {
      title: '',
      rows$: this.piis$,
      selectionMode: DataTableSelectionType.NONE,
      actionsLocation: DataTableActionsLocation.RIGHT,
      columns: [
        {
          label: 'Product',
          property: 'product.name',
        },
        {
          label: 'Status',
          property: 'lastStatus',
        },
      ],
      singleActions: [
        {
          name: 'Product inventory item details',
          icon: 'file-outline',
          type: DataTableActionType.BUTTON,
          action: (event, pii: ExtendedProductInventoryItem) => {
            this.nbDialogService.open(ProductInventoryItemDetailsComponent, {
              context: {
                piiId: pii.id,
              },
            });
          },
        },
      ],
    };
  }

  protected openServiceDataDetails(serviceDataId: string) {
    this.serviceDataUtilsService.openDetails(serviceDataId);
  }

  protected openSample(sampleId: string) {
    this.sampleUtils.openDetails(sampleId);
  }

  protected editSample(sampleId: string, serviceDataId: string) {
    this.sampleUtils.openEdit(sampleId, serviceDataId);
  }

  protected readonly redirectToDhl = redirectToDhl;
}
