<div class="row form-group">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="numberOfPregnancies">{{
          'serviceData.form.numberOfPregnancies' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          type="number"
          formControlName="numberOfPregnancies"
          id="numberOfPregnancies"
          appInputValidationStatus
          [placeholder]="'serviceData.form.placeholders.gravida' | translate"
          data-cy="gravida-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="numberOfPregnancies"
          translations="serviceData.form.validation.gravida"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="numberOfBirths">{{
          'serviceData.form.numberOfBirths' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          type="number"
          formControlName="numberOfBirths"
          id="numberOfBirths"
          appInputValidationStatus
          [placeholder]="'serviceData.form.placeholders.para' | translate"
          data-cy="para-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="numberOfBirths"
          translations="serviceData.form.validation.para"
        ></app-validation-error>
      </div>
    </div>
  </div>
</div>

<ng-container formGroupName="pregnancyDiseaseHistory">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="previousPregnancies">{{
            'serviceData.form.previousPregnancies' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="previousPregnancies"
            name="previousPregnancies"
            class="radio-toolbar"
            formControlName="hasHistory"
            data-cy="previous-pregnancies-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasHistory"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
  </div>
  @if (form.get('pregnancyDiseaseHistory.hasHistory').value) {
    <div class="row">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="col-2">
            <label for="pregnancyDiseaseHistory">{{
              'serviceData.form.pregnancyDiseaseHistory' | translate
            }}</label>
          </div>
          <div class="col-9">
            <div class="d-flex">
              <input
                fullWidth
                nbInput
                id="pregnancyDiseaseHistory"
                [formControl]="pregnancyDiseaseControl"
                [placeholder]="
                  'serviceData.form.placeholders.diseaseInformation' | translate
                "
                data-cy="pregnancy-disease-history-input"
              />
            </div>
            <app-validation-error
              translations="validation.diseaseHistory"
            ></app-validation-error>
          </div>
          <div class="col-1">
            <button
              nbButton
              nbTooltip="{{
                'serviceData.form.tooltips.addPregnancyDisease' | translate
              }}"
              nbTooltipPlacement="left"
              status="primary"
              [disabled]="!pregnancyDiseaseControl.value?.trim()"
              (click)="
                addDisease(
                  'pregnancyDiseaseHistory.diseases',
                  pregnancyDiseaseControl
                )
              "
              data-cy="add-pregnancy-disease-btn"
            >
              <nb-icon icon="plus-outline" />
            </button>
          </div>
        </div>
        <div class="row justify-content-end form-group">
          <div class="col-10">
            <app-data-table [configuration]="pregnancyDiseaseConfiguration" />
          </div>
        </div>
      </div>
    </div>
  }
</ng-container>

<ng-container formGroupName="familyDiseaseHistory">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="familyGeneticDiseaseHistory">{{
            'serviceData.form.familyGeneticDiseaseHistory' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="familyGeneticDiseaseHistory"
            name="familyGeneticDiseaseHistory"
            class="radio-toolbar"
            formControlName="hasHistory"
            data-cy="family-genetic-disease-history-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasHistory"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
  </div>
  @if (form.get('familyDiseaseHistory.hasHistory').value) {
    <div class="row">
      <div class="col-2">
        <label for="familyDiseaseConditionDescription">{{
          'serviceData.form.familyDiseaseHistory' | translate
        }}</label>
      </div>
      <div class="col-9">
        <div class="row">
          <div class="col-6">
            <div class="row align-items-center form-group">
              <div class="col-12">
                <div class="d-flex">
                  <input
                    fullWidth
                    nbInput
                    id="familyDiseaseConditionDescription"
                    [formControl]="
                      familyDiseaseGroup.get('conditionDescription')
                    "
                    [placeholder]="
                      'serviceData.form.placeholders.diseaseInformation'
                        | translate
                    "
                    data-cy="family-disease-condition-description-input"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 gr-0">
            <div class="row align-items-center justify-content-end">
              <div class="col-12">
                <div class="d-flex">
                  <input
                    fullWidth
                    nbInput
                    id="familyDiseaseRelationToPatient"
                    [formControl]="familyDiseaseGroup.get('relationToPatient')"
                    [placeholder]="
                      'serviceData.form.placeholders.relationToPatient'
                        | translate
                    "
                    data-cy="family-disease-patient-relation-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-validation-error
          translations="validation.diseaseHistory"
        ></app-validation-error>
      </div>
      <div class="col-1">
        <button
          nbButton
          nbTooltip="{{
            'serviceData.form.tooltips.addFamilyDisease' | translate
          }}"
          nbTooltipPlacement="left"
          status="primary"
          [disabled]="
            !familyDiseaseGroup.get('conditionDescription').value?.trim() ||
            !familyDiseaseGroup.get('relationToPatient').value?.trim()
          "
          (click)="addFamilyDisease()"
          data-cy="add-family-disease-btn"
        >
          <nb-icon icon="plus-outline" />
        </button>
      </div>
    </div>
    <div class="row justify-content-end form-group">
      <div class="col-10">
        <app-data-table [configuration]="familyDiseaseConfiguration" />
      </div>
    </div>
  }
</ng-container>

<ng-container formGroupName="carrierOfGeneticDiseases">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="isPatientCarrierOfGeneticDiseases">{{
            'serviceData.form.isPatientCarrierOfGeneticDiseases' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="isPatientCarrierOfGeneticDiseases"
            name="isPatientCarrierOfGeneticDiseases"
            class="radio-toolbar"
            formControlName="hasHistory"
            data-cy="genetic-disease-carrier-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasHistory"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
  </div>
  @if (form.get('carrierOfGeneticDiseases.hasHistory').value) {
    <div class="row">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="col-2">
            <label for="carrierOfGeneticDiseases">{{
              'serviceData.form.carrierOfGeneticDiseases' | translate
            }}</label>
          </div>
          <div class="col-9">
            <div class="d-flex">
              <input
                fullWidth
                nbInput
                id="carrierOfGeneticDiseases"
                [formControl]="geneticConditionControl"
                [placeholder]="
                  'serviceData.form.placeholders.diseaseInformation' | translate
                "
                data-cy="genetic-condition-carrier-input"
              />
            </div>

            <app-validation-error
              translations="validation.diseaseHistory"
            ></app-validation-error>
          </div>
          <div class="col-1">
            <button
              nbButton
              nbTooltip="{{
                'serviceData.form.tooltips.addGeneticCondition' | translate
              }}"
              nbTooltipPlacement="left"
              status="primary"
              [disabled]="!geneticConditionControl.value?.trim()"
              (click)="
                addDisease(
                  'carrierOfGeneticDiseases.diseases',
                  geneticConditionControl
                )
              "
              data-cy="add-genetic-condition-btn"
            >
              <nb-icon icon="plus-outline" />
            </button>
          </div>
        </div>
        <div class="row justify-content-end form-group">
          <div class="col-10">
            <app-data-table [configuration]="geneticConditionConfiguration" />
          </div>
        </div>
      </div>
    </div>
  }
</ng-container>

<ng-container formGroupName="cancerHistory">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="cancerHistory">{{
            'serviceData.form.cancerHistory' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="cancerHistory"
            name="cancerHistory"
            class="radio-toolbar"
            formControlName="hasHistory"
            data-cy="cancer-history-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasHistory"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
    <div class="col-6 align-self-center">
      @if (form.get('cancerHistory.hasHistory').value) {
        <div class="row align-items-center">
          <div class="col-4">
            <label for="historyOfCancerStatus">
              {{
                'serviceData.form.historyOfCancerStatus.status' | translate
              }}</label
            >
          </div>
          <div class="col-8">
            <nb-radio-group
              id="historyOfCancerStatus"
              name="historyOfCancerStatus"
              class="radio-toolbar align-items-center"
              formControlName="status"
              data-cy="cancer-history-status-rg"
            >
              <nb-radio [value]="cancerHistoryStatus.Recovered">{{
                'serviceData.form.historyOfCancerStatus.recovered' | translate
              }}</nb-radio>
              <nb-radio [value]="cancerHistoryStatus.CurrentlyAffected">{{
                'serviceData.form.historyOfCancerStatus.currentlyAffected'
                  | translate
              }}</nb-radio>
            </nb-radio-group>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-8">
            <app-validation-error
              for="status"
              translations="serviceData.form.validation.cancerHistory"
            ></app-validation-error>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>

@if (isCancerRecovered$ | async) {
  <div class="row form-group justify-content-end">
    <div class="notes col-10">
      {{ 'serviceData.form.cancerRecovery' | translate }}
    </div>
  </div>
}

<ng-container formGroupName="albuminTherapy">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="albuminTherapy">{{
            'serviceData.form.albuminTherapy' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="albuminTherapy"
            name="albuminTherapy"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="albumin-therapy-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasReceived"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
    <div class="col-6 align-self-center">
      @if (form.get('albuminTherapy.hasReceived').value) {
        <div class="row align-items-center">
          <div class="col-4">
            <label for="albuminTherapyRelevant">{{
              'serviceData.form.albuminTherapyRelevant' | translate
            }}</label>
          </div>
          <div class="col-8">
            <nb-radio-group
              id="albuminTherapyRelevant"
              name="albuminTherapyRelevant"
              class="radio-toolbar"
              formControlName="isRelevant"
              data-cy="albumin-therapy-relevant-rg"
            >
              <nb-radio [value]="false">{{
                'common.yes' | translate
              }}</nb-radio>
              <nb-radio [value]="true">{{ 'common.no' | translate }}</nb-radio>
            </nb-radio-group>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-8">
            <app-validation-error
              for="isRelevant"
              translations="serviceData.form.validation.albuminTherapy"
            ></app-validation-error>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>

<ng-container formGroupName="cellularImmunotherapy">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="cellularImmunotherapy">{{
            'serviceData.form.cellularImmunotherapy' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="cellularImmunotherapy"
            name="cellularImmunotherapy"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="cellular-immunotherapy-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasReceived"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
    <div class="col-6 align-self-center">
      @if (form.get('cellularImmunotherapy.hasReceived').value) {
        <div class="row align-items-center">
          <div class="col-4">
            <label for="cellularImmunotherapyRelevant">{{
              'serviceData.form.cellularImmunotherapyRelevant' | translate
            }}</label>
          </div>
          <div class="col-8">
            <nb-radio-group
              id="cellularImmunotherapyRelevant"
              name="cellularImmunotherapyRelevant"
              class="radio-toolbar"
              formControlName="isRelevant"
              data-cy="cellular-immunotherapy-relevant-rg"
            >
              <nb-radio [value]="false">{{
                'common.yes' | translate
              }}</nb-radio>
              <nb-radio [value]="true">{{ 'common.no' | translate }}</nb-radio>
            </nb-radio-group>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-8">
            <app-validation-error
              for="isRelevant"
              translations="serviceData.form.validation.cellularImmunotherapy"
            ></app-validation-error>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>

<ng-container formGroupName="stemCellTherapy">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="stemCellTherapy">{{
            'serviceData.form.stemCellTherapy' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="stemCellTherapy"
            name="stemCellTherapy"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="stem-cell-therapy-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasReceived"
            translations="serviceData.form.validation.stemCellTherapy"
          ></app-validation-error>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container formGroupName="transplantSurgery">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="transplantSurgery">{{
            'serviceData.form.transplantSurgery' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="transplantSurgery"
            name="transplantSurgery"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="transplant-surgery-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasReceived"
            translations="serviceData.form.validation.transplantSurgery"
          ></app-validation-error>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container formGroupName="bloodTransfusion">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="bloodTransfusion">{{
            'serviceData.form.bloodTransfusion' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="bloodTransfusion"
            name="bloodTransfusion"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="blood-transfusion-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasReceived"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
    <div class="col-6 align-self-center">
      @if (form.get('bloodTransfusion.hasReceived').value) {
        <div class="row align-items-center">
          <div class="col-4">
            <label for="bloodTransfusionRelevant">{{
              'serviceData.form.bloodTransfusionRelevant' | translate
            }}</label>
          </div>
          <div class="col-8">
            <nb-radio-group
              id="bloodTransfusionRelevant"
              name="bloodTransfusionRelevant"
              class="radio-toolbar"
              formControlName="isRelevant"
              data-cy="blood-transfusion-relevant-rg"
            >
              <nb-radio [value]="false">{{
                'common.yes' | translate
              }}</nb-radio>
              <nb-radio [value]="true">{{ 'common.no' | translate }}</nb-radio>
            </nb-radio-group>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-8">
            <app-validation-error
              for="isRelevant"
              translations="serviceData.form.validation.bloodTransfusion"
            ></app-validation-error>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>
