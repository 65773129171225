import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DOCTORS_PARTNER_TYPES } from '@app/app.constants';
import { PartnersUtilsService } from '@app/modules/partners/partners-utils.service';
import { Partner, PartnerType } from '@core/core.types';
import { AuthenticationService } from '@core/services/authentication.service';
import { PartnerTypeState } from '@core/store/partner-types/partner-types.state';
import { LoadPartnersByIds } from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import { NbInputModule, NbSelectModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { PartnerTypeaheadComponent } from '@shared/components/partner-typeahead/partner-typeahead.component';
import { ValidationErrorComponent } from '@shared/components/validation-error/validation-error.component';
import { InputValidationStatusDirective } from '@shared/directives/input-validation-status.directive';
import { controlContainerProvider } from '@shared/helpers';
import { Observable, filter, first, map, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'app-service-data-form-service-information',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NbSelectModule,
    AsyncPipe,
    TranslateModule,
    ValidationErrorComponent,
    InputValidationStatusDirective,
    NbInputModule,
    PartnerTypeaheadComponent,
  ],
  templateUrl: './service-data-form-service-information.component.html',
  viewProviders: [controlContainerProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDataFormServiceInformationComponent implements OnInit {
  @Input() id: string;

  protected doctors$: Observable<Partner[]>;

  protected get form() {
    return this.controlContainer.control as FormGroup;
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private partnersUtilsService: PartnersUtilsService,
    private store: Store,
    private controlContainer: ControlContainer,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.doctors$ = this.getDoctors();

    this.form
      .get('partnerId')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.form.get('bundleIds').patchValue([]);
        this.form
          .get('serviceInformation.nipt.doctorInformation.doctorId')
          .patchValue(null);
        this.form.get('paymentInformation.paymentType').reset();
      });
  }

  private getDoctors(): Observable<Partner[]> {
    return this.form.get('partnerId').valueChanges.pipe(
      startWith(this.form.get('partnerId').value),
      takeUntilDestroyed(this.destroyRef),
      filter((partnerId: string) => !!partnerId),
      switchMap((partnerId: string) =>
        this.partnersUtilsService.getPartnerById$(partnerId)
      ),
      filter((partner: Partner) => !!partner),
      switchMap((partner: Partner) =>
        this.store.dispatch(new LoadPartnersByIds(partner.subPartners)).pipe(
          switchMap(() =>
            this.store
              .select(PartnersState.getPartnersByIds)
              .pipe(map((findByIds) => findByIds(partner.subPartners)))
          ),
          switchMap((doctors: Partner[]) =>
            this.store.select(PartnerTypeState.getPartnerTypesList).pipe(
              map((partnerTypesList: PartnerType[]) => {
                const medicalAdministrator = partnerTypesList.find(
                  (partnerType: PartnerType) =>
                    partnerType.type ===
                    DOCTORS_PARTNER_TYPES.medicalAdministrator
                );

                return doctors.filter(
                  (doctor: Partner) =>
                    doctor?.partnerTypeId !== medicalAdministrator.id &&
                    !doctor?.deactivated
                );
              })
            )
          ),
          switchMap((doctors: Partner[]) =>
            this.authService
              .hasRequiredPermission$('serviceData.edit.editDoctorId')
              .pipe(
                first(),
                map((hasPermission: boolean) => {
                  if (hasPermission || !this.id) {
                    this.form
                      .get('serviceInformation.nipt.doctorInformation.doctorId')
                      .enable();
                  }

                  return doctors;
                })
              )
          )
        )
      )
    );
  }
}
