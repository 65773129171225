import { Invoice, InvoicePayment } from '../../core.types';

export const PAYMENT_INVOICES_STATE_NAME = 'invoices';
export class LoadInvoicesByIdsAction {
  static type = '[Invoices] LoadInvoicesByIdsAction';

  constructor(public ids: string[]) {}
}

export class AddInvoiceAction {
  static type = '[Invoices] AddInvoiceAction';

  constructor(public invoice: Invoice) {}
}

export class LoadInvoiceById {
  static type = '[Invoices] LoadInvoiceById';

  constructor(
    public invoiceId: string,
    public refresh = false
  ) {}
}

export class LoadInvoicesPaymentsListAction {
  static type = '[Invoices] LoadInvoicesPaymentsListAction';

  constructor(
    public invoiceId: string,
    public refresh = false
  ) {}
}

export class UpdateInvoiceAction {
  static type = '[Invoices] UpdateInvoiceAction';

  constructor(
    public invoiceId: string,
    public sepaContract: boolean
  ) {}
}
export class MarkInvoicePaymentAsPaidAction {
  static type = '[Invoices] MarkInvoicePaymentAsPaidAction';

  constructor(
    public invoiceId: string,
    public invoicePaymentId: string
  ) {}
}

export class AddInvoicePaymentsAction {
  static type = '[Invoices] AddInvoicePaymentsAction';

  constructor(
    public invoiceId: string,
    public invoicePaymentsList: InvoicePayment[]
  ) {}
}

export class RemoveInvoicePaymentAction {
  static type = '[Invoices] RemoveInvoicePaymentAction';

  constructor(
    public invoiceId: string,
    public paymentId: string
  ) {}
}

export class AssignInvoicePaymentsToInvoiceAction {
  static type = '[Invoices] AssignInvoicePaymentsToInvoiceAction';

  constructor(
    public invoiceId: string,
    public invoicePayment: InvoicePayment
  ) {}
}

export class UpdateInvoicePaymentAction {
  static type = '[Invoices] UpdateInvoicePaymentAction';

  constructor(
    public invoiceId: string,
    public invoicePayment: InvoicePayment
  ) {}
}

export class SetLaboratoryPriceAction {
  static type = '[Invoices] SetLaboratoryPriceAction';

  constructor(
    public invoiceId: string,
    public laboratoryPrice: number
  ) {}
}

export class SetDiscountAction {
  static type = '[Invoices] SetDiscountAction';

  constructor(
    public invoiceId: string,
    public discountValue: number
  ) {}
}

export class MarkAsPaidAction {
  static type = '[Invoices] MarkAsPaidAction';

  constructor(public invoiceId: string) {}
}

export class RevokeInvoicePaid {
  static type = '[Invoices] RevokeInvoicePaid';

  constructor(public invoiceId: string) {}
}
