import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  RUNS_PAGE_SORT_SETTINGS,
  RUNS_STATE_NAME,
} from '@shared/constants/runs.constants';
import { isEqual } from 'lodash';
import { EMPTY, skipUntil } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { delay, filter, first, switchMap, tap } from 'rxjs/operators';
import { Pagination, Run, StatePageSettings } from '../../core.types';
import { ExportersService } from '../../services/exporters.service';
import { Logger } from '../../services/logger.service';
import { RunFilters, RunsService } from '../../services/runs.service';
import { SetKitStateReturnedAction } from '../kits/kits.actions';
import {
  AddNewRunAction,
  AssignKitToRunAction,
  AssignKitsToRunAction,
  ChangeRunStatusAction,
  ClearRunsStateAction,
  DeleteRunAction,
  LoadAllRunsAction,
  LoadRunById,
  LoadRunsAction,
  RemoveKitFromRunAction,
  SetRunsById,
  UpdateRunAction,
} from './runs.actions';
import { SigneeState } from '@core/store/signees/signees.state';

export const RUNS_LIST_PAGE_SIZE = 30;

export interface RunsStateModel {
  runs: { [id: string]: Run };
  pages?: {
    [key: string]: {
      items: string[];
      continuationToken: string;
    };
  };
  currentPageSettings: StatePageSettings<RunFilters>;
  totalCount?: number;
  allRunsLoaded: boolean;
}

const RUNS_DEFAULT_STATE: RunsStateModel = {
  runs: {},
  pages: {},
  currentPageSettings: {
    pageIndex: 0,
    sortSettings: {
      orderBy: RUNS_PAGE_SORT_SETTINGS.orderBy,
      sortDirection: RUNS_PAGE_SORT_SETTINGS.sortDirection,
      pageSize: RUNS_LIST_PAGE_SIZE,
    },
    filterSettings: {},
  },
  totalCount: 0,
  allRunsLoaded: false,
};

@State<RunsStateModel>({
  name: RUNS_STATE_NAME,
  defaults: {
    ...RUNS_DEFAULT_STATE,
  },
})
@Injectable()
export class RunState {
  private readonly logger = new Logger(this.constructor.name);

  constructor(
    protected runsService: RunsService,
    protected exportersService: ExportersService,
    protected store: Store
  ) {}

  @Selector()
  static getCurrentPage(state: RunsStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;
    const pagesLength = Object.keys(pages).length;
    if (pagesLength === 0 || pageIndex > pagesLength || pageIndex < 0) {
      return [];
    }
    return [...state.pages[pageIndex].items];
  }

  @Selector()
  static getPaginationInfo({
    currentPageSettings,
    totalCount,
    pages,
  }: // eslint-disable-next-line @typescript-eslint/ban-types
  RunsStateModel): Pagination<{}> {
    return {
      currentPageSettings: {
        ...currentPageSettings,
      },
      totalCount,
      pagesSize: Object.keys(pages).length,
      collectionSize: RUNS_LIST_PAGE_SIZE,
    };
  }

  @Selector()
  static getAllRuns(state: RunsStateModel) {
    if (state.allRunsLoaded && state.runs) {
      return Object.values(state.runs);
    }
    return null;
  }

  @Selector()
  static getRunsByKitId({ runs }: RunsStateModel) {
    return (kitId: string) => {
      if (!runs) {
        return [];
      }
      return Object.keys(runs)
        .map((key) => {
          const run = runs[key];
          if (
            run &&
            run.kits &&
            run.kits.findIndex((k) => k.kitId === kitId) > -1
          ) {
            return runs[key];
          }
          return null;
        })
        .filter((r) => r !== null);
    };
  }

  @Selector()
  static getRunById({ runs }: RunsStateModel) {
    return (runId: string) => (runs ? runs[runId] : null);
  }

  @Selector()
  static getRunsByIds({ runs }: RunsStateModel) {
    return (runIds: string[]) => runIds.map((id) => runs[id]);
  }

  @Action(ClearRunsStateAction)
  clearRunState({ patchState }: StateContext<RunsStateModel>) {
    this.logger.debug('clearRunState');
    const { currentPageSettings, pages, totalCount } = RUNS_DEFAULT_STATE;
    patchState({
      currentPageSettings,
      pages,
      totalCount,
      allRunsLoaded: false,
    });
  }

  @Action(AddNewRunAction)
  addNewRun(
    { getState, patchState, dispatch }: StateContext<RunsStateModel>,
    { payload: newRun }: AddNewRunAction
  ) {
    return this.runsService.generateRun(newRun).pipe(
      delay(300),
      switchMap((response) =>
        dispatch(new LoadRunById(response.id, true, true))
      )
    );
  }

  @Action(UpdateRunAction)
  updateRun(
    { getState, patchState, dispatch }: StateContext<RunsStateModel>,
    { payload: { run } }: UpdateRunAction
  ) {
    return this.runsService.updateRun(run).pipe(
      switchMap(() => {
        const storedRun = getState().runs[run.id];
        if (storedRun) {
          return dispatch(new SetRunsById({ ...storedRun, ...run }));
        }
        return of(null);
      })
    );
  }

  @Action(AssignKitToRunAction)
  assignKitToRun(
    { getState, patchState, dispatch }: StateContext<RunsStateModel>,
    { payload: { runId, kitId } }: AssignKitToRunAction
  ) {
    return this.runsService.addKitToRun(runId, kitId).pipe(
      switchMap(() => {
        const run = { ...getState().runs[runId] };
        run.kits = [
          ...run.kits,
          { kitId, position: Object.keys(getState().runs).length },
        ];
        patchState({
          runs: { ...getState().runs, [runId]: run },
        });
        return of();
      })
    );
  }

  @Action(RemoveKitFromRunAction)
  removeKitFromRun(
    { getState, patchState, dispatch }: StateContext<RunsStateModel>,
    { payload: { runId, kitId } }: RemoveKitFromRunAction
  ) {
    return this.runsService.removeKitFromRun(runId, kitId).pipe(
      switchMap(() => {
        const run = { ...getState().runs[runId] };
        run.kits = run.kits.filter((kit) => kit.kitId !== kitId);
        patchState({
          runs: { ...getState().runs, [runId]: run },
        });
        return of();
      })
    );
  }

  @Action(AssignKitsToRunAction)
  async addKitsToRun(
    { getState, patchState }: StateContext<RunsStateModel>,
    action: AssignKitsToRunAction
  ) {
    this.logger.debug('AssignKitsToRunAction');
    const runId = action.payload.runId;
    const newRunKitsList = action.payload.runKitsList.reverse();
    const date = action.payload.date;
    let run = getState().runs[runId];
    if (!run) {
      this.logger.warn(`unable to find run ${runId} in state`);
      return;
    }

    const kitsToRemoveFromRun = run.kits ? [...run.kits] : [];
    const kitsToAddToRun = [];

    for (const kitPositionItem of newRunKitsList) {
      const currentKitIndex = kitsToRemoveFromRun.findIndex(
        (r) => r.kitId === kitPositionItem.kitId
      );
      if (currentKitIndex > -1) {
        kitsToRemoveFromRun.splice(currentKitIndex, 1);
      } else {
        kitsToAddToRun.push(kitPositionItem);
      }
    }

    for (const kitPositionItem of kitsToRemoveFromRun) {
      // unassign removed kit
      try {
        await this.runsService
          .unassignKitFromRun(run.id, kitPositionItem.kitId)
          .toPromise();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    for (const kitPositionItem of kitsToAddToRun) {
      // assign added kit
      try {
        await this.runsService
          .assignKitToRun(run.id, kitPositionItem)
          .toPromise();
        this.store.dispatch(
          new SetKitStateReturnedAction({
            kitId: kitPositionItem.kitId,
            date,
          })
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    // update run state
    run = getState().runs[runId];
    if (run) {
      const updatedRun = { ...run, kits: newRunKitsList };
      patchState({
        runs: { ...getState().runs, [runId]: updatedRun },
      });
    }
  }

  @Action(ChangeRunStatusAction)
  changeRunStatus(
    { dispatch }: StateContext<RunsStateModel>,
    { payload: { run, isStarted } }: ChangeRunStatusAction
  ) {
    return (
      isStarted
        ? this.runsService.markRunAsStarted(run.id, run.started.timestamp)
        : this.runsService.markRunAsFinished(run.id, run.finished.timestamp)
    ).pipe(switchMap(() => dispatch(new SetRunsById(run))));
  }

  @Action(DeleteRunAction)
  deleteRun(
    { getState, patchState }: StateContext<RunsStateModel>,
    { runId }: DeleteRunAction
  ) {
    return this.runsService.deleteRun(runId).pipe(
      switchMap(() => {
        const currentState = getState();
        const pageWithRun = Object.keys(currentState.pages).findIndex((p) =>
          currentState.pages[p].items.includes(runId)
        );
        const pagesWithoutRun = {
          ...currentState.pages,
          [pageWithRun]: {
            ...currentState.pages[pageWithRun],
            items: currentState.pages[pageWithRun].items.filter(
              (i) => i !== runId
            ),
          },
        };
        const runs = currentState.runs
          ? {
              ...currentState.runs,
            }
          : {};
        if (Object.prototype.hasOwnProperty.call(runs, runId)) {
          delete runs[runId];
          return of(
            patchState({
              runs,
              pages: pagesWithoutRun,
            })
          );
        }
        return of({});
      })
    );
  }

  @Action(SetRunsById)
  setRunsById(
    { getState, patchState }: StateContext<RunsStateModel>,
    { runs }: SetRunsById
  ) {
    const runsArray: Run[] = Array.isArray(runs) ? runs : [runs];
    if (runsArray.length) {
      const { runs: stateRuns } = getState();
      const updatedRunsState = stateRuns ? { ...stateRuns } : {};
      for (const run of runsArray) {
        updatedRunsState[run.id] = run;
      }

      patchState({
        runs: { ...updatedRunsState },
      });
    }
  }

  @Action(LoadAllRunsAction)
  loadAllRuns(
    { getState, patchState, dispatch }: StateContext<RunsStateModel>,
    { continuationToken }: LoadAllRunsAction
  ) {
    const allRunsLoaded = getState().allRunsLoaded;
    if (!allRunsLoaded || continuationToken) {
      if (!allRunsLoaded) {
        patchState({ allRunsLoaded: true });
      }

      return this.store.select(SigneeState.getSigneeList).pipe(
        filter((signees) => !!signees.length),
        first(),
        switchMap((signees) =>
          this.runsService
            .getRuns(
              {
                signeeIds: signees.map((signee) => signee.id),
              },
              {
                pagingTop: 500,
                continuationToken,
              }
            )
            .pipe(
              tap((response) => {
                const updatedRunsState = {};
                for (const run of response.items) {
                  updatedRunsState[run.id] = run;
                }
                patchState({
                  runs: { ...getState().runs, ...updatedRunsState },
                });
              }),
              switchMap((response) =>
                response.continuationToken
                  ? dispatch(new LoadAllRunsAction(response.continuationToken))
                  : of(null)
              )
            )
        )
      );
    }
  }

  @Action(LoadRunsAction)
  loadRunsAction(
    { getState, patchState, dispatch }: StateContext<RunsStateModel>,
    { filters, headers, pageToLoad, reload }: LoadRunsAction
  ) {
    const state = getState();
    const runs = { ...state.runs };
    let pages = { ...state.pages };

    const currentPageSettings = {
      ...state.currentPageSettings,
      sortSettings: { ...state.currentPageSettings.sortSettings },
      filterSettings: { ...state.currentPageSettings.filterSettings },
    };

    let actualPageToLoad = pageToLoad ? pageToLoad - 1 : undefined;

    // Update pagination in case of listing pages.
    if (actualPageToLoad !== undefined) {
      currentPageSettings.pageIndex = actualPageToLoad;

      // Check that filters are same as they were for previous page.
      if (
        !isEqual(currentPageSettings.filterSettings, filters) ||
        currentPageSettings.pageSize !== headers.pagingTop ||
        currentPageSettings.sortSettings.sortDirection !==
          headers.orderDirection ||
        currentPageSettings.sortSettings.orderBy !== headers.orderBy
      ) {
        // If filters are different as they were -> update filters, reset pages object and go back to page 1.
        currentPageSettings.pageSize = headers.pagingTop;
        currentPageSettings.sortSettings.sortDirection = headers.orderDirection;
        currentPageSettings.sortSettings.orderBy = headers.orderBy;
        currentPageSettings.sortSettings.pageSize = headers.pagingTop;
        currentPageSettings.filterSettings = filters;

        actualPageToLoad = 0;
        reload = true;
        pages = {};
        currentPageSettings.pageIndex = actualPageToLoad;
      }
    }

    // Get selected page continuation token, if not first page
    if (actualPageToLoad > 0) {
      headers.continuationToken =
        pages[actualPageToLoad - 1]?.continuationToken;
    }

    // If not listing page, page doesnt exist or reload is demanded -> call API
    if (actualPageToLoad === undefined || !pages[actualPageToLoad] || reload) {
      return this.runsService.getRuns(filters, headers).pipe(
        tap(({ items, continuationToken, totalCount }) => {
          patchState({
            runs: {
              ...runs,
              ...items.reduce((acc, run) => {
                acc[run.id] = run;
                return acc;
              }, {}),
            },
            currentPageSettings: {
              sortSettings: {
                sortDirection: headers.orderDirection,
                orderBy: headers.orderBy,
                pageSize: headers.pagingTop,
              },
              pageSize: headers.pagingTop,
              filterSettings: filters,
              pageIndex: pageToLoad !== null ? pageToLoad - 1 : 0,
            },
            totalCount,
            pages: {
              ...pages,
              [actualPageToLoad]: {
                items: items.map((run) => run.id),
                continuationToken,
              },
            },
          });
        })
      );
    } else {
      // If page exists -> update state so pagination is updated.
      patchState({ currentPageSettings });
      return EMPTY;
    }
  }

  @Action(LoadRunById)
  loadRunById(
    { getState, patchState }: StateContext<RunsStateModel>,
    { runId, refresh, prependToPage }: LoadRunById
  ) {
    const currentState = getState();
    if (
      !currentState.runs ||
      !Object.prototype.hasOwnProperty.call(currentState.runs, runId) ||
      refresh
    ) {
      const updatedRunsState = currentState.runs
        ? { ...currentState.runs }
        : {};
      updatedRunsState[runId] = null;
      patchState({
        runs: updatedRunsState,
      });
      return this.runsService.getRun(runId).pipe(
        tap((run: Run) => {
          const pages = { ...getState().pages };
          let firstPage = { ...pages[0] };

          // Prepend ID on first page (when creating run)
          if (prependToPage) {
            firstPage = {
              continuationToken: firstPage.continuationToken,
              items: [run.id, ...firstPage.items],
            };
          }
          return patchState({
            runs: {
              ...getState().runs,
              [runId]: run,
            },
            pages: { ...pages, [0]: firstPage },
          });
        })
      );
    }
  }
}
