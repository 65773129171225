import { Component, Input } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
} from '@nebular/theme';

@Component({
  standalone: true,
  selector: 'app-add-email',
  imports: [
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './add-email.component.html',
})
export class AddEmailComponent {
  @Input() title = 'To';
  emailControl = new FormControl('', [Validators.required, Validators.email]);

  constructor(private dialog: NbDialogRef<AddEmailComponent>) {}

  addEmail(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!this.emailControl.valid) {
      return;
    }
    const email = this.emailControl.value;
    this.dialog.close({ email });
  }

  close() {
    this.dialog.close();
  }
}
