import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { PaymentType } from '@core/core.types';
import {
  NbCheckboxModule,
  NbInputModule,
  NbSelectModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationErrorComponent } from '@shared/components/validation-error/validation-error.component';
import { InputValidationStatusDirective } from '@shared/directives/input-validation-status.directive';
import { controlContainerProvider } from '@shared/helpers';

@Component({
  selector: 'app-service-data-form-payment-information',
  imports: [
    NbCheckboxModule,
    NbSelectModule,
    ReactiveFormsModule,
    NbInputModule,
    TranslateModule,
    ValidationErrorComponent,
    InputValidationStatusDirective,
  ],
  templateUrl: './service-data-form-payment-information.component.html',
  viewProviders: [controlContainerProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDataFormPaymentInformationComponent implements OnInit {
  @Input() paymentTypes: string[];

  protected readonly Object = Object;
  protected readonly PaymentType = PaymentType;

  private destroyRef = inject(DestroyRef);

  protected get form() {
    return this.controlContainer.control as FormGroup;
  }

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form
      .get('paymentType')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((paymentType: PaymentType) => {
        if (
          paymentType !== PaymentType.Customer1 &&
          paymentType !== PaymentType.Customer2
        ) {
          this.form.get('notes').reset('');
        }
      });
  }
}
