import { Injectable } from '@angular/core';
import { ServiceDataFormComponent } from '@app/modules/service-data/components/service-data/service-data-form/service-data-form.component';
import {
  ExportDialogDataType,
  ExportType,
  ExtendedServiceData,
  ServiceData,
  ServiceDataExtensions,
} from '@app/modules/service-data/service-data.types';
import { ServiceDataFilters } from '@app/modules/service-data/services/service-data.service';
import { GetSamplesListAction } from '@app/modules/service-data/store/sample.actions';
import { SampleState } from '@app/modules/service-data/store/sample.state';
import { PaymentType, RuntimeStatus } from '@core/core.types';
import { ExportersUtilsService } from '@core/services/exporters-utils.service';
import {
  LoadPartnerById,
  LoadPartnersByIds,
} from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import {
  LoadInvoiceById,
  LoadInvoicesByIdsAction,
  LoadInvoicesPaymentsListAction,
} from '@core/store/payments/invoice-payments.actions';
import { InvoicesState } from '@core/store/payments/invoice-payments.state';
import { ProductsListState } from '@core/store/products/products.state';
import { HttpUtils } from '@core/utils/http-utils';
import { NbDialogService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map, switchMap, tap } from 'rxjs/operators';
import { ServiceDataDetailsComponent } from '../components/service-data/service-data-details/service-data-details.component';
import { ServiceDataResultsComponent } from '../components/service-data/service-data-results/service-data-results.component';
import { GetServiceDataListAction } from '@app/modules/service-data/store/service-data.actions';
import { LoadKitsByIdAction } from '@core/store/kits/kits.actions';

@Injectable({
  providedIn: 'root',
})
export class ServiceDataUtilsService {
  constructor(
    private store: Store,
    private nbDialogService: NbDialogService,
    private exportersUtilsService: ExportersUtilsService,
    private translate: TranslateService
  ) {}

  getExtendedServiceData(
    serviceData: ServiceData,
    appends: ServiceDataExtensions
  ): Observable<ExtendedServiceData> {
    if (!serviceData) {
      return of(null);
    }
    return forkJoin([
      appends.doctor
        ? this.store.dispatch(
            new LoadPartnerById(
              serviceData.serviceInformation.nipt.doctorInformation.doctorId
            )
          )
        : of(null),
      appends.partner
        ? this.store.dispatch(new LoadPartnerById(serviceData.partnerId))
        : of(null),
      appends.invoice
        ? this.store
            .dispatch(
              new LoadInvoiceById(
                serviceData.paymentInformation.invoiceId,
                false
              )
            )
            .pipe(
              switchMap(() =>
                this.store.dispatch(
                  new LoadInvoicesPaymentsListAction(
                    serviceData.paymentInformation.invoiceId
                  )
                )
              )
            )
        : of(null),
      appends.samples && serviceData.sampleIds.length
        ? this.store.dispatch(
            new GetSamplesListAction(
              { ids: serviceData.sampleIds },
              {},
              null,
              false
            )
          )
        : of(null),
    ]).pipe(
      switchMap(() =>
        combineLatest([
          of(serviceData),
          this.store
            .select(ProductsListState.getBundles)
            .pipe(map((getBundles) => getBundles(serviceData.bundleIds))),
          appends.partner
            ? this.store
                .select(PartnersState.getPartnerById)
                .pipe(
                  map((getPartnerById) => getPartnerById(serviceData.partnerId))
                )
            : of(null),
          appends.doctor
            ? this.store
                .select(PartnersState.getPartnerById)
                .pipe(
                  map((getPartnerById) =>
                    getPartnerById(
                      serviceData.serviceInformation.nipt.doctorInformation
                        .doctorId
                    )
                  )
                )
            : of(null),
          appends.invoice
            ? combineLatest([
                this.store
                  .select(InvoicesState.getInvoiceById)
                  .pipe(
                    map((getInvoiceById) =>
                      getInvoiceById(serviceData.paymentInformation.invoiceId)
                    )
                  ),
                this.store
                  .select(InvoicesState.getInvoicePymentsByInvoiceId)
                  .pipe(
                    map((getPaymentsByInvoiceId) =>
                      getPaymentsByInvoiceId(
                        serviceData.paymentInformation.invoiceId
                      )
                    )
                  ),
              ]).pipe(map(([invoice, payments]) => ({ ...invoice, payments })))
            : of(null),
          appends.samples
            ? this.store
                .select(SampleState.getSamplesByIds)
                .pipe(
                  map((getSamplesByIds) =>
                    getSamplesByIds(serviceData.sampleIds)
                  )
                )
            : of(null),
        ])
      ),
      map(([serviceData, bundles, partner, doctor, invoice, samples]) => ({
        ...serviceData,
        bundles,
        partner,
        doctor,
        invoice,
        samples,
      }))
    );
  }

  getExtendedServiceDataList(
    serviceDataList: ServiceData[],
    appends: ServiceDataExtensions
  ): Observable<ExtendedServiceData[]> {
    if (!serviceDataList.length) {
      return of([]);
    }

    return this.loadServiceDataConnectedData(serviceDataList, appends).pipe(
      switchMap(() =>
        combineLatest(
          serviceDataList.map((serviceData) =>
            this.getExtendedServiceData(serviceData, appends)
          )
        )
      )
    );
  }

  loadServiceDataConnectedData(
    serviceDataList: ServiceData[],
    appends: ServiceDataExtensions
  ) {
    if (!serviceDataList.length) {
      return of([]);
    }

    const invoiceIds: string[] = [];
    const partnerIds: string[] = [];
    serviceDataList.forEach((serviceData) => {
      if (appends.partner) {
        partnerIds.push(serviceData.partnerId);
      }
      if (appends.doctor) {
        partnerIds.push(
          serviceData.serviceInformation.nipt?.doctorInformation?.doctorId
        );
      }
      invoiceIds.push(serviceData.paymentInformation.invoiceId);
    });

    return forkJoin([
      appends.partner || appends.doctor
        ? this.store.dispatch(new LoadPartnersByIds(partnerIds))
        : of(null),
      appends.invoice
        ? this.store.dispatch(new LoadInvoicesByIdsAction(invoiceIds))
        : of(null),
    ]);
  }

  buildHttpParams(params: ServiceDataFilters) {
    let httpParams = HttpUtils.buildHttpParams(params);

    if (params.doctorIds?.length) {
      httpParams = httpParams.set('doctorIds', params.doctorIds.join(','));
    }

    if (params.paymentTypes?.length) {
      httpParams = httpParams.set(
        'paymentTypes',
        params.paymentTypes.join(',')
      );
    }

    if (params.bundleIds?.length) {
      httpParams = httpParams.set('bundleIds', params.bundleIds.join(','));
    }

    if (params.referenceKeys?.length) {
      httpParams = httpParams.set(
        'referenceKeys',
        params.referenceKeys.join(',')
      );
    }

    if (params.partnerIds?.length) {
      httpParams = httpParams.set('partnerIds', params.partnerIds.join(','));
    }

    return httpParams;
  }

  isUnpaid(serviceData: ExtendedServiceData) {
    return (
      serviceData &&
      serviceData.paymentInformation.paymentType !== PaymentType.Partner &&
      !(
        serviceData.invoice.paid ||
        (serviceData.invoice.payments?.[0]?.paid &&
          serviceData.invoice?.properties?.find(
            (property) => property.itemKey === 'SepaContract'
          )?.itemValue === 'true')
      )
    );
  }

  public openDetails(serviceDataId: string) {
    this.nbDialogService.open(ServiceDataDetailsComponent, {
      context: { serviceDataId },
    });
  }

  public openEdit(serviceDataId: string) {
    this.nbDialogService.open(ServiceDataFormComponent, {
      context: { id: serviceDataId },
    });
  }

  public openResults(serviceDataIds: string[]) {
    this.nbDialogService.open(ServiceDataResultsComponent, {
      context: { serviceDataIds },
    });
  }

  public exportSelected(
    exportType: ExportType,
    ids?: string[]
  ): Observable<string> {
    const progressSubject = this.exportersUtilsService.openProgressDialog(
      this.translate.instant(
        'serviceData.export.types.' +
          Object.keys(ExportType)[Object.values(ExportType).indexOf(exportType)]
      )
    );

    return this.exportersUtilsService
      .exportSelected(ExportDialogDataType.ServiceData, ids, exportType)
      .pipe(
        tap((status: string) => {
          if (status !== RuntimeStatus.Running) {
            progressSubject.next({
              value: 100,
              status: 'Done',
              success: status === RuntimeStatus.Completed ? true : false,
            });
          }
        })
      );
  }

  public export(
    exportType: ExportType,
    filters?: { [key: string]: string }
  ): Observable<string> {
    const progressSubject = this.exportersUtilsService.openProgressDialog(
      this.translate.instant(
        'serviceData.export.types.' +
          Object.keys(ExportType)[Object.values(ExportType).indexOf(exportType)]
      )
    );

    return this.exportersUtilsService.export(filters, exportType).pipe(
      tap((status: string) => {
        if (status !== RuntimeStatus.Running) {
          progressSubject.next({
            value: 100,
            status: 'Done',
            success: status === RuntimeStatus.Completed ? true : false,
          });
        }
      })
    );
  }
}
