import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import {
  API_SPECIFICATION_CONSTANTS,
  PAGING_HEADERS,
  SIGNEE_API_CONSTANTS,
} from '../../../app.constants';
import { NIPTSignee } from '../core.types';

@Injectable({
  providedIn: 'root',
})
export class SigneeService {
  private SIGNEE_ENDPOINT: string;
  private routes = {
    getSigneeList: () =>
      `${this.SIGNEE_ENDPOINT}/${SIGNEE_API_CONSTANTS.niptSigneeDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}/${SIGNEE_API_CONSTANTS.commandListNIPTSignees}`,
  };

  constructor(private httpClient: HttpClient) {
    this.SIGNEE_ENDPOINT = `${environment.cataloguesApiUrl}`;
  }

  getSigneeList(params: {
    LaboratoryNames?: string;
  }): Observable<NIPTSignee[]> {
    return this.httpClient.get<NIPTSignee[]>(this.routes.getSigneeList(), {
      params,
      headers: {
        [PAGING_HEADERS.orderBy]: 'laboratoryName',
      },
    });
  }
}
