import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  INVOICE_KEYS,
  NOTIFICATION_EMAILS_CONSTANTS,
  TRANSLATION_PROJECTS,
} from '@app/app.constants';
import { KitsUtilsService } from '@app/modules/kits/kits-utils.service';
import { PartnersUtilsService } from '@app/modules/partners/partners-utils.service';
import {
  Document,
  DocumentInteraction,
  DocumentStatus,
  DocumentType,
  ExtendedProductInventoryItem,
  ProductInventoryItemStatus,
  SampleStatus,
} from '@app/modules/service-data/service-data.types';
import { ProductInventoryItemUtilsService } from '@app/modules/service-data/services/product-inventory-item-utils.service';
import {
  GetProductInventoryItemAction,
  UnassignDocumentFromProductInventoryItemAction,
} from '@app/modules/service-data/store/product-intentory-item.actions';
import {
  NotificationProfileModel,
  PaymentType,
  TemplateType,
  TranslationProject,
} from '@core/core.types';
import { AuthenticationService } from '@core/services/authentication.service';
import { getTemplateType } from '@core/services/documents-utils';
import { DocumentsUtilsService } from '@core/services/documents-utils.service';
import { DocumentsService } from '@core/services/documents.service';
import { NotificationProfilesService } from '@core/services/notification-profiles.service';
import { TranslationProjectService } from '@core/services/translation-project.service';
import {
  ApproveDocumentAction,
  ArchiveDocumentAction,
  DeleteDocumentAction,
  DisapproveDocumentAction,
  LoadMultipleDocumentsAction,
  RemoveFromUploadedList,
} from '@core/store/documents/documents.actions';
import { DocumentsState } from '@core/store/documents/documents.state';
import { LanguageState } from '@core/store/languages/languages.state';
import { LoadInvoicesPaymentsListAction } from '@core/store/payments/invoice-payments.actions';
import { InvoicesState } from '@core/store/payments/invoice-payments.state';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ConfirmService } from '@shared/components/confirm/confirm-dialog.component';
import { DataTableComponent } from '@shared/components/data-table/data-table.component';
import {
  DataTableActionsLocation,
  DataTableActionType,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { DocumentInteractionsListComponent } from '@shared/components/document-interactions-list/document-interactions-list.component';
import { WarningService } from '@shared/components/warning/warning-dialog.component';
import { ConfirmCloseReason } from '@shared/shared.types';
import moment from 'moment';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  first,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { ApproveDocumentDialogComponent } from '../../approve-document-dialog/approve-document-dialog.component';

@Component({
  selector: 'app-product-inventory-item-documents',
  imports: [DataTableComponent, DocumentInteractionsListComponent],
  templateUrl: './product-inventory-item-documents.component.html',
  styleUrl: './product-inventory-item-documents.component.scss',
})
export class ProductInventoryItemDocumentsComponent implements OnInit {
  @Input() pii: ExtendedProductInventoryItem;

  @Output() showPDF = new EventEmitter();

  @ViewChild('interactionTableTemplate', { static: true })
  interactionTableTemplate: TemplateRef<any>;

  protected dataTableConfiguration: DataTableConfiguration;

  protected selectedRowsSubject$ = new BehaviorSubject<Document[]>(null);

  private documents$: Observable<Document[]>;

  constructor(
    protected dialogRef: NbDialogRef<ProductInventoryItemDocumentsComponent>,
    private store: Store,
    private documentsUtilsService: DocumentsUtilsService,
    private confirmService: ConfirmService,
    private warningService: WarningService,
    private translateService: TranslateService,
    private toastrService: NbToastrService,
    private notificationProfilesService: NotificationProfilesService,
    private kitsUtilsService: KitsUtilsService,
    private partnersUtilsService: PartnersUtilsService,
    private translationProjectService: TranslationProjectService,
    private dialogService: NbDialogService,
    private documentsService: DocumentsService,
    private piiUtilsService: ProductInventoryItemUtilsService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.documents$ = this.store
      .dispatch(new LoadMultipleDocumentsAction({ ids: this.pii.documents }))
      .pipe(
        switchMap(() =>
          this.store.select(DocumentsState.getDocumentsByIds).pipe(
            map((getByIds) => getByIds(this.pii.documents)),
            switchMap((documents: Document[]) =>
              this.authService
                .hasRequiredPermission$(
                  'pii.details.documentsList.showUnapproved'
                )
                .pipe(
                  first(),
                  map((hasPermission: boolean) =>
                    hasPermission
                      ? documents
                      : documents.filter(
                          (document: Document) => !!document.approved
                        )
                  ),
                  tap((documents: Document[]) => {
                    this.selectedRowsSubject$.next(documents);
                    if (documents.length) {
                      this.setPdfOptions(documents[documents.length - 1]?.id)
                        .pipe(first())
                        .subscribe();
                    } else {
                      this.showPDF.emit(null);
                    }
                  })
                )
            )
          )
        )
      );

    this.dataTableConfiguration = {
      title: '',
      rows$: this.documents$,
      tableHeadClasses: '',
      tableCellClasses: 'align-middle',
      actionsClasses: 'w-15 text-end',
      actionsLocation: DataTableActionsLocation.RIGHT,
      singleActions: [
        {
          type: DataTableActionType.BUTTON,
          name: 'Preview document',
          icon: 'file-text-outline',
          permission: 'pii.details.documents.buttons.preview',
          action: this.onPreviewDocument.bind(this),
        },
        {
          type: DataTableActionType.BUTTON,
          name: 'Archive document',
          icon: 'archive-outline',
          permission: 'pii.details.documents.buttons.actions',
          action: this.onArchiveDocument.bind(this),
          disabled: (row) => row.approved,
        },
        {
          type: DataTableActionType.BUTTON,
          name: 'Approve document',
          icon: 'checkmark-outline',
          permission: 'pii.details.documents.buttons.actions',
          action: this.onApproveDocument.bind(this),
          disabled: (row) =>
            this.pii.sample.status === SampleStatus.forResample || row.approved,
        },
        {
          type: DataTableActionType.BUTTON,
          name: 'Disapprove document',
          icon: 'close-outline',
          permission: [
            'pii.details.documents.buttons.actions',
            'pii.details.documentsList.disapprove',
          ],

          action: this.onDisapproveDocument.bind(this),
          disabled: (row) =>
            ((this.pii.sample.status === SampleStatus.forResample ||
              this.pii.lastStatus ===
                ProductInventoryItemStatus.processingFailed) &&
              !this.authService.hasRequiredPermission(
                'pii.details.documentsList.disapprove.enabledAfterApproved'
              )) ||
            this.pii.sample.status === SampleStatus.redrawn,
        },
        {
          type: DataTableActionType.BUTTON,
          name: 'Remove document',
          icon: 'trash-2-outline',
          permission: 'pii.details.documents.buttons.delete',
          action: this.onRemoveDocument.bind(this),
          disabled: (row) =>
            row.lastStatus === DocumentStatus.approved ||
            row.lastStatus === DocumentStatus.downloaded,
        },
      ],
      columns: [
        {
          label: 'Name',
          property: 'fileName',
          customStyle: 'font-weight: bold!important',
        },
        {
          label: 'Status',
          property: 'lastStatus',
          customStyle: 'width: 15%',
        },
      ],
      extraRow: this.interactionTableTemplate,
      selectionMode: DataTableSelectionType.SINGLE,
    };

    this.selectedRowsSubject$
      .pipe(
        switchMap((documents: Document[]) => {
          if (documents && documents.length > 0) {
            return this.setPdfOptions(documents[documents.length - 1]?.id);
          }
          return of(null);
        })
      )
      .subscribe();
  }

  private onPreviewDocument(event: MouseEvent, document: Document) {
    event.preventDefault();
    event.stopPropagation();

    this.documentsService
      .getDocumentReadUri(document.id)
      .pipe(first())
      .subscribe((uri: string) => {
        window.open(uri, 'blank');
      });
  }

  private onArchiveDocument(event, document) {
    event.preventDefault();

    this.confirmService
      .confirm({
        title: this.translateService.instant(
          'pii.details.archive.confirmDocumentArchiveTitle'
        ),
        message: this.translateService.instant(
          'pii.details.archive.confirmDocumentArchiveResolveMessage',
          { fileName: document.fileName }
        ),
      })
      .pipe(
        first(),
        switchMap((confirmOpts) => {
          if (confirmOpts.closeReason === ConfirmCloseReason.Yes) {
            return this.store.dispatch(new ArchiveDocumentAction(document.id));
          }
        })
      )
      .subscribe();
  }

  private onApproveDocument(event: MouseEvent, document: Document) {
    event.preventDefault();
    event.stopPropagation();

    this.invoiceAndPaymentRowsExist()
      .pipe(
        first(),
        switchMap((exists: boolean) => {
          if (
            this.pii.sample.serviceData.paymentInformation.paymentType !==
              PaymentType.Partner &&
            !this.pii.sample.serviceData.paymentInformation.paid &&
            !exists
          ) {
            return this.confirmService
              .confirm({
                title: this.translateService.instant(
                  'pii.details.approve.areYouSureTitle'
                ),
                message: this.translateService.instant(
                  'pii.details.approve.areYouSureMessage'
                ),
              })
              .pipe(
                first(),
                switchMap((confirmOpts) => {
                  if (confirmOpts.closeReason === ConfirmCloseReason.No) {
                    return of(null);
                  }

                  return this.approvalCheck(document);
                })
              );
          }

          return this.approvalCheck(document);
        }),
        delay(300),
        switchMap(() =>
          combineLatest([
            this.store.dispatch(
              new LoadMultipleDocumentsAction({ ids: this.pii.documents })
            ),
            this.store.dispatch(new GetProductInventoryItemAction(this.pii.id)),
          ])
        )
      )
      .subscribe();
  }

  private approvalCheck(document: Document) {
    if (
      !this.documentsUtilsService.isDocumentChecked(document.id) &&
      this.documentsUtilsService.isBGIDocument(document)
    ) {
      return this.confirmService
        .confirm({
          title: this.translateService.instant(
            'pii.details.approve.withoutCheckingTitle'
          ),
          message: this.translateService.instant(
            'pii.details.approve.withoutCheckingMessage'
          ),
        })
        .pipe(
          switchMap((confirmOpts) => {
            if (confirmOpts.closeReason === ConfirmCloseReason.No) {
              return of(null);
            }

            return this.showErrorsAndGetEmailTemplates(document);
          })
        );
    } else {
      return this.showErrorsAndGetEmailTemplates(document);
    }
  }

  private invoiceAndPaymentRowsExist(): Observable<boolean> {
    const invoice = this.pii.sample.serviceData.invoice;

    if (invoice?.properties) {
      const property = invoice.properties.length
        ? invoice.properties.find(
            (p) =>
              p.itemKey.toLocaleLowerCase() ===
              INVOICE_KEYS.SepaContract.toLocaleLowerCase()
          )
        : null;

      if (JSON.parse(property.itemValue))
        return this.store
          .dispatch(new LoadInvoicesPaymentsListAction(invoice.id))
          .pipe(
            first(),
            switchMap(() =>
              this.store
                .select(InvoicesState.getInvoicePymentsByInvoiceId)
                .pipe(
                  map((getInvoicePaymentsById) =>
                    getInvoicePaymentsById(invoice.id)
                  ),
                  switchMap((invoicePayments) => {
                    if (
                      !invoicePayments ||
                      !invoicePayments.length ||
                      (invoicePayments.length && !invoicePayments[0].paid)
                    ) {
                      return of(false);
                    }
                    return of(true);
                  })
                )
            )
          );
    }

    return of(false);
  }

  private showErrorsAndGetEmailTemplates(document: Document): Observable<any> {
    const lastApprovedInteraction = this.findLastDocumentInteractionByType(
      document.interactions,
      DocumentStatus.approved
    );
    if (lastApprovedInteraction) {
      const lastDisapprovedInteraction = this.findLastDocumentInteractionByType(
        document.interactions,
        DocumentStatus.disapproved
      );
      if (
        !lastDisapprovedInteraction ||
        !moment(lastApprovedInteraction.interacted).isBefore(
          moment(lastDisapprovedInteraction.interacted)
        )
      ) {
        return this.warningService.showWarning({
          title: this.translateService.instant(
            'pii.details.approve.documentApprovalTitle'
          ),
          message: this.translateService.instant(
            'pii.details.approve.documentAlreadyApprovedWarningMessage',
            { fileName: document.fileName }
          ),
        });
      }
    }
    return this.confirmService
      .confirm({
        title: this.translateService.instant(
          'pii.details.approve.documentApprovalTitle'
        ),
        message: this.translateService.instant(
          'pii.details.approve.confirmDocumentApprovalMessage',
          { fileName: document.fileName }
        ),
      })
      .pipe(
        switchMap((confirmOpts) => {
          const documentType = document.properties.find(
            (p) => p.key === 'DocumentType'
          );
          if (confirmOpts.closeReason === ConfirmCloseReason.Yes) {
            if (documentType.value === DocumentType.OfficialReport) {
              return this.piiUtilsService.massApproveDocuments([
                { ...document, productInventoryItem: this.pii },
              ]);
            } else {
              return this.getEmailTemplate(document);
            }
          }

          return of(null);
        })
      );
  }

  private findLastDocumentInteractionByType(
    interactions: DocumentInteraction[],
    interactionType: DocumentStatus
  ): DocumentInteraction | null {
    let lastDocumentInteraction: DocumentInteraction = null;
    if (interactions && interactions.length > 0) {
      for (const interaction of interactions) {
        if (
          interaction.interaction === interactionType &&
          (lastDocumentInteraction === null ||
            moment(interaction.interacted).isAfter(
              moment(lastDocumentInteraction.interacted)
            ))
        ) {
          lastDocumentInteraction = interaction;
        }
      }
    }
    return lastDocumentInteraction;
  }

  private getEmailTemplate(document: Document): Observable<{
    serviceName: string;
    predefinedRecipients: {
      [country: string]: { cc: string[]; bcc: string[] };
    };
    subject: string;
    body: string;
  }> {
    const documentType = document.properties.find(
      (p) => p.key === 'DocumentType'
    );

    if (
      this.pii.lastStatus === ProductInventoryItemStatus.processingFailed ||
      this.pii.lastStatus === ProductInventoryItemStatus.canceled
    ) {
      this.toastrService.warning(
        this.translateService.instant(
          'pii.details.approve.approvalWarningMessage',
          {
            kitId: this.pii.kitId,
            lastStatus: this.pii.lastStatus,
          }
        ),
        this.translateService.instant(
          'pii.details.approve.approvalWarningTitle'
        ),
        {
          duration: 0,
        }
      );
    } else if (
      this.pii.sample.status === SampleStatus.redrawn ||
      this.pii.sample.status === SampleStatus.forResample
    ) {
      this.toastrService.warning(
        this.translateService.instant(
          'pii.details.approve.approvalSampleWarningMessage',
          {
            lastStatus: this.pii.sample.status,
          }
        ),
        this.translateService.instant(
          'pii.details.approve.approvalWarningTitle'
        ),
        {
          duration: 0,
        }
      );
    }

    if (documentType.value !== DocumentType.OfficialReport) {
      const templateType = getTemplateType(
        document,
        this.pii.sample.serviceData
      );

      return this.notificationProfilesService
        .getNotificationProfilesOfPartner(
          this.pii.sample.serviceData.serviceInformation.nipt.doctorInformation
            .doctorId
        )
        .pipe(
          first(),
          switchMap((notificationProfiles: NotificationProfileModel[]) => {
            const notificationProfile =
              notificationProfiles && notificationProfiles.length
                ? notificationProfiles.find((np) => np.name === templateType)
                : null;

            return this.retrieveMailTemplate(
              this.pii.kitId,
              templateType,
              this.pii.product.brand
            ).pipe(
              switchMap((emailTemplate) =>
                this.dialogService
                  .open(ApproveDocumentDialogComponent, {
                    context: {
                      document,
                      notificationProfile,
                      templateType,
                      kitId: this.pii.kitId,
                      doctorId:
                        this.pii.sample.serviceData.serviceInformation.nipt
                          .doctorInformation.doctorId,
                      emailTemplate,
                    },
                  })
                  .onClose.pipe(
                    switchMap((data) => {
                      if (data) {
                        return this.store
                          .dispatch(
                            new ApproveDocumentAction(
                              document.id,
                              this.pii.id,
                              data
                            )
                          )
                          .pipe(
                            switchMap(() =>
                              this.store.dispatch(
                                new RemoveFromUploadedList([document.id])
                              )
                            )
                          );
                      } else {
                        return of(null);
                      }
                    })
                  )
              )
            );
          })
        );
    }

    return of(null);
  }

  private retrieveMailTemplate(
    kitId: string,
    templateType: TemplateType,
    brandName: string
  ): Observable<{
    serviceName: string;
    predefinedRecipients: {
      [country: string]: { cc: string[]; bcc: string[] };
    };
    subject: string;
    body: string;
  }> {
    const kit$ = this.kitsUtilsService.getKitById$(kitId);
    const partner$ = kit$.pipe(
      switchMap((k) => this.partnersUtilsService.getPartnerById$(k.partnerId))
    );

    return combineLatest([
      partner$,
      this.loadTranslationProject(templateType),
    ]).pipe(
      first(),
      filter((a) => a.every((e) => !!e)),
      map(([partner, translationProject]) => {
        const response = {
          serviceName: brandName,
          predefinedRecipients: {},
          subject: '',
          body: '',
        };

        if (partner && partner.address && partner.address.country) {
          const partnerCountryKey = partner.address.country
            .toLowerCase()
            .split(' ')
            .join('');
          response.predefinedRecipients =
            this.notificationProfilesService.recipientsByCountry[
              partnerCountryKey
            ];
          if (!response.predefinedRecipients) {
            response.predefinedRecipients = this.notificationProfilesService
              .recipientsByCountry.default ?? { cc: [], bcc: [] };
          }
        }

        if (translationProject && partner) {
          const language =
            partner.languageIds && partner.languageIds.length
              ? this.store.selectSnapshot(LanguageState.getLanguageById)(
                  partner.languageIds[0]
                )?.name
              : translationProject.languages[0];
          const subjectTerm = translationProject.translationTerms.find(
            (t) => t.term === NOTIFICATION_EMAILS_CONSTANTS.subjectTerm
          );
          const bodyTerm = translationProject.translationTerms.find(
            (t) => t.term === NOTIFICATION_EMAILS_CONSTANTS.bodyTerm
          );

          if (subjectTerm) {
            const subjectTranslation =
              subjectTerm.translations.find((t) => t.language === language) ||
              subjectTerm.translations.find(
                ({ language: tLanguage }) =>
                  tLanguage.toLowerCase() === 'english'
              );
            response.subject = subjectTranslation.translation;
          }

          if (bodyTerm) {
            const bodyTranslation =
              bodyTerm.translations.find((t) => t.language === language) ||
              bodyTerm.translations.find(
                ({ language: tLanguage }) =>
                  tLanguage.toLowerCase() === 'english'
              );
            response.body = bodyTranslation.translation;
          }

          return response;
        }
      })
    );
  }

  private loadTranslationProject(templateType): Observable<TranslationProject> {
    const key = templateType.toLowerCase().split(' ').join('');

    if (TRANSLATION_PROJECTS[key]) {
      return this.translationProjectService.get(TRANSLATION_PROJECTS[key]);
    }

    return of(null);
  }

  private onRemoveDocument(event: MouseEvent, document: Document) {
    this.confirmService
      .confirm({
        title: 'pii.details.remove.confirmDocumentDeletionTitle',
        message: this.translateService.instant(
          'pii.details.remove.confirmDocumentDeletionMessage',
          { documentName: document.fileName }
        ),
      })
      .pipe(
        take(1),
        switchMap((confirmOpt) => {
          if (confirmOpt.closeReason !== ConfirmCloseReason.Yes) {
            return EMPTY;
          }

          return this.store
            .dispatch(
              new UnassignDocumentFromProductInventoryItemAction(
                this.pii.id,
                document.id
              )
            )
            .pipe(
              switchMap(() =>
                this.store.dispatch(new DeleteDocumentAction(document.id))
              ),
              switchMap(() =>
                this.store.dispatch(
                  new GetProductInventoryItemAction(this.pii.id, true)
                )
              )
            );
        })
      )
      .subscribe();
  }

  private onDisapproveDocument(event, document) {
    event.preventDefault();
    event.stopPropagation();
    const lastApprovedInteraction = this.findLastDocumentInteractionByType(
      document.interactions,
      DocumentStatus.approved
    );

    if (!lastApprovedInteraction) {
      this.warningService.showWarning({
        title: this.translateService.instant(
          'pii.details.disapprove.documentDisapprovalTitle'
        ),
        message: this.translateService.instant(
          'pii.details.disapprove.documentNotApprovedWarningMessage',
          { fileName: document.fileName }
        ),
      });

      return;
    }
    const lastDisapprovedInteraction = this.findLastDocumentInteractionByType(
      document.interactions,
      DocumentStatus.disapproved
    );
    if (
      lastDisapprovedInteraction &&
      !moment(lastDisapprovedInteraction.interacted).isBefore(
        moment(lastApprovedInteraction.interacted)
      )
    ) {
      this.warningService.showWarning({
        title: this.translateService.instant(
          'pii.details.disapprove.documentDisapprovalTitle'
        ),
        message: this.translateService.instant(
          'pii.details.disapprove.documentAlreadyDisapprovedWarningMessage',
          { fileName: document.fileName }
        ),
      });

      return;
    }

    this.confirmService
      .confirm({
        message: this.translateService.instant(
          'pii.details.disapprove.confirmDocumentDisapprovalMessage',
          { fileName: document.fileName }
        ),
        title: this.translateService.instant(
          'pii.details.disapprove.documentDisapprovalTitle'
        ),
      })
      .pipe(
        first(),
        switchMap((confirmOpts) => {
          if (confirmOpts.closeReason === ConfirmCloseReason.Yes) {
            return this.store.dispatch(
              new DisapproveDocumentAction(document.id, this.pii.id)
            );
          }
        })
      )
      .subscribe();
  }

  private setPdfOptions(documentId: string): Observable<string> {
    return this.documentsService.getDocumentReadUri(documentId).pipe(
      catchError(() => of(null)),
      tap((uri: string) => this.showPDF.emit(uri))
    );
  }
}
