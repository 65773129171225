import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { controlContainerProvider } from '@shared/helpers';

@Component({
  selector: 'app-validation-error',
  imports: [TranslateModule],
  templateUrl: './validation-error.component.html',
  styleUrl: './validation-error.component.scss',
  viewProviders: [controlContainerProvider],
})
export class ValidationErrorComponent implements OnInit {
  @Input() for?: string;
  @Input() translations: string;

  protected control: AbstractControl;

  private formGroupDirective = inject(FormGroupDirective, { optional: true });
  private destroyRef = inject(DestroyRef);

  constructor(
    private controlContainer: ControlContainer,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const parentFormGroup = this.controlContainer.control as FormGroup;
    this.control = this.for ? parentFormGroup.get(this.for) : parentFormGroup;

    // temporary fix until angular 18 introduces touched event listener
    // at which point replace this with that and add OnPush change detection
    this.formGroupDirective?.ngSubmit
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdRef.markForCheck());
  }

  protected readonly Object = Object;
}
