{
  "English": {
    "patient": "Patient",
    "patientNameText": "Name",
    "patientIdCardText": "ID",
    "patientAgeText": "Age",
    "gestationalWeeksText": "Gestational week",
    "dueDateText": "Estimated due date",
    "sample": "Sample",
    "sampleIdText": "Barcode",
    "doctorNameText": "Doctor",
    "venipunctureDateText": "Date of venipuncture",
    "receivedDateText": "Sample received",
    "sampleTypeText": "Sample type",
    "sampleType": "Peripheral blood",
    "approvedBy": "Approved by",
    "datedText": "Date"
  },
  "Slovenian": {
    "patient": "Pacientka",
    "patientNameText": "Ime in priimek",
    "patientIdCardText": "ID",
    "patientAgeText": "Starost",
    "gestationalWeeksText": "Gestacijska starost",
    "dueDateText": "PDP",
    "sample": "Vzorec",
    "sampleIdText": "Koda",
    "doctorNameText": "Zdravnik",
    "venipunctureDateText": "Datum odvzema vzorca",
    "receivedDateText": "Datum prejema vzorca",
    "sampleTypeText": "Tip vzorca",
    "sampleType": "Periferna kri",
    "approvedBy": "Odobril/a",
    "datedText": "Datum"
  },
  "German": {
    "patient": "Patientin",
    "patientNameText": "Name",
    "patientIdCardText": "ID",
    "patientAgeText": "Alter",
    "gestationalWeeksText": "Schwangerschaftswoche",
    "dueDateText": "Geschätzte Fälligkeit",
    "sample": "Probe",
    "sampleIdText": "Barkode",
    "doctorNameText": "Arzt",
    "venipunctureDateText": "Datum der Blutabnahme",
    "receivedDateText": "Probe erhalten",
    "sampleTypeText": "Typ der Probe",
    "sampleType": "Peripheres Blut",
    "approvedBy": "Genehmigt von",
    "datedText": "Datiert"
  },
  "Polish": {
    "patient": "Pacjentka",
    "patientNameText": "Imię",
    "patientIdCardText": "Dokument Tożsamości",
    "patientAgeText": "Wiek",
    "gestationalWeeksText": "Wiek ciążowy",
    "dueDateText": "Przewidywana data porodu",
    "sample": "Próbka",
    "sampleIdText": "Kod kreskowy",
    "doctorNameText": "Lekarz",
    "venipunctureDateText": "Data pobrania",
    "receivedDateText": "Data otrzymania próbki przez laboratorium",
    "sampleTypeText": "Typ próbki",
    "sampleType": "Krew obwodowa",
    "approvedBy": "Zaakceptowano przez",
    "datedText": "Data"
  },
  "Croatian": {
    "patient": "Pacijentica",
    "patientNameText": "Ime i Prezime",
    "patientIdCardText": "Broj osobne iskaznice",
    "patientAgeText": "Dob",
    "gestationalWeeksText": "Tjedan gestacije",
    "dueDateText": "Predviđeni termin poroda",
    "sample": "Uzorak",
    "sampleIdText": "Barkod",
    "doctorNameText": "Doktor",
    "venipunctureDateText": "Datum uzimanja krvi",
    "receivedDateText": "Datum zaprimanja uzorka",
    "sampleTypeText": "Tip uzorka",
    "sampleType": "Periferna krv",
    "approvedBy": "Odobreno od strane",
    "datedText": "Datum"
  },
  "Latvian": {
    "patient": "Paciente",
    "patientNameText": "Vārds Uzvārds",
    "patientIdCardText": "Personas kods",
    "patientAgeText": "Vecums",
    "gestationalWeeksText": "Grūtniecības nedēļa",
    "dueDateText": "Paredzamais dzemdību datums",
    "sample": "Asins paraugs",
    "sampleIdText": "Svītrkods",
    "doctorNameText": "Ārsts",
    "venipunctureDateText": "Asins parauga ņemšanas datums",
    "receivedDateText": "Asins paraugs saņemts",
    "sampleTypeText": "Asins parauga veids",
    "sampleType": "perifērās asinis",
    "approvedBy": "Apstiprināja",
    "datedText": "Datums"
  },
  "Albanian": {
    "patient": "Pacienti",
    "patientNameText": "Emri",
    "patientIdCardText": "Numri personal",
    "patientAgeText": "Mosha",
    "gestationalWeeksText": "Java e gestacionit",
    "dueDateText": "Data e parashikuar e lindjës",
    "sample": "Mostra",
    "sampleIdText": "Barkodi",
    "doctorNameText": "Doktori",
    "venipunctureDateText": "Data e e dhënies së gjakut",
    "receivedDateText": "Marrja e mostrës",
    "sampleTypeText": "Lloji i mostrës",
    "sampleType": "Gjaku periferik",
    "approvedBy": "Aprovuar nga",
    "datedText": "Data"
  },
  "Greek": {
    "patient": "Aσθενής",
    "patientNameText": "Όνομα",
    "patientIdCardText": "Ταυτότητα",
    "patientAgeText": "Ηλικία",
    "gestationalWeeksText": "Εβδομάδα Κύησης",
    "dueDateText": "Εκτιμώμενη Ημερομηνία Τοκετού",
    "sample": "Δείγμα",
    "sampleIdText": "Barcode",
    "doctorNameText": "Γιατρός",
    "venipunctureDateText": "Ημερομηνία φλεβοκέντησης",
    "receivedDateText": "Δείγμα που ελήφθη",
    "sampleTypeText": "Είδος δείγματος",
    "sampleType": "Περιφερειακό αίμα",
    "approvedBy": "Εγκρίθηκε απο",
    "datedText": "Ημερομηνία"
  },
  "Spanish": {
    "patient": "Paciente",
    "patientNameText": "Nombre",
    "patientIdCardText": "ID",
    "patientAgeText": "Edad",
    "gestationalWeeksText": "Semana de gestación",
    "dueDateText": "Fecha probable de parto",
    "sample": "Muestra",
    "sampleIdText": "Código de barras",
    "doctorNameText": "Médico",
    "venipunctureDateText": "Fecha de punción venosa",
    "receivedDateText": "Inicio de análisis",
    "sampleTypeText": "Tipo de muestra",
    "sampleType": "Plasma",
    "approvedBy": "Aprobado por",
    "datedText": "Fecha"
  },
  "Portuguese": {
    "patient": "Paciente",
    "patientNameText": "Nome",
    "patientIdCardText": "Documento de identificação",
    "patientAgeText": "Idade",
    "gestationalWeeksText": "Semana de gestação",
    "dueDateText": "Data estimada do nascimento",
    "sample": "Amostra",
    "sampleIdText": "Código de barras",
    "doctorNameText": "Médico",
    "venipunctureDateText": "Data da punção venosa",
    "receivedDateText": "Amostra recebida",
    "sampleTypeText": "Tipo de amostra",
    "sampleType": "Sangue periférico",
    "approvedBy": "Aprovado por",
    "datedText": "Data"
  },
  "Romanian": {
    "patient": "Pacientă",
    "patientNameText": "Numele",
    "patientIdCardText": "ID",
    "patientAgeText": "Vârsta",
    "gestationalWeeksText": "Săptămâna de sarcină",
    "dueDateText": "Data estimativă a nașterii",
    "sample": "Proba",
    "sampleIdText": "Cod de bare",
    "doctorNameText": "Medic",
    "venipunctureDateText": "Data venopuncției",
    "receivedDateText": "Proba primită",
    "sampleTypeText": "Tipul probei",
    "sampleType": "Sânge periferic",
    "approvedBy": "Aprobat de",
    "datedText": "Data"
  },
  "Ukrainian": {
    "patient": "Пацієнтка",
    "patientNameText": "Ім'я",
    "patientIdCardText": "Паспорт",
    "patientAgeText": "Вік",
    "gestationalWeeksText": "Гестаційний тиждень",
    "dueDateText": "Очікувана дата пологів",
    "sample": "Зразок",
    "sampleIdText": "Штрих-код",
    "doctorNameText": "Лікар",
    "venipunctureDateText": "Дата венепункції",
    "receivedDateText": "Зразок отримано",
    "sampleTypeText": "Тип зразку",
    "sampleType": "Периферійна кров",
    "approvedBy": "Затверджено",
    "datedText": "Дата"
  },
  "Estonian": {
    "patient": "Patsient",
    "patientNameText": "Nimi",
    "patientIdCardText": "ID",
    "patientAgeText": "Vanus",
    "gestationalWeeksText": "Rasedusnädal",
    "dueDateText": "Hinnanguline tähtaeg",
    "sample": "PROOV",
    "sampleIdText": "Vöötkood",
    "doctorNameText": "Arst",
    "venipunctureDateText": "Veenipunktsiooni kuupäev",
    "receivedDateText": "Proov saadud",
    "sampleTypeText": "Proovi liik",
    "sampleType": "Perifeerne veri",
    "approvedBy": "Kinnitanud",
    "datedText": "Kuupäev"
  },
  "Czech": {
    "patient": "Pacientka",
    "patientNameText": "Jméno",
    "patientIdCardText": "Rodné číslo",
    "patientAgeText": "Věk",
    "gestationalWeeksText": "Gestační stáří",
    "dueDateText": "Předpokládané datum porodu",
    "sample": "Vzorek",
    "sampleIdText": "Čárový kód",
    "doctorNameText": "Lékař",
    "venipunctureDateText": "Datum odběru",
    "receivedDateText": "Datum přijetí vzorku",
    "sampleTypeText": "Typ vzorku",
    "sampleType": "Periferní krev",
    "approvedBy": "Schválil(a)",
    "datedText": "Datum"
  },
  "Lithuanian": {
    "patient": "Pacientė",
    "patientNameText": "Vardas",
    "patientIdCardText": "Identifikavimo numeris",
    "patientAgeText": "Amžius",
    "gestationalWeeksText": "Nėštumo savaitė",
    "dueDateText": "Numatoma gimdymo data",
    "sample": "Mėginys",
    "sampleIdText": "Brūkšninis kodas",
    "doctorNameText": "Gydytojas",
    "venipunctureDateText": "Venos punkcijos data",
    "receivedDateText": "Mėginio gavimo data",
    "sampleTypeText": "Mėginio tipas",
    "sampleType": "Periferinis kraujas",
    "approvedBy": "Patvirtino",
    "datedText": "Data"
  },
  "Italian": {
    "patient": "Paziente",
    "patientNameText": "Nome",
    "patientIdCardText": "Identità",
    "patientAgeText": "Età",
    "gestationalWeeksText": "Settimana gestazionale",
    "dueDateText": "Data di scadenza stimata",
    "sample": "Campione",
    "sampleIdText": "Codice a barre",
    "doctorNameText": "Medico",
    "venipunctureDateText": "Data della puntura venosa",
    "receivedDateText": "Campione ricevuto",
    "sampleTypeText": "Tipo di campione",
    "sampleType": "Sangue periferico",
    "approvedBy": "Approvato da",
    "datedText": "Data"
  },
  "Hungarian": {
    "patient": "Beteg",
    "patientNameText": "Név",
    "patientIdCardText": "Azonosító",
    "patientAgeText": "Kor",
    "gestationalWeeksText": "Terhességi hét",
    "dueDateText": "Szülés várható dátuma",
    "sample": "Mintavétel",
    "sampleIdText": "Vonalkód",
    "doctorNameText": "Orvos",
    "venipunctureDateText": "Vénás vérvétel dátuma",
    "receivedDateText": "Kapott minta",
    "sampleTypeText": "Minta típusa",
    "sampleType": "Perifériás vér",
    "approvedBy": "Odobreno od strane",
    "datedText": "Dátum"
  },
  "Macedonian": {
    "patient": "Пациент",
    "patientNameText": "Име",
    "patientIdCardText": "Идентификација",
    "patientAgeText": "Возраст",
    "gestationalWeeksText": "Гестациска недела",
    "dueDateText": "Проценет датум на породување",
    "sample": "Примерок",
    "sampleIdText": "Баркод",
    "doctorNameText": "Лекар",
    "venipunctureDateText": "Датум на венепункција",
    "receivedDateText": "Примен примерок",
    "sampleTypeText": "Вид примерок",
    "sampleType": "Периферна крв",
    "approvedBy": "Одобрено од",
    "datedText": "Датум"
  },
  "Slovak": {
    "patient": "Pacient",
    "patientNameText": "Meno",
    "patientIdCardText": "ID",
    "patientAgeText": "Vek",
    "gestationalWeeksText": "Gestačný týždeň",
    "dueDateText": "Odhadovaný dátum pôrodu",
    "sample": "Vzorka",
    "sampleIdText": "Čiarový kód",
    "doctorNameText": "Lekár",
    "venipunctureDateText": "Dátum venopunkcie",
    "receivedDateText": "Vzorka prijatá",
    "sampleTypeText": "Typ vzorky",
    "sampleType": "Periférna krv",
    "approvedBy": "Schválil/-a",
    "datedText": "Dátum"
  },
  "Bulgarian": {
    "patient": "Пациент",
    "patientNameText": "Име",
    "patientIdCardText": "Документ за самоличност",
    "patientAgeText": "Възраст",
    "gestationalWeeksText": "Гестационна седмица",
    "dueDateText": "Дата на термина",
    "sample": "Проба",
    "sampleIdText": "Баркод",
    "doctorNameText": "Лекар",
    "venipunctureDateText": "Дата на венепункция",
    "receivedDateText": "Пробата е получена на",
    "sampleTypeText": "Тип на пробата",
    "sampleType": "Периферна кръв",
    "approvedBy": "Одобрено от",
    "datedText": "Дата"
  },
  "French": {
    "patient": "Patiente",
    "patientNameText": "Nom",
    "patientIdCardText": "Identifiant",
    "patientAgeText": "Âge",
    "gestationalWeeksText": "Semaine de grosesse",
    "dueDateText": "Date d’accouchement estimée",
    "sample": "Échantillon",
    "sampleIdText": "Code-barres",
    "doctorNameText": "Médecin",
    "venipunctureDateText": "Date de ponction veineuse",
    "receivedDateText": "Échantillon reçu",
    "sampleTypeText": "Type d’échantillon",
    "sampleType": "Sang périphérique",
    "approvedBy": "Approuvé par",
    "datedText": "Date"
  }
}
