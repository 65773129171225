import { AsyncPipe, DatePipe, JsonPipe, NgIf } from '@angular/common';
import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SamplesListComponent } from '@app/modules/service-data/components/sample/samples-list/samples-list.component';
import { ExtendedServiceData } from '@app/modules/service-data/service-data.types';
import { ServiceDataUtilsService } from '@app/modules/service-data/services/service-data-utils.service';
import {
  GetServiceDataAction,
  SetGeneplanetInvoiceAction,
  SetPaymentTypeAction,
} from '@app/modules/service-data/store/service-data.actions';
import { ServiceDataState } from '@app/modules/service-data/store/service-data.state';
import { Bundle, InvoicePayment, PaymentType } from '@core/core.types';
import { ProductUtilsService } from '@core/services/product-utils.service';
import {
  AddInvoicePaymentsAction,
  MarkAsPaidAction,
  MarkInvoicePaymentAsPaidAction,
  RemoveInvoicePaymentAction,
  RevokeInvoicePaid,
  SetDiscountAction,
  SetLaboratoryPriceAction,
  UpdateInvoiceAction,
} from '@core/store/payments/invoice-payments.actions';
import { ProductsListState } from '@core/store/products/products.state';
import { Util } from '@core/utils/core.util';
import {
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbDialogRef,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbSelectModule,
  NbToggleModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { DataTableComponent } from '@shared/components/data-table/data-table.component';
import {
  DataTableActionsLocation,
  DataTableActionType,
  DataTableConfiguration,
  DataTableDataType,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { RequirePermissionDirective } from '@shared/directives/require-permission.directive';
import { GetListPipe } from '@shared/pipes/get-list.pipe';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { map, shareReplay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-service-data-finance-details',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    GetListPipe,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    SamplesListComponent,
    TranslateModule,
    FormsModule,
    NbFormFieldModule,
    NbInputModule,
    NgIf,
    ReactiveFormsModule,
    JsonPipe,
    NbToggleModule,
    NbDatepickerModule,
    NbSelectModule,
    DataTableComponent,
    RequirePermissionDirective,
  ],
  templateUrl: './service-data-finance-details.component.html',
  styleUrl: './service-data-finance-details.component.scss',
})
export class ServiceDataFinanceDetailsComponent implements OnInit {
  @Input() id: string;

  @Select(ProductsListState.getBundlesList) bundles$: Observable<Bundle[]>;

  serviceData$: Observable<ExtendedServiceData>;
  invoiceId: string;

  prices$ = new BehaviorSubject<{
    endUserPrice: number;
    doctorFee: number;
  }>({ endUserPrice: 0, doctorFee: 0 });

  sumPrice$ = new BehaviorSubject<number>(0);

  gpInvoiceField = new FormControl('');
  laboratoryPriceField = new FormControl<number>(null);
  discountField = new FormControl<number>(null);
  sepaField = new FormControl<boolean>(false);
  paymentTypeField = new FormControl<PaymentType>(null);

  paidInFullEnabled$: Observable<boolean>;

  paymentFormGroup = this.fb.group({
    paymentDate: [moment(), [Validators.required]],
    price: [0, [Validators.required, Validators.min(1)]],
    notes: ['', Validators.required],
  });

  paymentsDataTableConfiguration: DataTableConfiguration;

  constructor(
    private store: Store,
    private serviceDataUtils: ServiceDataUtilsService,
    protected dialogRef: NbDialogRef<ServiceDataFinanceDetailsComponent>,
    private productUtils: ProductUtilsService,
    private fb: FormBuilder,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.serviceData$ = this.store
      .dispatch(new GetServiceDataAction(this.id, false, false))
      .pipe(
        switchMap(() =>
          this.store
            .select(ServiceDataState.getServiceDataById)
            .pipe(map((getById) => getById(this.id)))
        ),
        switchMap((serviceData) =>
          this.serviceDataUtils.getExtendedServiceData(serviceData, {
            partner: true,
            bundles: true,
            invoice: true,
            samples: true,
          })
        ),
        tap((serviceData) => {
          this.invoiceId = serviceData.invoice.id;
          this.gpInvoiceField.setValue(
            serviceData.paymentInformation.geneplanetInvoiceNumber
          );
          this.laboratoryPriceField.setValue(
            serviceData.invoice.laboratoryPrice
          );
          this.discountField.setValue(serviceData.invoice.discountValue);
          this.paymentTypeField.setValue(
            serviceData.paymentInformation.paymentType
          );
          this.sepaField.setValue(
            serviceData.invoice?.properties?.find(
              (property) => property.itemKey === 'SepaContract'
            )?.itemValue === 'true'
          );
          this.paymentFormGroup
            .get('notes')
            .setValue(
              `${serviceData.paymentInformation.notes} for ${serviceData.patientInformation.firstName} ${serviceData.patientInformation.lastName}`
            );
        }),
        tap((serviceData) =>
          this.productUtils
            .getPriceContactsFromPantheonByPartnerExternalId$(
              serviceData.partner.externalId
            )
            .subscribe((priceContracts) => {
              const prices = serviceData.bundles.reduce<{
                endUserPrice: number;
                doctorFee: number;
              }>(
                (acc, bundle) => {
                  const endUserPriceContract = priceContracts.find(
                    (priceContract) =>
                      priceContract.ident === bundle.endUserPriceExternalId
                  );
                  const doctorFeePriceContract = priceContracts.find(
                    (priceContract) =>
                      priceContract.ident === bundle.doctorFeeExternalId
                  );
                  return {
                    endUserPrice:
                      acc.endUserPrice + endUserPriceContract?.price,
                    doctorFee: acc.doctorFee + doctorFeePriceContract?.price,
                  };
                },
                { endUserPrice: 0, doctorFee: 0 }
              );
              if (isNaN(prices.endUserPrice)) {
                prices.endUserPrice = null;
              }
              if (isNaN(prices.doctorFee)) {
                prices.doctorFee = null;
              }
              this.prices$.next(prices);
            })
        ),
        shareReplay(1)
      );

    this.paymentsDataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      actionsLocation: DataTableActionsLocation.RIGHT,
      singleActions: [
        {
          name: 'Mark as paid',
          icon: 'checkmark-outline',
          invisible: (payment) => !!payment.paid,
          type: DataTableActionType.BUTTON,
          action: (event, payment) =>
            this.store.dispatch(
              new MarkInvoicePaymentAsPaidAction(this.invoiceId, payment.id)
            ),
        },
        {
          name: 'Paid',
          icon: 'done-all-outline',
          disabled: () => true,
          invisible: (payment) => !payment.paid,
          type: DataTableActionType.BUTTON,
        },
        {
          name: 'Remove payment',
          icon: 'minus-outline',
          type: DataTableActionType.BUTTON,
          action: (event, payment) =>
            this.store.dispatch(
              new RemoveInvoicePaymentAction(this.invoiceId, payment.id)
            ),
        },
      ],
      columns: [
        {
          label: '#',
          customStyle: 'width: 1%;',
          property: 'index',
        },
        {
          label: 'Date',
          property: 'paymentDate',
          type: DataTableDataType.DATE,
        },
        {
          label: 'Price',
          property: 'price',
        },
        {
          label: 'Payer name / Note',
          property: 'notes',
        },
      ],
      rows$: this.serviceData$.pipe(
        map((serviceData) =>
          serviceData.invoice.payments.map((payment, index) => ({
            ...payment,
            index: index + 1,
          }))
        ),
        tap((payments: InvoicePayment[]) => {
          this.sumPrice$.next(
            payments.reduce((prev, current) => prev + current.price, 0) +
              (this.discountField.value || 0)
          );
        })
      ),
    };

    this.paidInFullEnabled$ = combineLatest([
      this.sumPrice$,
      this.prices$,
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(([sumPrice, prices]) => {
        if (sumPrice > 0 && sumPrice >= (prices.endUserPrice ?? 0)) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  saveLaboratoryPrice() {
    if (!this.laboratoryPriceField.dirty) {
      return;
    }
    this.serviceData$
      .pipe(
        first(),
        switchMap((serviceData) =>
          this.store.dispatch(
            new SetLaboratoryPriceAction(
              serviceData.invoice.id,
              this.laboratoryPriceField.value
            )
          )
        )
      )
      .subscribe(() => this.laboratoryPriceField.markAsPristine());
  }

  savePaymentType() {
    if (!this.paymentTypeField.dirty) {
      return;
    }
    this.serviceData$
      .pipe(
        first(),
        switchMap((serviceData) =>
          this.store.dispatch(
            new SetPaymentTypeAction(
              serviceData.id,
              this.paymentTypeField.value
            )
          )
        )
      )
      .subscribe(() => this.paymentTypeField.markAsPristine());
  }

  saveDiscount() {
    if (!this.discountField.dirty) {
      return;
    }
    this.serviceData$
      .pipe(
        first(),
        switchMap((serviceData) =>
          this.store.dispatch(
            new SetDiscountAction(
              serviceData.invoice.id,
              this.discountField.value
            )
          )
        )
      )
      .subscribe(() => this.discountField.markAsPristine());
  }

  saveGpInvoice() {
    if (!this.gpInvoiceField.dirty) {
      return;
    }
    this.serviceData$
      .pipe(
        first(),
        switchMap((serviceData) =>
          this.store.dispatch(
            new SetGeneplanetInvoiceAction(
              serviceData.id,
              this.gpInvoiceField.value
            )
          )
        )
      )
      .subscribe(() => this.gpInvoiceField.markAsPristine());
  }

  addPayment(invoiceId: string) {
    this.store.dispatch(
      new AddInvoicePaymentsAction(invoiceId, [
        {
          ...(this.paymentFormGroup.getRawValue() as any),
          id: Util.CreateGuid(),
        },
      ])
    );
  }

  toggleSepa(invoiceId, sepa) {
    this.store.dispatch(new UpdateInvoiceAction(invoiceId, sepa));
  }

  markAsPaidInFull() {
    this.store.dispatch(new MarkAsPaidAction(this.invoiceId));
  }

  revokePaidInFull() {
    this.store.dispatch(new RevokeInvoicePaid(this.invoiceId));
  }

  protected readonly PaymentType = PaymentType;
  protected readonly Object = Object;
}
